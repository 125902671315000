import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useEffect, useState } from "react";
import { saveUsuarioOrganismoAreaFuncion } from "../../services/UsuarioOrganismoAreaFuncionService";
import { getFunciones } from "../../services/FuncionService";
import { getOrganismosAreas } from "../../services/OrganismoAreaService";
import { getUsuarios } from "../../services/UsuarioService";
import darkTheme from "../../layout/DarkTheme";


const initialState = {
	descripcion: "",
	observaciones: "",
	circunscripcionJudicialOrganismo: "",
};

const circunscripcionJudicialOptions = [
  {
    id: "1",
    descripcion: "Primera",
  },
  {
    id: "2",
    descripcion: "Segunda",
  },
  {
    id: "3",
    descripcion: "Tercera",
  },
  {
    id: "4",
    descripcion: "Cuarta",
  }
];

const itemGender = "M";
const nameItemText = "Función y Usuario asignado al Organismo-Área";
//
// #####################################################################################################################
//
const UsuarioOrganismoAreaFuncionDialog = ({
	fueros,
	onSave,
	onClose,
	itemCrud,
	actionCrud,
	setMessage,
	setShowMessage
}) => {
	const [itemCrudLocal, setItemCrudLocal] = useState(initialState);
	const [isDoingSomething, setIsDoingSomething] = useState(false);
	const [organismosAreas, setOrganismosAreas] = useState([]);
	const [funciones, setFunciones] = useState([]);
	const [usuarios, setUsuarios] = useState([]);
	const [valueAutoCompleteFueros, setValueAutoCompleteFueros] = useState(itemCrud.fuero);
	const [inputValueAutoCompleteFueros, setInputValueAutoCompleteFueros] = useState("");
	const [valueAutoCompleteOrganismosAreas, setValueAutoCompleteOrganismosAreas] = useState('');
	const [inputValueAutoCompleteOrganismosAreas, setInputValueAutoCompleteOrganismosAreas] = useState("");
	const [valueAutoCompleteFunciones, setValueAutoCompleteFunciones] = useState('');
	const [inputValueAutoCompleteFunciones, setInputValueAutoCompleteFunciones] = useState("");
	const [valueAutoCompleteUsuarios, setValueAutoCompleteUsuarios] = useState('');
	const [inputValueAutoCompleteUsuarios, setInputValueAutoCompleteUsuarios] =	useState("");
	//
	// ################################################################################
	//

	useEffect(() => {
		if (actionCrud === "create") {
			setValueAutoCompleteFueros("");
			setItemCrudLocal(initialState);
			loadFunciones();
			loadUsuarios();
		} else {
			setValueAutoCompleteFueros(itemCrud.descripcion_fuero);
			setValueAutoCompleteOrganismosAreas((itemCrud.descripcionOrganismo + ' - ' + itemCrud.descripcionArea));
			setValueAutoCompleteFunciones(itemCrud.descripcionFuncion);
			setValueAutoCompleteUsuarios((itemCrud.apellidoUsuario + ' ' +  itemCrud.nombreUsuario));
			loadOrganismosAreas(itemCrud.fuero, itemCrud.circunscripcionJudicialOrganismo)
			loadFunciones();
			loadUsuarios();
			setItemCrudLocal(itemCrud);
		}
	}, []);

	useEffect(() => {}, []);

	const loadOrganismosAreas = async (fuero, circunscripcionJudicial) => {
		let _params = {
			idFuero: fuero?.id || valueAutoCompleteFueros?.id,
      circunscripcionJudicial: circunscripcionJudicial || itemCrudLocal.circunscripcionJudicialOrganismo,
			estado: "habilitado",
		};

		setIsDoingSomething(true);
		const response = await getOrganismosAreas(_params);
		setIsDoingSomething(false);

		if (response.ok) {
			let _response = response.data.data;
			let _organismosAreas = [];

			_response.forEach((item) => {
				_organismosAreas.push({
					id: item.id,
					id_fuero: item.id_fuero,
					descripcion_fuero: item.descripcion_fuero,
					idOrganismo: item.id_organismo,
					id_area: item.id_area,
					label: item.descripcion_organismo + ' - ' + item.descripcion_area,
				});
			});

			setOrganismosAreas(_organismosAreas);

		} else {
			setOrganismosAreas([]);
			setMessage({
				messageText: response.messageText,
				severity: response.messageSeverity,
			});
			setShowMessage(true);
		}
	};

	
	//
	// ################################################################################
	//
	const handleCloseDialog = () => {
		onClose();
	};
	
	const handleChange = (e) => {
		setItemCrudLocal({
			...itemCrudLocal,
			[e.target.name]: e.target.value,
		});
		
	};

	const handleCircunscripcionChange = (event) => {
    setItemCrudLocal({
      ...itemCrudLocal,
      circunscripcionJudicialOrganismo: event.target.value,
    });
    setValueAutoCompleteOrganismosAreas("");
    loadOrganismosAreas(valueAutoCompleteFueros, event.target.value);
  };

  const handleFueroChange = (event, newValue) => {
    setValueAutoCompleteFueros(newValue);
    setValueAutoCompleteOrganismosAreas("");
    loadOrganismosAreas(newValue, itemCrudLocal.circunscripcionJudicialOrganismo);
  };

	const handleSave = () => {
		if (actionCrud === "create" || actionCrud === "update") {
			if (inputValueAutoCompleteOrganismosAreas === "") {
				setMessage({
					severity: "error",
					messageText: "Debes seleccionar un Sistema",
				});
				setShowMessage(true);
				return;
			}
			if (inputValueAutoCompleteFunciones === "") {
				setMessage({
					severity: "error",
					messageText: "Debes seleccionar un Rol",
				});
				setShowMessage(true);
				return;
			}
			if (inputValueAutoCompleteUsuarios === "") {
				setMessage({
					severity: "error",
					messageText: "Debes seleccionar un Usuario",
				});
				setShowMessage(true);
				return;
			}
		}
		save();
	};


	const save = async () => {
		let _itemCrud = {
			idOrganismo: itemCrudLocal?.idOrganismo ,
			idArea: itemCrudLocal?.idArea,
			idFuncion: itemCrudLocal?.idFuncion || valueAutoCompleteFunciones.id,
			observaciones: itemCrudLocal?.observaciones,
			idUsuario: valueAutoCompleteUsuarios?.id || itemCrudLocal.idUsuario,
			usernameUsuarioActualiza: localStorage.getItem("emailUsuario"),
		};

		if (actionCrud !== "create") {
			 _itemCrud.id = itemCrudLocal?.id
		}
		setIsDoingSomething(true);
		const response = await saveUsuarioOrganismoAreaFuncion(actionCrud, _itemCrud);
		setIsDoingSomething(false);

		if (response.ok) {
			onSave();
		}

		setMessage({
			messageText: response.messageText,
			severity: response.messageSeverity,
		});
		setShowMessage(true);
	};
	//
	// ################################################################################
	//

	const loadFunciones = async (itemCrudLocal) => {

		let _params = {
			idOrganismoArea: itemCrudLocal?.idOrganismoArea || valueAutoCompleteOrganismosAreas?.id,
			idFuncion: itemCrudLocal?.idFuncion,
			estado: 'habilitado',
		};

		setIsDoingSomething(true);
		const response = await getFunciones(_params);
		setIsDoingSomething(false);
		if (response.ok) {
			let _response = response.data.data;
			let _funciones = [];
			_response.forEach((item) => {
				_funciones.push({
					id: item.id,
					label: item.descripcion,
				});
			});

			setFunciones(_funciones);
		} else {
			setFunciones([]);
			setMessage({
				messageText: response.messageText,
				severity: response.messageSeverity,
			});
			setShowMessage(true);
		}

	};
	//
	// ################################################################################
	//

	const loadUsuarios = async (itemCrudLocal) => {
		let _params = {
			idOrganismoArea: itemCrudLocal?.id,
		};

		setIsDoingSomething(true);
		const response = await getUsuarios(_params);
		setIsDoingSomething(false);
		if (response.ok) {
			let _response = response.data.data;
			let _usuarios = [];
			_response.forEach((item) => {
				_usuarios.push({
					id: item.id,
					label: item.apellido + ' ' + item.nombre,
				});
				
			});

			setUsuarios(_usuarios);
		} else {
			setUsuarios([]);
			setMessage({
				messageText: response.messageText,
				severity: response.messageSeverity,
			});
			setShowMessage(true);
		}
	};
	//
	// ################################################################################
	//


	return (
		<>
			<Dialog
				open={true}
				onClose={handleCloseDialog}
				maxWidth="md"
				fullWidth={true}
				scroll="paper"
				aria-labelledby="form-dialog-title"
			>
				        <DialogTitle id="form-dialog-title"
					textAlign={{ xs: "center", sm: "center" }}			
					style={{ background: darkTheme.palette.primary.dark, color: darkTheme.palette.primary.text}}
				>
					{actionCrud === "create"
						? itemGender === "M"
							? "Nuevo"
							: "Nueva"
						: actionCrud === "update"
							? "Editando"
							: actionCrud === "enable"
								? "Habilitando"
								: actionCrud === "disable"
									? "Deshabilitando"
									: actionCrud === "read"
										? "Detalle"
										: ""}{" "}
					{nameItemText}
					<IconButton
						aria-label="close"
						onClick={handleCloseDialog}
						sx={{
							position: "absolute",
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<CloseIcon />
					</IconButton>
				</DialogTitle>

				{isDoingSomething && <LinearProgress />}

				<DialogContent dividers>
					{actionCrud === "view" && (
						<Typography
							sx={{
								mx: 2,
								p: 1,
								backgroundcolor: "Tomato",
								borderRadius: "5px",
								fontSize: "20px",
								textAlign: "center",
							}}
						>
							Sistema deshabilitado
						</Typography>
					)}
					<Box sx={{ m: 1, p: 1 }}>
							<Autocomplete
							id="fuero"
							sx={{ width: "100%", pb: 1 }}
							size="small"
							value={valueAutoCompleteFueros}
							onChange={handleFueroChange}
							inputValue={inputValueAutoCompleteFueros}
							onInputChange={(event, newInputValue) => {
								setInputValueAutoCompleteFueros(newInputValue);
							}}
							options={fueros}
							renderInput={(params) => (
								<TextField {...params} label="Fuero" />
							)}
							isOptionEqualToValue={(option, value) =>
								option.value === value.value
							}
							disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
							disableClearable = {true}
						/>
            <FormControl
              sx={{ width: "100%", pb: 1 }}
              disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
            >
              <InputLabel id="circunscripcionJudicial-select-label">
                Circunscripción Judicial
              </InputLabel>
              <Select
                sx={{ width: "100%", mr: 1 }}
                labelId="circunscripcionJudicial"
                label="Circunscripción Judicial"
                id="circunscripcionJudicialOrganismo"
                name="circunscripcionJudicial"
                value={itemCrudLocal.circunscripcionJudicialOrganismo}
                size="small"
                onChange={handleCircunscripcionChange}
              >
                {circunscripcionJudicialOptions.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.descripcion}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
						<Autocomplete
							id="organismosAreas"
							sx={{ width: "100%", pb: 1 }}
							size="small"
							value={valueAutoCompleteOrganismosAreas}
							onChange={(event, newValue) => {
								setValueAutoCompleteOrganismosAreas(newValue);
								setItemCrudLocal({
									...itemCrudLocal,
									idOrganismoArea: newValue?.id,
									idOrganismo: newValue?.idOrganismo,
									idArea: newValue?.id_area,

								});
							}}
							inputValue={inputValueAutoCompleteOrganismosAreas}
							onInputChange={(event, newInputValue) => {
								setInputValueAutoCompleteOrganismosAreas(newInputValue);

							}}
							options={organismosAreas}
							renderInput={(params) => (
								<TextField {...params} label="Organismo-Área" />
							)}
							isOptionEqualToValue={(option, value) =>
								option.value === value.value
							}
              disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read" || !valueAutoCompleteFueros?.id || !itemCrudLocal.circunscripcionJudicialOrganismo}
						/>

						<Autocomplete
							id="usuario"
							sx={{ width: "100%", pb: 1 }}
							size="small"
							value={valueAutoCompleteUsuarios}
							onChange={(event, newValue) => {
								setValueAutoCompleteUsuarios(newValue);
								setItemCrudLocal({
									...itemCrudLocal,
									idUsuario: newValue?.id,
								});
							}}
							inputValue={inputValueAutoCompleteUsuarios}
							onInputChange={(event, newInputValue) => {
								setInputValueAutoCompleteUsuarios(newInputValue);
							}}
							options={usuarios}
							renderInput={(params) => (
								<TextField {...params} label="Usuario" />
							)}
							isOptionEqualToValue={(option, value) =>
								option.value === value.value
							}
							disabled={
								actionCrud === "disable" ||
								actionCrud === "enable" ||
								actionCrud === "read"
							}
						/>
						<Autocomplete
							id="funciones"
							sx={{ width: "100%", pb: 1 }}
							size="small"
							value={valueAutoCompleteFunciones}
							onChange={(event, newValue) => {
								setValueAutoCompleteFunciones(newValue);
								setItemCrudLocal({
									...itemCrudLocal,
									idFuncion: newValue?.id,
								});
								
							}}
							inputValue={inputValueAutoCompleteFunciones}
							onInputChange={(event, newInputValue) => {
								setInputValueAutoCompleteFunciones(newInputValue);
							}}
							options={funciones}
							renderInput={(params) => (
								<TextField {...params} label="Función" />
							)}
							isOptionEqualToValue={(option, value) =>
								option.value === value.value
							}
							disabled={
								actionCrud === "disable" ||
								actionCrud === "enable" ||
								actionCrud === "read"
							}
						/>


						<TextField
							variant="outlined"
							size="small"
							name="observaciones"
							label="Observaciones"
							value={itemCrudLocal.observaciones}
							onChange={handleChange}
							disabled={
								actionCrud === "disable" ||
								actionCrud === "enable" ||
								actionCrud === "read"
							}
							sx={{ width: "100%", pb: 2 }}
							multiline
							rows={4}
						/>

						{actionCrud !== "create" && (
							<Box
								sx={{
									p: 1,
									mt: 1,
									color: "SlateGrey",
									border: "0px solid DarkGray",
									borderRadius: "3px",
								}}
							>
								<Typography variant="h8" component="div" gutterBottom>
									Última actualización: {itemCrudLocal.fechaActualizacion}
								</Typography>

								<Typography variant="h8" component="div" gutterBottom>
									Último usuario que actualizó: {itemCrudLocal.nombreUsuarioActualiza}{" "}
									{itemCrudLocal.apellidoUsuarioActualiza} ({itemCrudLocal.usernameUsuarioActualiza})
								</Typography>
							</Box>
						)}
					</Box>

					<Box sx={{ textAlign: "right" }}>
						<Button
							sx={{ mr: 1 }}
							variant="outlined"
							onClick={handleCloseDialog}
							startIcon={<CloseIcon />}
						>									
							Cancelar
						</Button>

						{actionCrud !== "read" && (
							<Button
								sx={{ mr: 1, backgroundcolor: "warning" }}
								variant="contained"
								onClick={handleSave}
								startIcon={
									actionCrud === "disable" ? (
										<RemoveCircleOutlineIcon />
									) : actionCrud === "enable" ? (
										<AddCircleOutlineIcon />
									) : (
										<SaveIcon />
									)
								}
							>
								{actionCrud === "disable"
									? "Deshabilitar"
									: actionCrud === "enable"
										? "Habilitar"
										: "Guardar"}
							</Button>
						)}
					</Box>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default UsuarioOrganismoAreaFuncionDialog;
