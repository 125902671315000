import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import Slide1 from '../../layout/assets/3.png';
import Slide2 from '../../layout/assets/4.png';
import Slide3 from '../../layout/assets/5.png';
import Slide4 from '../../layout/assets/6.png';
import Slide5 from '../../layout/assets/7.png';
import Slide6 from '../../layout/assets/8.png';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    label: 'Sistemas',
    imgPath: Slide1,
  },
  {
    label: 'Usuarios',
    imgPath: Slide2,
  },
  {
    label: 'Roles',
    imgPath: Slide3,
  },
  {
    label: 'Organismos',
    imgPath: Slide4,
  },
  {
    label: 'Funciones',
    imgPath: Slide5,
  },
  {
    label: 'Parámetros',
    imgPath: Slide6,
  }
];

function Carrusel() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ maxWidth: 300, flexGrow: 1 }}>
      <Paper
        square
        elevation={0}
      >
        <Typography variant="h4" noWrap component="h2"
                sx={{ visibility: 'hidden' }}
            >
                Estadisticas
            </Typography>
        <Typography variant="h3" noWrap component="article"
                sx={{ flex: 1, color: 'rgba(255, 255, 255, 0.5)', fontWeight: 'light', mb: 1, textAlign: 'center' }}>{images[activeStep].label}</Typography>
      </Paper>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {images.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={{
                  height: 255,
                  display: 'block',
                  maxWidth: 400,
                  overflow: 'hidden',
                  width: '100%',
                }}
                src={step.imgPath}
                alt={step.label}
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Siguiente
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Previa
          </Button>
        }
      />
    </Box>
  );
}

export default Carrusel;
