import {
	Autocomplete,
	Button,
	FormControl,
	FormControlLabel,
  FormLabel,
	Grid,
	InputLabel,
	LinearProgress,
	MenuItem,
	Paper,
	Radio,
  RadioGroup,
	Select,
	TextField,
	ThemeProvider,
	Tooltip,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import RemoveIcon from "@mui/icons-material/Remove";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useEffect, useState } from "react";
import {
	getSistemasRoles
} from "../../services/SistemaRolService";
import { getSistemas } from "../../services/SistemaService";
import SistemaRolUsuarioDialog from "./SistemaRolUsuarioDialog";
import Message from "../../utils/Message";
import { Box } from "@mui/system";
import { getSistemaRolUsuario, getSistemasRolesUsuarios, generarPDFSistemasRolesUsuarios } from "../../services/SistemaRolUsuarioService";
import { getUsuariosOrganismosAreasFunciones } from "../../services/UsuarioOrganismoAreaFuncionService";
import darkTheme from "../../layout/DarkTheme";
import React from 'react';
import { useMenu } from "../../context/MenuContext.js";

const initialSearchData = {
	descripcion: "",
	estado: "habilitado",
	orden: "ASC",
	ordenarPor: "descripcion_sistema",
};

const estadoOptions = [
	{
		id: "todo",
		descripcion: "Todo",
	},
	{
		id: "habilitado",
		descripcion: "Habilitado",
	},
	{
		id: "deshabilitado",
		descripcion: "Deshabilitado",
	},
];

const ordenOptions = [
  {
    id: "ASC",
    descripcion: "Ascendente",
  },
  {
    id: "DESC",
    descripcion: "Descendente",
  },
];

const ordenarPorOptions = [
	{
		id: "descripcion_sistema",
		descripcion: "Sistema",
	},
	{
		id: "descripcion_sistema_rol",
		descripcion: "Rol",
	},
	{
		id: "username_usuario",
		descripcion: "Usuario",
	},
];


const nameItemsText = "Asignación de Roles a Usuarios de Sistemas";
//
// #####################################################################################################################
//
const SistemaRolUsuarioTable = (data) => {
	const [searchData, setSearchData] = useState(initialSearchData);
	const [itemsCrud, setItemsCrud] = useState([]);
	const [itemCrud, setItemCrud] = useState({});
	const [actionCrud, setActionCrud] = useState("");
	const [isDoingSomething, setIsDoingSomething] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const [totalRowsCount, setTotalRowsCount] = useState(0);
	const [currentPage, setCurrentPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [message, setMessage] = useState({});
	const [showMessage, setShowMessage] = useState(false);
	const [sistemas, setSistemas] = useState([]);
	const [sistemasRoles, setSistemasRoles] = useState([]);
	const [usuarios, setUsuarios] = useState([]);
	const [valueAutoCompleteSistemas, setValueAutoCompleteSistemas] = useState({
		id: "",
		label: "",
	});
	const [inputValueAutoCompleteSistemas, setInputValueAutoCompleteSistemas] =
		useState("");
	const [valueAutoCompleteSistemasRoles, setValueAutoCompleteSistemasRoles] = useState({
		id: "",
		label: "",
	});
	const [inputValueAutoCompleteSistemasRoles, setInputValueAutoCompleteSistemasRoles] =
		useState("");

	const [valueAutoCompleteUsuarios, setValueAutoCompleteUsuarios] = useState({
		id: "",
		label: "",
		idSistemaRol: ""
	});
	const [inputValueAutoCompleteUsuarios, setInputValueAutoCompleteUsuarios] =
		useState("");
	const { menuOpen } = useMenu();

	const tableStyles = {
		width: menuOpen ?  "80%": "95%", 
		height: "90%",
		overflow: "auto",
		p: 1,
		margin: "auto",
		position: 'fixed', 
		top: "8%", 
		left: menuOpen ? '15%' : '0%', 
		transition: 'left 0.3s ease' 
	};

	const tableColumns = [
		{
			name: "id",
			label: "ID",
			options: {
				display: "excluded",
			},
		},
		{
			name: "descripcion_sistema",
			label: "Sistema",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			name: "descripcion_sistema_rol",
			label: "Rol",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			name: "username_usuario",
			label: "Usuario",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			name: "actions",
			label: "Acciones",
			options: {
				filter: false,
				sort: false,
				empty: true,
				customBodyRenderLite: (dataIndex, rowIndex) => (
					<>						
						{itemsCrud[dataIndex].habilitado && (
							<Tooltip title="Deshabilitar">
								<IconButton
									size="small"
									aria-label="disable"
									onClick={() =>
										handleClickActionCrud(itemsCrud[dataIndex], "disable")
									}
								>
									<RemoveIcon />
								</IconButton>
							</Tooltip>
						)}

						{!itemsCrud[dataIndex].habilitado && (
							<Tooltip title="Habilitar">
								<IconButton
									size="small"
									aria-label="enable"
									onClick={() =>
										handleClickActionCrud(itemsCrud[dataIndex], "enable")
									}
								>
									<AddIcon />
								</IconButton>
							</Tooltip>
						)}
            
            {(
              <Tooltip title="Ver">
                <IconButton
                  size="small"
                  aria-label="read"
                  onClick={() => handleClickActionCrud(itemsCrud[dataIndex], "read")}
                >
                  <ZoomInIcon />
                </IconButton>
              </Tooltip>
            )}					
					</>
				),
			},
		},
	];
	
	const tableOptions = {
		storageKey: "my-table-state",
		rowsPerPageOptions: [10, 20, 30],
		serverSide: true,
		count: totalRowsCount,
		rowsPerPage: rowsPerPage,
		onChangeRowsPerPage: (numberOfRows) => {
			setRowsPerPage(numberOfRows);
		},
		onChangePage: (currentPage) => {
			setCurrentPage(currentPage);
		},
		download: false,
		filter: false,
		print: false,
		search: false,
		viewColumns: false,
		selectableRowsHeader: false,
		selectableRows: "none",
		elevation: 5,
		textLabels: {
			body: {
				noMatch: "No se encuentran resultados para esta búsqueda",
			},
			pagination: {
				next: "Próxima",
				previous: "Previa",
				rowsPerPage: "Filas:",
				displayRows: "de",
			},
		},
	};
	//
	// ################################################################################ 
	//

	useEffect(() => {
		const loadSistemas = async () => {
			setIsDoingSomething(true);
			const response = await getSistemas({ estado: "habilitado" });
			setIsDoingSomething(false);

			if (response.ok) {
				let _response = response.data.data;
				let _sistemas = [];

				_response.forEach((item) => {
					_sistemas.push({
						id: item.id,
						label: item.descripcion,
					});
				});

				setSistemas(_sistemas);
				setSistemasRoles([])
				
			} else {
				setSistemas([]);
				setMessage({
					messageText: response.messageText,
					severity: response.messageSeverity,
				});
				setShowMessage(true);
			}
		};
		loadSistemas();
	}, []);
	//
	// ################################################################################
	//
	useEffect(() => {
		load();
	}, [currentPage, rowsPerPage]);
	//
	// ################################################################################
	//
	useEffect(() => {
		setValueAutoCompleteSistemasRoles({
			id: "",
			label: "",
		});
		setValueAutoCompleteUsuarios({
			id: "",
			label: "",
		});
	}, [valueAutoCompleteSistemas]);
	//
	// ################################################################################
	//
	const handleChange = (e) => {
		setSearchData({
			...searchData,
			[e.target.name]:
				e.target.type === "checkbox" ? e.target.checked : e.target.value,
		});
	};
	//
	// ################################################################################
	//
	const handleLimpiar = () => {

		setValueAutoCompleteSistemas({
			id: "",
			label: "",
		});
		setInputValueAutoCompleteSistemas("");
		setValueAutoCompleteSistemasRoles({
			id: "",
			label: "",
		});
		setInputValueAutoCompleteSistemasRoles("");
		setValueAutoCompleteUsuarios({
			id: "",
			label: "",
		});
		setInputValueAutoCompleteUsuarios("");
		setSearchData(initialSearchData);
		setItemsCrud([]);
		setSistemasRoles([]);
	};
	//
	// ################################################################################
	//
	
	const handleBuscar = () => {
		setCurrentPage(0);
		load();
		loadRoles();
		//loadUsuarios();
	};
	//
	// ################################################################################
	//
	const load = async () => {
		let _params = {
			idSistema: valueAutoCompleteSistemas?.id,
			idRol: valueAutoCompleteSistemasRoles?.id,
			idUsuario: valueAutoCompleteUsuarios?.id,
			estado: searchData.estado,
			sortOrder: searchData.orden,
      sortField: searchData.ordenarPor,
			rows: rowsPerPage,
			first: currentPage * rowsPerPage,
		};
		setIsDoingSomething(true);
		const response = await getSistemasRolesUsuarios(_params);
		setIsDoingSomething(false);
		if (response.ok) {
			setTotalRowsCount(response.data.count);
			setItemsCrud(response.data.data);
		} else {
			setMessage({
				messageText: response.messageText,
				severity: response.messageSeverity,
			});
			setShowMessage(true);
		}
	};
	//
	// ################################################################################
	//
	const loadRoles = async (newValue) => {
		let _params = {
			idSistema: newValue?.id || valueAutoCompleteSistemas?.id,
			idRol: newValue?.id,
		};
		setIsDoingSomething(true);
		const response = await getSistemasRoles(_params);
		setIsDoingSomething(false);

		if (response.ok) {
			let _response = response.data.data;
			let _roles = [];
			_response.forEach((item) => {
				_roles.push({
					id: item.id,
					label: item.descripcion_sistema_rol,
				});
			});

			setSistemasRoles(_roles);
		} else {
			setSistemasRoles([]);
			setMessage({
				messageText: response.messageText,
				severity: response.messageSeverity,
			});
			setShowMessage(true);
		}

	};
	//
	// ################################################################################ 
	//
	const loadUsuarios = async (newValue) => {
		let _params = {
			idSistema: newValue?.id,
		};
		
		setIsDoingSomething(true);
		const response = await getUsuariosOrganismosAreasFunciones(_params);
		setIsDoingSomething(false);
		if (response.ok) {
			let _response = response.data.data;
			let _usuarios = [];
			_response.forEach((item) => {
				_usuarios.push({
					id: item.id_usuario,
					label: item.apellido_usuario+', '+item.nombre_usuario,
				});
			});
		
			setUsuarios(_usuarios);
		} else {
			setUsuarios([]);
			setMessage({
				messageText: response.messageText,
				severity: response.messageSeverity,
			});
			setShowMessage(true);
		}
	};
	//
	// ################################################################################
	//
	const handleClickActionCrud = (itemCrud, action) => {
		let _itemCrud = {};

		if (action !== "create") {
			const loadItem = async () => {
				setIsDoingSomething(true);
				const response = await getSistemaRolUsuario({ id: itemCrud.id });
				setIsDoingSomething(false);

				if (response.ok) {
					_itemCrud = {
						id: response.data.data.id,
						sistema: {
							id: response.data.data.id_sistema,
							label: response.data.data.descripcion_sistema,
						},
						descripcion: response.data.data.descripcion_sistema_rol,
						observaciones: response.data.data.observaciones_sistema_rol,
						idUsuario: response.data.data.id_usuario,
						fechaCreacion: response.data.data.fecha_creacion,
						fechaActualizacion: response.data.data.fecha_actualizacion,
						nombreUsuario: response.data.data.nombre_usuario,
						apellidoUsuario: response.data.data.apellido_usuario,
						username: response.data.data.username,
						nombreUsuarioActualiza: response.data.data.nombre_usuario_actualiza,
						apellidoUsuarioActualiza: response.data.data.apellido_usuario_actualiza,
						usernameUsuarioActualiza: response.data.data.username_usuario_actualiza,
					};

					setActionCrud(action);
					setItemCrud(_itemCrud);
					setOpenDialog(true);
				} else {
					setMessage({
						severity: response.messageSeverity,
						messageText: response.messageText,
					});
					setShowMessage(true);
					return;
				}
			};
			loadItem();
		} else {
			setActionCrud(action);
			setItemCrud(_itemCrud);
			setOpenDialog(true);
		}
	};

	//
	// ################################################################################
	//

	const handleDialogSave = () => {
		load();
		setOpenDialog(false);
	};

	//
	// ################################################################################
	//

	const handleDialogClose = () => {
		setOpenDialog(false);
	};

	//
	// ################################################################################
	//

	const handleGenerarPDF = () => {
		generarPDF();
	};

	//
	// ################################################################################
	//
	
	const generarPDF = async () => {
		let _params = {
			idSistema: valueAutoCompleteSistemas?.id,
			idRol: valueAutoCompleteSistemasRoles?.id,
			idUsuario: valueAutoCompleteUsuarios?.id,
			sortOrder: searchData.orden,
      		sortField: searchData.ordenarPor,		
			estado: searchData.estado,
		};

		setIsDoingSomething(true);
		const response = await generarPDFSistemasRolesUsuarios(_params);

		setIsDoingSomething(false);
		if (!response.ok) {
			setMessage({
				messageText: response.messageText,
				severity: response.messageSeverity,
			});
			setShowMessage(true);
		}
	};

	//
	// ################################################################################
	//
	// let table = data.data.table
	return (
		<>
			<Paper
				sx={tableStyles}
				elevation={2}
			>
				<Box
					sx={{
						margin: "auto",
						width: "90%",
					}}
				>
					<Box sx={{ m: 1, p: 1 }}>
						<Grid container spacing={1}>
							<Grid item xs={12}>
								<h2>{nameItemsText}</h2>
							</Grid>
							<Grid item xs={12} md={6} lg={3}>
								<Autocomplete
									id="sistema"
									size="small"
									value={valueAutoCompleteSistemas}
									onChange={(event, newValue) => {
										setValueAutoCompleteSistemas(newValue);
										setSearchData({
											...searchData,
											idSistema: newValue?.id,
											idSistemaRol: newValue?.id_sistema_rol
										});
										loadRoles(newValue)
										loadUsuarios(newValue)
									}}
									inputValue={inputValueAutoCompleteSistemas}
									onInputChange={(event, newInputValue) => {
										setInputValueAutoCompleteSistemas(newInputValue);
									}}
									options={sistemas}
									renderInput={(params) => (
										<TextField {...params} label="Sistema" />
									)}
									isOptionEqualToValue={(option, value) =>
										option.value === value.value
									}
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={3}>
								<Autocomplete
									id="rol"
									size="small"
									disabled={!valueAutoCompleteSistemas?.id}
									value={valueAutoCompleteSistemasRoles}
									onChange={(event, newValue) => {
										setValueAutoCompleteSistemasRoles(newValue);
										setSearchData({
											...searchData,
											idRol: newValue?.id,
										});
									}}
									inputValue={inputValueAutoCompleteSistemasRoles}
									onInputChange={(event, newInputValue) => {
										setInputValueAutoCompleteSistemasRoles(newInputValue);
									}}
									options={sistemasRoles}
									renderInput={(params) => (
										<TextField {...params} label="Rol" />
									)}
									isOptionEqualToValue={(option, value) =>
										option.value === value.value
									}
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={3}>
								<Autocomplete
									id="usuario"
									size="small"
									disabled={!valueAutoCompleteSistemas?.id}
									value={valueAutoCompleteUsuarios}
									onChange={(event, newValue) => {
										setValueAutoCompleteUsuarios(newValue);
										setSearchData({
											...searchData,
											idSistema: newValue?.id,
											idSistemaRol: newValue?.idSistemaRol
										});
									}}
									inputValue={inputValueAutoCompleteUsuarios}
									onInputChange={(event, newInputValue) => {
										setInputValueAutoCompleteUsuarios(newInputValue);
									}}
									options={usuarios}
									renderInput={(params) => (
										<TextField {...params} label="Usuario" />
									)}
									isOptionEqualToValue={(option, value) =>
										option.value === value.value
									}
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={3}>
								<FormControl sx={{ width: "100%" }}>
									<InputLabel id="estado-select-label">Estado</InputLabel>
									<Select
										sx={{ width: "100%", mr: 1 }}
										labelId="estado"
										label="Estado"
										id="estado"
										name="estado"
										value={searchData.estado}
										size="small"
										onChange={handleChange}
									>
										{estadoOptions.map((item) => (
											<MenuItem key={item.id} value={item.id}>
												{item.descripcion}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Box>
					<Box sx={{ m: 1, p: 1 }}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={3}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="ordenarPor-select-label">
                    Ordenar por:
                  </InputLabel>
                  <Select
                    sx={{ width: "100%", mr: 1 }}
                    labelId="ordenarPor"
                    label="Ordenar por:"
                    id="ordenaPor"
                    name="ordenarPor"
                    value={searchData.ordenarPor}
                    size="small"
                    onChange={handleChange}
                  >
                    {ordenarPorOptions.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.descripcion}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
							<Grid item xs={12} md={0.5}>
              </Grid>
              <Grid item xs={12} md={4}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <FormControl component="fieldset">
                      <FormLabel
                        component="legend"
                        sx={{
                          textAlign: "center",
                          mb: 1,
                          fontSize: "0.875rem",
                        }}
                      >
                        Orden
                      </FormLabel>
                      <RadioGroup
                        aria-label="Orden"
                        name="orden"
                        value={searchData.orden}
                        onChange={handleChange}
                        row
                      >
                        {ordenOptions
                          .slice(0, Math.ceil(ordenOptions.length / 2))
                          .map((item) => (
                            <FormControlLabel
                              key={item.id}
                              value={item.id}
                              label={item.descripcion}
                              control={<Radio />}
                            />
                          ))}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl component="fieldset">
                      <FormLabel
                        component="legend"
                        sx={{
                          textAlign: "center",
                          mb: 1,
                          fontSize: "0.875rem",
                        }}
                      >
                        &nbsp;
                      </FormLabel>
                      <RadioGroup
                        aria-label="Orden"
                        name="orden"
                        value={searchData.orden}
                        onChange={handleChange}
                        row
                      >
                        {ordenOptions
                          .slice(Math.ceil(ordenOptions.length / 2))
                          .map((item) => (
                            <FormControlLabel
                              key={item.id}
                              value={item.id}
                              label={item.descripcion}
                              control={<Radio />}
                            />
                          ))}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
					<Box sx={{ m: 1, p: 1 }}>
						<Grid container spacing={1}>
							<Grid item xs={12} md={3} lg={2}>
								<Button
									sx={{ width: "100%" }}
									variant="contained"
									onClick={() => handleClickActionCrud({}, "create")}
									startIcon={<AddIcon />}
								>
									Nuevo
								</Button>
							</Grid>
							<Grid item xs={12} md={3} lg={2}>
								<Button
									sx={{ width: "100%" }}
									variant="contained"
									onClick={handleBuscar}
									startIcon={<SearchIcon />}
								>
									Buscar
								</Button>
							</Grid>
							<Grid item xs={12} md={3} lg={2}>
								<Button
									sx={{ width: "100%", mr: 1 }}
									variant="contained"
									onClick={handleLimpiar}
									startIcon={<CleaningServicesIcon />}
								>
									Limpiar
								</Button>
							</Grid>
							<Grid item xs={12} md={3} lg={2} >
							</Grid>
							<Grid item xs={12} md={3} lg={2} >
							</Grid>							
							<Grid item xs={12} md={3} lg={2} >
								<Button
									sx={{ width: "100%" }}
									variant="contained"
									onClick={handleGenerarPDF}
									startIcon={<PictureAsPdfIcon />}
								>
									Generar PDF
								</Button>
							</Grid>
						</Grid>
					</Box>

          <Box sx={{ m: 1, p: 1 }}>
          </Box>
            {isDoingSomething && <LinearProgress />} 
					<ThemeProvider theme={darkTheme}>
						<MUIDataTable
							columns={tableColumns}
							data={itemsCrud}
							options={tableOptions}
						/>
					</ThemeProvider>
				</Box>
			</Paper>

			{/* >>>> Dialog */}

			{openDialog && (
				<SistemaRolUsuarioDialog
					sistemas={sistemas}
					onSave={handleDialogSave}
					onClose={handleDialogClose}
					itemCrud={itemCrud}
					actionCrud={actionCrud}
					setMessage={setMessage}
					setShowMessage={setShowMessage}
				/>
			)}

			{/* >>>> Messages */}

			{showMessage && (
				<Message
					showMessage={showMessage}
					setShowMessage={setShowMessage}
					message={message}
				/>
			)}
		</>
	);
};

export default SistemaRolUsuarioTable;
