import axios from "axios";
import { urlBackendUsuarios } from "../configs/config";
import { format } from "date-fns";
import keycloakObject from "../utils/keycloak";



const epName = "/feriado/";
const nameItemsText = "Feriados";
const nameItemText = "Feriado";
const itemGender = "M";
const elLa = itemGender === "M" ? "el" : "la";
// const unUna = itemGender === "M" ? "un" : "una";

//
// #############################################################################
//
export const getFeriados = async (params) => {
	let _params = {};
	let _errorMessage = `Se produjo un error al obtener ${nameItemsText}`;

	if (params.descripcion) {
		_params.descripcion = params.descripcion;
	}

	if (params.fechaDesde) {
		_params.fecha_desde = format(params.fechaDesde, 'yyyy/MM/dd');
	}

	if (params.fechaHasta) {
		_params.fecha_hasta = format(params.fechaHasta, 'yyyy/MM/dd');
	}

	if (params.estado) {
		let estado =
			params.estado === "habilitado"
				? "H"
				: params.estado === "deshabilitado"
				? "D"
				: "";
		_params.habilitado = estado;
	}

	if (params.rows) {
		_params.rows = params.rows;
	}

	if (params.first) {
		_params.first = params.first;
	}

	if (params.sortField) {
		_params.sortfield = params.sortField;
	}

	if (params.sortOrder) {
		_params.sortorder = params.sortOrder;
	}

	try {
		const response = await axios({
			method: "GET",
			url: `${urlBackendUsuarios}${epName}`,
			params: _params,
			headers: {'Authorization': `Bearer ${localStorage.getItem('Token')}`},
		});

		if (response.status === 200) {
			let _error = "error_code" in response.data;
			if (!_error) {
				// si no hay error controlado
				response.ok = true;
				return response;
			}
		}

		// si hay algun error controlado
		console.log(_errorMessage);
		console.log(response);

		response.ok = false;
		response.messageText = _errorMessage;
		response.messageSeverity = "error";
		return response;
	} catch (error) {
		console.log(_errorMessage);
		if (error.response) {
			// The request was made and the server responded with a status code that falls out of the range of 2xx
			console.log(error.response);
		} else if (error.request) {
			// The request was made but no response was received
			// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
			// http.ClientRequest in node.js
			console.log(error.request);
		} else {
			// Something happened in setting up the request that triggered an Error
			console.log(error);
		}
		let response = {};
		response.ok = false;
		response.messageText = _errorMessage;
		response.messageSeverity = "error";
		return response;
	}
};
//
// #############################################################################
//
export const getFeriado = async (params) => {
	let _errorMessage = `Se produjo un error al obtener ${elLa} ${nameItemText}`;
	try {
		const response = await axios({
			method: "GET",
			url: `${urlBackendUsuarios}${epName}`,
			params: params,
			headers: {'Authorization': `Bearer ${keycloakObject.getToken()}`},
		});

		if (response.status === 200) {
			let _error = "error_code" in response.data;
			if (!_error) {
				// si no hay error controlado
				response.ok = true;
				return response;
			}
		}

		// si hay algun error controlado
		
		console.log(_errorMessage);
		console.log(response);

		response.ok = false;
		response.messageText = _errorMessage;
		response.messageSeverity = "error";
		return response;
	} catch (error) {
		console.log(_errorMessage);
		if (error.response) {
			// The request was made and the server responded with a status code that falls out of the range of 2xx
			console.log(error.response);
		} else if (error.request) {
			// The request was made but no response was received
			// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
			// http.ClientRequest in node.js
			console.log(error.request);
		} else {
			// Something happened in setting up the request that triggered an Error
			console.log(error);
		}
		let response = {};
		response.ok = false;
		response.messageText = _errorMessage;
		response.messageSeverity = "error";
		return response;
	}
};
//
// #############################################################################
//
export const saveFeriado = async (actionCrud, item) => {
	let _errorMessage = `No fue posible guardar ${elLa} ${nameItemText}`;

	let _item = {
		descripcion: item.descripcion,
		fecha: item.fecha,		
		username_actualiza: item.usernameUsuarioActualiza,
	};

	if (actionCrud !== "create") {
		_item.id = item.id;
	}

	try {
		let response = {};
		if (actionCrud === "create") {
			response = await axios({
				method: "POST",
        url: `${urlBackendUsuarios}${epName}`,
				data: _item,
				headers: {'Authorization': `Bearer ${keycloakObject.getToken()}`},
			});
		} else if (actionCrud === "update") {
			response = await axios({
				method: "PUT",
        url: `${urlBackendUsuarios}${epName}`,
				data: _item,
				headers: {'Authorization': `Bearer ${keycloakObject.getToken()}`},
			});
		} else if (actionCrud === "disable" || actionCrud === "enable") {
			response = await axios({
				method: "DELETE",
				url: `${urlBackendUsuarios}${epName}`,
				params: {
					id: _item.id,
					username_actualiza: _item.username_actualiza,
					action: actionCrud,
				},
				headers: {'Authorization': `Bearer ${keycloakObject.getToken()}`},
			});
		}

		if (response.status === 200) {
    let _error = "error_code" in response.data;
			if (!_error) {
				// si no hay error controlado
				response.ok = true;
				response.messageText =
					`${nameItemText}` +
					(actionCrud === "disable"
						? itemGender === "M"
							? " deshabilitado"
							: " deshabilitada"
						: actionCrud === "enable"
						? itemGender === "M"
							? " habilitado"
							: " habilitada"
						: itemGender === "M"
						? " guardado"
						: " guardada");
						response.messageSeverity = "success";
			} else {
				// si hay error controlado 
				console.log(_errorMessage);
				console.log(response);

				let _message_code = response.data.message_code;
				if (_message_code === "err-feriado-invalid") {
					response.messageText = `La fecha es inválida.`;
				} else if (_message_code === "err-feriado-already-exist") {
					response.messageText = `Ya existe un feriado con la fecha ingresada.`;
				} else {

					response.messageText = _errorMessage;
				}
        response.ok = false;
        response.messageSeverity = "error";
			}
			return response;
		}
	} catch (error) {
		console.log(_errorMessage);
		if (error.response) {
			// The request was made and the server responded with a status code that falls out of the range of 2xx
			console.log(error.response);
		} else if (error.request) {
			// The request was made but no response was received
			// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
			// http.ClientRequest in node.js
			console.log(error.request);
		} else {
			// Something happened in setting up the request that triggered an Error
			console.log(error);
		}
		let response = {};
		response.ok = false;
		response.messageText = _errorMessage;
		response.messageSeverity = "error";
		return response;
	}
};

//
// #############################################################################
//