import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useEffect, useState } from "react";
import { getProcesos } from "../../services/ProcesoService";
import { getMaterias } from "../../services/MateriaService";
import { getTiposVinculos } from "../../services/TipoVinculoService";
import { saveMateriaTipoVinculo } from "../../services/MateriaTipoVinculoService";
import darkTheme from "../../layout/DarkTheme";

const initialState = {
  id: 0,
  idMateria: 0,
  idTipoVinculo: 0,
  materia: "",
  tipoVinculo: "",
  fuero: "",
  fechaActualizacion: "",
  nombreUsuario: "",
  apellidoUsuario: "",
  username: "",
};


const itemGender = "F";
const nameItemText = "Materia - Tipo Vínculo";

const MateriaTipoVinculoDialog = ({
  fueros,

  onSave,
  onClose,
  itemCrud,
  actionCrud,
  setMessage,
  setShowMessage,
}) => {
  const [itemCrudLocal, setItemCrudLocal] = useState(initialState);
  const [isDoingSomething, setIsDoingSomething] = useState(false);
  const [materiasLocal, setMateriasLocal] = useState([]);
  const [procesosLocal, setProcesosLocal] = useState([]);
  const [tipoVinculosLocal, setTipoVinculosLocal] = useState([]);
  const [valueAutoCompleteFueros, setValueAutoCompleteFueros] = useState(itemCrud.fuero);
  const [inputValueAutoCompleteFueros, setInputValueAutoCompleteFueros] = useState("");
  const [valueAutoCompleteProceso, setValueAutoCompleteProceso] = useState(itemCrud.proceso);
  const [inputValueAutoCompleteProceso, setInputValueAutoCompleteProceso] = useState("");
  const [valueAutoCompleteMaterias, setValueAutoCompleteMaterias] = useState(itemCrud.materia);
  const [inputValueAutoCompleteMaterias, setInputValueAutoCompleteMaterias] = useState("");
  const [valueAutoCompleteTipoVinculos, setValueAutoCompleteTipoVinculos] = useState(itemCrud.tipoVinculo);
  const [inputValueAutoCompleteTipoVinculos, setInputValueAutoCompleteTipoVinculos] = useState("");

  useEffect(() => {
    if (actionCrud === "create") {
      setValueAutoCompleteFueros("");
      setItemCrudLocal(initialState);
    } else {
      setValueAutoCompleteFueros(itemCrud.fuero);
      setValueAutoCompleteProceso(itemCrud.proceso);
      setValueAutoCompleteMaterias(itemCrud.materia);
      setValueAutoCompleteTipoVinculos(itemCrud.tipoVinculo);
      setItemCrudLocal(itemCrud);
      loadMaterias(itemCrud.fuero);
      loadTipoVinculos();
    }
  }, []);

  const handleCloseDialog = () => {
    onClose();
  };

  const handleFueroChange = (event, newValue) => {
    setValueAutoCompleteFueros(newValue);
    setValueAutoCompleteProceso("");
    setValueAutoCompleteTipoVinculos("");
    loadProceso(newValue);
  };
  
  const handleProcesoChange = (event, newValue) => {
    setValueAutoCompleteProceso(newValue);
    setValueAutoCompleteMaterias("");
    setValueAutoCompleteTipoVinculos("");
    loadMaterias(newValue);
    loadTipoVinculos();
  };

  const handleSave = () => {
    if (actionCrud === "create" || actionCrud === "update") {
      if (inputValueAutoCompleteFueros === "") {
        setMessage({
          severity: "error",
          messageText: "Debes seleccionar un Fuero",
        });
        setShowMessage(true);
        return;
      }

      if (inputValueAutoCompleteMaterias === "") {
        setMessage({
          severity: "error",
          messageText: "Debes seleccionar una Materia",
        });
        setShowMessage(true);
        return;
      }

      if (inputValueAutoCompleteTipoVinculos === "") {
        setMessage({
          severity: "error",
          messageText: "Debes seleccionar un Tipo Vínculo",
        });
        setShowMessage(true);
        return;
      }
    }
    save();
  };

  const save = async () => {
    let _itemCrud = {
      idMateria: valueAutoCompleteMaterias?.id,     
      idTipoVinculo: valueAutoCompleteTipoVinculos?.id,
      usernameUsuarioActualiza: localStorage.getItem("emailUsuario"),
    };
    if (actionCrud !== "create") {
      _itemCrud.id = itemCrud.id;
    }
    setIsDoingSomething(true);
    const response = await saveMateriaTipoVinculo(actionCrud, _itemCrud);
    setIsDoingSomething(false);

    if (response.ok) {
      onSave();
    }

    setMessage({
      messageText: response.messageText,
      severity: response.messageSeverity,
    });
    setShowMessage(true);
  };

  const loadProceso = async (fuero) => {
    let _params = {
      idFuero: fuero?.id || valueAutoCompleteFueros?.id,
    };
    setIsDoingSomething(true);
    const response = await getProcesos(_params);
    setIsDoingSomething(false);
    if (response.ok) {
      let _response = response.data.data;
      let _procesos = [];
      _response.forEach((item) => {
        _procesos.push({
          id: item.id,
          label: item.descripcion,
        });
      });
      setProcesosLocal(_procesos);
    } else {
      setProcesosLocal([]);
      setMessage({
        messageText: response.messageText,
        severity: response.messageSeverity,
      });
      setShowMessage(true);
    }
  };

  const loadMaterias = async (proceso) => {
    let _params = {
      idProceso: proceso?.id || valueAutoCompleteProceso?.id,
      };
    setIsDoingSomething(true);

    const response = await getMaterias(_params);
    setIsDoingSomething(false);
    if (response.ok) {
      let _response = response.data.data;
      let _materias = [];
      _response.forEach((item) => {
        _materias.push({
          id: item.id,
          label: item.descripcion,
        });
      });
      setMateriasLocal(_materias);
    } else {
      setMateriasLocal([]);
      setMessage({
        messageText: response.messageText,
        severity: response.messageSeverity,
      });
      setShowMessage(true);
    }
  };

  const loadTipoVinculos = async () => {
    let _params = {
      estado: 'habilitado',
    };
    setIsDoingSomething(true);
    const response = await getTiposVinculos(_params);
    setIsDoingSomething(false);
    if (response.ok) {
      let _response = response.data.data;
      let _tipo_vinculos = [];
      _response.forEach((item) => {
        _tipo_vinculos.push({
          id: item.id,
          label: item.descripcion,
        });
      });
      setTipoVinculosLocal(_tipo_vinculos);
    } else {
      setTipoVinculosLocal([]);
      setMessage({
        messageText: response.messageText,
        severity: response.messageSeverity,
      });
      setShowMessage(true);
    }
  };

  return (
    <>
      <Dialog
        open={true}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth={true}
        scroll="paper"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title"
          textAlign={{ xs: "center", sm: "center" }}
          style={{ background: darkTheme.palette.primary.dark, color: darkTheme.palette.primary.text}}
        >
          {actionCrud === "create"
            ? itemGender === "M"
              ? "Nuevo"
              : "Nueva"
            : actionCrud === "update"
              ? "Editando"
              : actionCrud === "enable"
                ? "Habilitando"
                : actionCrud === "disable"
                  ? "Deshabilitando"
                  : actionCrud === "read"
                    ? "Detalle"
                    : ""}{" "}
          {nameItemText}
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        {isDoingSomething && <LinearProgress />}

        <DialogContent dividers>
          <Box sx={{ m: 1, p: 1 }}>
            <Autocomplete
              id="fuero"
              sx={{ width: "100%", pb: 1 }}
              size="small"
              value={valueAutoCompleteFueros}
              onChange={handleFueroChange}
              inputValue={inputValueAutoCompleteFueros}
              onInputChange={(event, newInputValue) => {
                setInputValueAutoCompleteFueros(newInputValue);
              }}
              options={fueros}
              renderInput={(params) => (
                <TextField {...params} label="Fuero" />
              )}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
              disableClearable={true}
            />
            
            <Autocomplete
              id="proceso"
              sx={{ width: "100%", pb: 1 }}
              size="small"
              value={valueAutoCompleteProceso}
              onChange={handleProcesoChange}
              inputValue={inputValueAutoCompleteProceso}
              onInputChange={(event, newInputValue) => {
                setInputValueAutoCompleteProceso(newInputValue);
              }}
              options={procesosLocal}
              renderInput={(params) => (
                <TextField {...params} label="Proceso" />
              )}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read" || !valueAutoCompleteFueros?.id }
            />

            <Autocomplete
              id="materia"
              sx={{ width: "100%", pb: 1 }}
              size="small"
              value={valueAutoCompleteMaterias}
              onChange={(event, newValue) => {
                setValueAutoCompleteMaterias(newValue);
                setValueAutoCompleteTipoVinculos("");
              }}
              inputValue={inputValueAutoCompleteMaterias}
              onInputChange={(event, newInputValue) => {
                setInputValueAutoCompleteMaterias(newInputValue);
              }}
              options={materiasLocal}
              renderInput={(params) => (
                <TextField {...params} label="Materia" />
              )}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read" || !valueAutoCompleteProceso?.id }
            />

            <Autocomplete
              id="tipoVinculo"
              sx={{ width: "100%", pb: 1 }}
              size="small"
              value={valueAutoCompleteTipoVinculos}
              onChange={(event, newValue) => {
                setValueAutoCompleteTipoVinculos(newValue);
              }}
              inputValue={inputValueAutoCompleteTipoVinculos}
              onInputChange={(event, newInputValue) => {
                setInputValueAutoCompleteTipoVinculos(newInputValue);
              }}
              options={tipoVinculosLocal}
              renderInput={(params) => (
                <TextField {...params} label="Tipo Vínculo" />
              )}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
            />

            {actionCrud !== "create" && (
              <Box
                sx={{
                  p: 1,
                  mt: 1,
                  color: "SlateGrey",
                  border: "0px solid DarkGray",
                  borderRadius: "3px",
                }}
              >
                <Typography variant="h8" component="div" gutterBottom>
                  Fecha creación: {itemCrudLocal.fechaCreacion}
                </Typography>

                <Typography variant="h8" component="div" gutterBottom>
                  Último usuario que actualizó: {itemCrudLocal.nombreUsuario}{" "}
                  {itemCrudLocal.apellidoUsuario} ({itemCrudLocal.username})
                </Typography>
              </Box>
            )}
          </Box>

          <Box sx={{ textAlign: "right" }}>
            <Button
              sx={{ mr: 1 }}
              variant="outlined"
              onClick={handleCloseDialog}
              startIcon={<CloseIcon />}
            >
              Cancelar
            </Button>

            {(actionCrud !== "read") && (
              <Button
                sx={{ mr: 1, backgroundcolor: "warning" }}
                variant="contained"
                onClick={handleSave}
                startIcon={
                  actionCrud === "disable" ? (
                    <RemoveCircleOutlineIcon />
                  ) : actionCrud === "enable" ? (
                    <AddCircleOutlineIcon />
                  ) : (
                    <SaveIcon />
                  )
                }
              >
                {actionCrud === "disable"
                  ? "Deshabilitar"
                  : actionCrud === "enable"
                    ? "Habilitar"
                    : "Guardar"}
              </Button>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MateriaTipoVinculoDialog;
