import {
	Autocomplete,
	Button,
	FormControl,
	Grid,
	InputLabel,
	LinearProgress,
	MenuItem,
	Paper,
	Select,
	TextField,
	Tooltip,
	ThemeProvider,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import RemoveIcon from "@mui/icons-material/Remove";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import { useEffect, useState } from "react";
import { getUsuario } from "../../services/UsuarioService";
import { getLocalidad, getLocalidades } from "../../services/LocalidadService";
import LocalidadDialog from "./LocalidadDialog";
import { getDepartamentos } from "../../services/DepartamentoService";
import Message from "../../utils/Message";
import { Box } from "@mui/system";
import darkTheme from "../../layout/DarkTheme";
import { getProvincias } from "../../services/ProvinciaService";
import React from 'react';
import { useMenu } from "../../context/MenuContext.js";

const initialState = {
	descripcion: "",
	estado: "habilitado",
	rows: 0,
	first: 0,
};

const estadoOptions = [
	{
		id: "todo",
		descripcion: "Todo",
	},
	{
		id: "habilitado",
		descripcion: "Habilitado",
	},
	{
		id: "deshabilitado",
		descripcion: "Deshabilitado",
	},
];
const nameItemsText = "Localidades";
//
// #############################################################################
//
const LocalidadTable = ({ ...data }) => {
	const [searchData, setSearchData] = useState(initialState);
	const [itemsCrud, setItemsCrud] = useState([]);
	const [itemCrud, setItemCrud] = useState({});
	const [actionCrud, setActionCrud] = useState("");
	const [isDoingSomething, setIsDoingSomething] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const [totalRowsCount, setTotalRowsCount] = useState(0);
	const [currentPage, setCurrentPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [message, setMessage] = useState({});
	const [showMessage, setShowMessage] = useState(false);
	const [departamentos, setDepartamentos] = useState([]);
	const [provincias, setProvincias] = useState([]);
	const [localidades, setLocalidades] = useState([]);
	const [valueAutoCompleteProvincias, setValueAutoCompleteProvincias] = useState({
		id: "",
		label: "",
	});
	const [inputValueAutoCompleteProvincias, setInputValueAutoCompleteProvincias] = useState("");
	const [valueAutoCompleteDepartamentos, setValueAutoCompleteDepartamentos] = useState({
		id: "",
		label: "",
	});
	const [inputValueAutoCompleteDepartamentos, setInputValueAutoCompleteDepartamentos] = useState("");
	const [valueAutoCompleteLocalidades, setValueAutoCompleteLocalidades] = useState({
		id: "",
		label: "",
	});
	const [inputValueAutoCompleteLocalidades, setInputValueAutoCompleteLocalidades] = useState("");
  const { menuOpen } = useMenu();

  const tableStyles = {
    width: menuOpen ?  "80%": "95%", 
    height: "90%",
    overflow: "auto",
    p: 1,
    margin: "auto",
    position: 'fixed', 
    top: "8%", 
    left: menuOpen ? '15%' : '0%', 
    transition: 'left 0.3s ease' 
  };

	const tableColumns = [
		{
			name: "id",
			label: "ID",
			options: {
				display: "excluded",
			},
		},
		{
			name: "descripcion_provincia",
			label: "Provincia",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			name: "descripcion_departamento",
			label: "Departamento",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			name: "descripcion",
			label: "Localidad",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			name: "observaciones",
			label: "Observaciones",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			name: "actions",
			label: "Acciones",
			options: {
				filter: false,
				sort: false,
				empty: true,
				customBodyRenderLite: (dataIndex, rowIndex) => (
					<>
						{itemsCrud[dataIndex].habilitado && (
							<Tooltip title="Editar">
								<IconButton
									size="small"
									aria-label="update"
									onClick={() => handleClickActionCrud(itemsCrud[dataIndex], "update")}
								>
									<EditOutlinedIcon />
								</IconButton>
							</Tooltip>
						)}

						{itemsCrud[dataIndex].habilitado && (
							<Tooltip title="Deshabilitar">
								<IconButton
									size="small"
									aria-label="disable"
									onClick={() => handleClickActionCrud(itemsCrud[dataIndex], "disable")}
								>
									<RemoveIcon />
								</IconButton>
							</Tooltip>
						)}

						{!itemsCrud[dataIndex].habilitado && (
							<Tooltip title="Habilitar">
								<IconButton
									size="small"
									aria-label="enable"
									onClick={() => handleClickActionCrud(itemsCrud[dataIndex], "enable")}
								>
									<AddIcon />
								</IconButton>
							</Tooltip>
						)}

						{(
              <Tooltip title="Ver">
                <IconButton
                  size="small"
                  aria-label="read"
                  onClick={() => handleClickActionCrud(itemsCrud[dataIndex], "read")}
                >
                  <ZoomInIcon />
                </IconButton>
              </Tooltip>
            )}
          </>
				),
			},
		},
	];

	const tableOptions = {
		storageKey: "my-table-state",
		rowsPerPageOptions: [10, 20, 30],
		serverSide: true,
		count: totalRowsCount,
		rowsPerPage: rowsPerPage,
		onChangeRowsPerPage: (numberOfRows) => {
			setRowsPerPage(numberOfRows);
		},
		onChangePage: (currentPage) => {
			setCurrentPage(currentPage);
		},
		download: false,
		filter: false,
		print: false,
		search: false,
		viewColumns: false,
		selectableRowsHeader: false,
		selectableRows: "none",
		elevation: 1,
		textLabels: {
			body: {
				noMatch: "No se han encontrado resultados para esta búsqueda",
			},
			pagination: {
				next: "Próxima",
				previous: "Previa",
				rowsPerPage: "Filas:",
				displayRows: "de",
			},
		},
	};
	//
	// ################################################################################
	//
	const load = async () => {
		searchData.rows = rowsPerPage;
		searchData.first =  currentPage * rowsPerPage
		
		setIsDoingSomething(true);
		const response = await getLocalidades(searchData);
		setIsDoingSomething(false);
		if (response.ok) {
			setItemsCrud(response.data.data);
			setTotalRowsCount(response.data.count)
		} else {
			setLocalidades([]);
			setMessage({
				messageText: response.messageText,
				severity: response.messageSeverity,
			});
			setShowMessage(true);
		}
	};
	//
	// ################################################################################
	//

	useEffect(() => {
		const loadProvincias = async () => {
			setIsDoingSomething(true);
			const response = await getProvincias({ estado: "habilitado" });
			setIsDoingSomething(false);

			if (response.ok) {
				let _response = response.data.data;
				let _provincias = [];

				_response.forEach((item) => {
					_provincias.push({
						id: item.id,
						label: item.descripcion,
					});
				});

				setProvincias(_provincias);
				setDepartamentos([])
				
			} else {
				setProvincias([]);
				setMessage({
					messageText: response.messageText,
					severity: response.messageSeverity,
				});
				setShowMessage(true);
			}
		};
		loadProvincias();
		loadDepartamentos();
	}, []);
	//
	// ################################################################################
	//
	useEffect(() => {
		load();
	}, [currentPage, rowsPerPage]);
	//
	// ################################################################################
	//
	useEffect(() => {
		setValueAutoCompleteDepartamentos({
			id: "",
			label: "",
		});
		setValueAutoCompleteLocalidades({
			id: "",
			label: "",
		});
	}, [valueAutoCompleteProvincias]);
	//
	// ################################################################################
	//
	const handleChange = (e) => {
		setSearchData({
			...searchData,
			[e.target.name]: e.target.type === "checkbox" ? e.target.checked : e.target.value,
		});
	};
	//
	// ################################################################################
	//
	const search = () => {
		setCurrentPage(0);
		load();
	};

	const clean = () => {
		setValueAutoCompleteProvincias({id: "",	label: ""});
		setValueAutoCompleteDepartamentos({ label: "", id: "" });
		setValueAutoCompleteLocalidades({ label: "", id: "" });
		setSearchData(initialState);
		setItemsCrud([]);
	};
//
	// ################################################################################
	//
	
	const loadDepartamentos = async (newValue) => {
		let _params = {
			idProvincia: newValue?.id || valueAutoCompleteProvincias?.id,
			idDepartamento: valueAutoCompleteDepartamentos?.id,
			id: valueAutoCompleteLocalidades?.id,
			estado: searchData.estado,

		};
		setIsDoingSomething(true);
		const response = await getDepartamentos(_params);
		setIsDoingSomething(false);
		if (response.ok) {
			let _response = response.data.data;
			let _departamentos = [];
			_response.forEach((item) => {
				_departamentos.push({
					id: item.id,
					label: item.descripcion,
				});
			});
			setDepartamentos(_departamentos);
		} else {
			setDepartamentos([]);
			setMessage({
				messageText: response.messageText,
				severity: response.messageSeverity,
			});
			setShowMessage(true);
		}
		loadLocalidades();

	};
	//
	// ################################################################################
	//
	
	const loadLocalidades = async (newValue) => {
		let _params = {
			idProvincia: valueAutoCompleteProvincias?.id,
			idDepartamento: newValue?.id || valueAutoCompleteDepartamentos?.id,
			id: valueAutoCompleteLocalidades?.id,
			estado: searchData.estado,
			// rows: rowsPerPage,
			// first: currentPage * rowsPerPage,
		};
		
		setIsDoingSomething(true);
		const response = await getLocalidades(_params);
		setIsDoingSomething(false);

		if (response.ok) {
			let _response = response.data.data;
			let _localidades = [];
			_response.forEach((item) => {
				_localidades.push({
					id: item.id,
					label: item.descripcion,
				});
			});
		
			setLocalidades(_localidades);
		} else {
			setLocalidades([]);
			setMessage({
				messageText: response.messageText,
				severity: response.messageSeverity,
			});
			setShowMessage(true);
		}
	};
	//
	// ################################################################################
	//
	const handleClickActionCrud = (itemCrud, action) => {
		let _itemCrud = {};
		if (action !== "create") {

			const loadItem = async () => {
				setIsDoingSomething(true);
				const response = await getLocalidad({ id: itemCrud.id });
				setIsDoingSomething(false);
				if (response.ok) {
					_itemCrud = {
						id: response.data.data.id,
						provincia: {
							id: response.data.data.id_provincia,
							label: response.data.data.descripcion_provincia,
						},
						departamento: {
							id: response.data.data.id_departamento,
							label: response.data.data.descripcion_departamento,
						},
						localidad: {
							id: response.data.data.id,
							label: response.data.data.descripcion_localidad,
						},
						descripcion: response.data.data.descripcion,
						observaciones: response.data.data.observaciones,
						fechaCreacion: response.data.data.fecha_creacion,
						fechaActualizacion: response.data.data.fecha_actualizacion,
            username: response.data.data.username_actualiza,
					};

					loadUsuario();
			

				} else {
					setMessage({
						severity: response.messageSeverity,
						messageText: response.messageText,
					});
					setShowMessage(true);
					return;
				}
			};
			loadItem();

			const loadUsuario = async () => {
				setIsDoingSomething(true);
				const response = await getUsuario({ username: _itemCrud.username });
				setIsDoingSomething(false);

				if (response.ok) {
					_itemCrud.nombreUsuario = response.data.data[0].nombre;
          _itemCrud.apellidoUsuario = response.data.data[0].apellido;


          setActionCrud(action);
					setItemCrud(_itemCrud);
					setOpenDialog(true);

				} else {
					setMessage({
						severity: response.messageSeverity,
						messageText: response.messageText,
					});
					setShowMessage(true);
					return;
				}
			};

		} else {
			setActionCrud(action);
			setItemCrud(_itemCrud);
			setOpenDialog(true);
		}
	};
	// ################################################################################
	//
	const handleDialogSave = () => {
		load();
		setOpenDialog(false);
	};
	//
	// ################################################################################
	//
	const handleDialogClose = () => {
		setOpenDialog(false);
		setItemCrud({})
	};
	//
	// ################################################################################
	// let table = data.data.table

	return (
		<>
			<Paper
				sx={tableStyles}
				elevation={2}
			>
				<Box
					sx={{
						margin: "auto",
						width: "90%",
					}}
				>
					<Box sx={{ m: 1, p: 1 }}>
						<Grid container spacing={1}>
							<Grid item xs={12}>
								<h2>{nameItemsText}</h2>
							</Grid>
							<Grid item xs={12} md={6} lg={3}>
								<Autocomplete
									id="provincia"
									size="small"
									value={valueAutoCompleteProvincias}
									onChange={(event, newValue) => {
										setValueAutoCompleteProvincias(newValue);
										setSearchData({
											...searchData,
											idProvincia: newValue?.id,
										});
										loadDepartamentos(newValue)
										//loadLocalidades(newValue)
									}}
									inputValue={inputValueAutoCompleteProvincias}
									onInputChange={(event, newInputValue) => {
										setInputValueAutoCompleteProvincias(newInputValue);
									}}
									options={provincias}
									renderInput={(params) => (
										<TextField {...params} label="Provincia" />
									)}
									isOptionEqualToValue={(option, value) =>
										option.value === value.value
									}
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={3}>
								<Autocomplete
									id="departamento"
									size="small"
									disabled={!valueAutoCompleteProvincias?.id}
									value={valueAutoCompleteDepartamentos}
									onChange={(event, newValue) => {
										setValueAutoCompleteDepartamentos(newValue);
										setSearchData({
											...searchData,
											idDepartamento: newValue?.id,
										});
										loadLocalidades(newValue)
									}}
									inputValue={inputValueAutoCompleteDepartamentos}
									onInputChange={(event, newInputValue) => {
										setInputValueAutoCompleteDepartamentos(newInputValue);
									}}
									options={departamentos}
									renderInput={(params) => (
										<TextField {...params} label="Departamento" />
									)}
									isOptionEqualToValue={(option, value) =>
										option.value === value.value
									}
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={3}>
								<Autocomplete
									id="localidad"
									size="small"
									disabled={!valueAutoCompleteProvincias?.id}
									value={valueAutoCompleteLocalidades}
									onChange={(event, newValue) => {
										setValueAutoCompleteLocalidades(newValue);
										setSearchData({
											...searchData,
											id: newValue?.id,
											descripcion: newValue?.label
										});

									}}
									inputValue={inputValueAutoCompleteLocalidades}
									onInputChange={(event, newInputValue) => {
										setInputValueAutoCompleteLocalidades(newInputValue);
									}}
									options={localidades}
									renderInput={(params) => (
										<TextField {...params} label="Localidad" />
									)}
									isOptionEqualToValue={(option, value) =>
										option.value === value.value
									}
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={3}>
								<FormControl sx={{ width: "100%" }}>
									<InputLabel id="estado-select-label">Estado</InputLabel>
									<Select
										sx={{ width: "100%", mr: 1 }}
										labelId="estado"
										label="Estado"
										id="estado"
										name="estado"
										value={searchData.estado}
										size="small"
										onChange={handleChange}
									>
										{estadoOptions.map((item) => (
											<MenuItem key={item.id} value={item.id}>
												{item.descripcion}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Box>

					<Box sx={{ m: 1, p: 1 }}>
						<Grid container spacing={1}>
							<Grid item xs={12} md={3} lg={2}>
								<Button
									sx={{ width: "100%" }}
									variant="contained"
									onClick={() => handleClickActionCrud({}, "create")}
									startIcon={<AddIcon />}
								>
									Nuevo
								</Button>
							</Grid>
							<Grid item xs={12} md={3} lg={2}>
								<Button
									sx={{ width: "100%", mr: 1 }}
									variant="contained"
									onClick={search}
									startIcon={<SearchIcon />}
								>
									Buscar
								</Button>
							</Grid>
							<Grid item xs={12} md={3} lg={2}>
								<Button
									sx={{ width: "100%", mr: 1 }}
									variant="contained"
									onClick={clean}
									startIcon={<CleaningServicesIcon />}
								>
									Limpiar
								</Button>
							</Grid>
						</Grid>
					</Box>

          <Box sx={{ m: 1, p: 1 }}>
          </Box>
            {isDoingSomething && <LinearProgress />} 
					<ThemeProvider theme={darkTheme}>
						<MUIDataTable columns={tableColumns} data={itemsCrud} options={tableOptions} />
					</ThemeProvider>
				</Box>
			</Paper>

			{/* >>>> Dialog */}

			{openDialog && (
				<LocalidadDialog
					provincias={provincias}
					departamentos={departamentos}
					onSave={handleDialogSave}
					onClose={handleDialogClose}
					itemCrud={itemCrud}
					actionCrud={actionCrud}
					setMessage={setMessage}
					setShowMessage={setShowMessage}
				/>
			)}

			{/* >>>> Messages */}

			{showMessage && (
				<Message
					showMessage={showMessage}
					setShowMessage={setShowMessage}
					message={message}
				/>
			)}
		</>
	);
};

export default LocalidadTable;
