import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import FAQ from "./FAQ";
import Hero from "./hero";
import Carrusel from "./carrusel";
import Cards from "./cards";



const Item = styled(Paper)(({ theme }) => ({
    backgroundcolor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


export default function Dashboard(data) {
    // let table = data.data.table
    return (
        <Grid component="main" container spacing={2} justifyContent="center" sx={{ flexGrow: 1,
            width: '93%',
            position: 'relative',
            top: '70px',
            left: '85px',
        }}>
                <Grid item xs={12} sm={6} md={9} component="section">
                    <Hero />
                </Grid>
                <Grid item xs={12} sm={6} md={3} component="section">
                    <Carrusel />
                </Grid>
                <Grid item xs={12} sm={6} md={4} component="section">
                    <Cards />
                </Grid>
                <Grid item xs={12} sm={6} md={8} component="section">
                   <FAQ />
                </Grid>
        </Grid>
    );
}
