export const isValidDate = (date) => {
  return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
}

export const isValidEmail = (email) => {
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

	const isValid = emailRegex.test(email);

	return isValid
}

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

export const formatDate = (date = new Date(), format = "YMD") => {
  
  format = format.toUpperCase();
	let _dateFormatted = "";
	switch (format) {
		case "YMD":
			_dateFormatted = [
				date.getFullYear(),
				padTo2Digits(date.getUTCMonth() + 1),
				padTo2Digits(date.getUTCDate()),
			].join("/");
			break;

      case "YMD-":
        _dateFormatted = [
          date.getFullYear(),
          padTo2Digits(date.getUTCMonth() + 1),
          padTo2Digits(date.getUTCDate()),
        ].join("-");
        break;
  
      case "DMY":
			_dateFormatted = [
				padTo2Digits(date.getUTCDate()),
				padTo2Digits(date.getUTCMonth() + 1),
				date.getFullYear(),
			].join("/");
			break;

		case "DMY HM":
			_dateFormatted = [
				padTo2Digits(date.getUTCDate()),
				padTo2Digits(date.getUTCMonth() + 1),
				date.getFullYear(),
			].join("/") + " " + date.getUTCHours().toString().padStart(2, "0") + ":" + date.getUTCMinutes().toString().padStart(2, "0");
			break;

		default:
	}
	return _dateFormatted;
};