import { format } from "date-fns";
import { isValidDate } from "../../utils/utils";
import {
	Box,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	LinearProgress,
	TextField,
	Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useEffect, useState } from "react";
import { saveFeriado } from "../../services/FeriadoService";
import darkTheme from "../../layout/DarkTheme";
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
const initialState = {
	descripcion: "",
	fecha: null,
};


const itemGender = "M";
const nameItemText = "Feriado";

//
// #####################################################################################################################
//
const FeriadoDialog = ({
	onSave,
	onClose,
	itemCrud,
	actionCrud,
	setMessage,
	setShowMessage,
}) => {
	const [itemCrudLocal, setItemCrudLocal] = useState(initialState);
	const [isDoingSomething, setIsDoingSomething] = useState(false);

	useEffect(() => {
		if (actionCrud === "create") {
			clean();
			setItemCrudLocal(initialState);
		} else {
			setItemCrudLocal(itemCrud);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function handleCloseDialog() {
		clean();
		onClose();
	}

	const handleChange = (e) => {
		setItemCrudLocal({
			...itemCrudLocal,
			[e.target.name]: e.target.value,
		});
	};

	const handleChangeFecha = (fecha) => {
		setItemCrudLocal({
			...itemCrudLocal,
			fecha: fecha,
		});
	};

	const clean = () => {
		itemCrudLocal.descripcion = '';
		};

	const handleSave = () => {
		if (actionCrud === "create" || actionCrud === "update") {

			if (
				itemCrudLocal.descripcion.trim() === "") {
				setMessage({
					severity: "error",
					messageText:
						"Debe agregar una descripción",
				});
				setShowMessage(true);
				return;
			}

			if (!isValidDate(itemCrudLocal.fecha)) {
				setMessage({
					severity: "error",
					messageText: "La fecha ingresada no es válida",
				});
				setShowMessage(true);
				return;
			}
		}
		save();
	};

	const save = async () => {
		let _itemCrud = {
			fecha: itemCrudLocal.fecha
				? format(new Date(itemCrudLocal.fecha), "yyyy/MM/dd")
				: null,
			descripcion: itemCrudLocal.descripcion,
			usernameUsuarioActualiza: localStorage.getItem("emailUsuario"),
		};
		if (actionCrud !== "create") {
			_itemCrud.id = itemCrudLocal.id;
		}
		setIsDoingSomething(true);
		const response = await saveFeriado(actionCrud, _itemCrud);
		setIsDoingSomething(false);

		if (response.ok) {
			onSave();
		}

		setMessage({
			messageText: response.messageText,
			severity: response.messageSeverity,
		});
		setShowMessage(true);
	};

	return (
		<>
				<LocalizationProvider dateAdapter={AdapterDateFns}>
			<Dialog
				open={true}
				onClose={handleCloseDialog}
				maxWidth="md"
				fullWidth={true}
				scroll="paper"
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title"
					textAlign={{ xs: "center", sm: "center" }}			
					style={{ background: darkTheme.palette.primary.dark, color: darkTheme.palette.primary.text}}
				>
					{actionCrud === "create"
						? itemGender === "M"
							? "Nuevo"
							: "Nueva"
						: actionCrud === "update"
							? "Editando"
							: actionCrud === "enable"
								? "Habilitando"
								: actionCrud === "read"
								? "Detalle"
								: "Deshabilitando"}{" "}
					{nameItemText}
					<IconButton
						aria-label="close"
						onClick={handleCloseDialog}
						sx={{
							position: "absolute",
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<CloseIcon />
					</IconButton>
				</DialogTitle>

				{isDoingSomething && <LinearProgress />}

				<DialogContent dividers>
					<Box sx={{ m: 1, p: 1 }}>

						<Box sx={{ pb: 1 }}>
	
							<TextField
								variant="outlined"
								size="small"
								name="descripcion"
								label="Descripción"
								value={itemCrudLocal.descripcion}
								onChange={handleChange}
								disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
								sx={{ width: "100%", pb: 1 }}
								inputProps={{ maxLength: 80 }}
							/>

							<DatePicker
								id="fecha"
								label="Fecha"
								variant="outlined"
								name="fecha"
								format="dd/MM/yyyy"
								value={itemCrudLocal.fecha}
								onChange={(fecha) =>
									handleChangeFecha(fecha)
								}
								renderInput={(params) => <TextField size="small" {...params} />}
								inputFormat="dd/MM/yyyy"
								disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
							/>
						</Box>

						{actionCrud !== "create" && (
							<Box
								sx={{
									p: 1,
									mt: 1,
									color: "SlateGrey",
									border: "0px solid DarkGray",
									borderRadius: "3px",
								}}
							>
								<Typography variant="h8" component="div" gutterBottom>
									Última actualización: {itemCrudLocal.fechaActualizacion}
								</Typography>

								<Typography variant="h8" component="div" gutterBottom>
									Último usuario que actualizó: {itemCrudLocal.nombreUsuarioActualiza}{" "}
									{itemCrudLocal.apellidoUsuarioActualiza} ({itemCrudLocal.usernameUsuarioActualiza})
								</Typography>
							</Box>
						)}
					</Box>

					<Box sx={{ textAlign: "right" }}>
						<Button
							sx={{ mr: 1 }}
							variant="outlined"
							onClick={handleCloseDialog}
							startIcon={<CloseIcon />}
						>									
							Cancelar
						</Button>

						{(actionCrud !== "read") && (	
							<Button
							sx={{ mr: 1, backgroundcolor: "warning" }}
							variant="contained"
							onClick={handleSave}
							startIcon={
								actionCrud === "disable" ? (
									<RemoveCircleOutlineIcon />
								) : actionCrud === "enable" ? (
									<AddCircleOutlineIcon />
								) : (
									<SaveIcon />
								)
							}
						>
							{actionCrud === "disable"
								? "Deshabilitar"
								: actionCrud === "enable"
									? "Habilitar"
									: "Guardar"}
							</Button>
						)}
					</Box>
				</DialogContent>
			</Dialog>
			</LocalizationProvider>
		</>
	);
};

export default FeriadoDialog;
