import { format } from "date-fns";
import {
	Button,
	FormControl,
	FormControlLabel,
  FormLabel,
	Grid,
	InputLabel,
	LinearProgress,
	MenuItem,
	Paper,
	Radio,
  RadioGroup,
	Select,
	ThemeProvider,
	TextField,
	Tooltip,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import DatePicker from "@mui/lab/DatePicker";
import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RemoveIcon from "@mui/icons-material/Remove";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import AddIcon from "@mui/icons-material/Add";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import { getUsuariosLicencias, getUsuarioLicencia, generarPDFUsuariosLicencias } from "../../services/UsuarioLicenciaService";
import { getUsuarios } from "../../services/UsuarioService";
import UsuarioLicenciaDialog from "./UsuarioLicenciaDialog";
import Message from "../../utils/Message";
import { Box } from "@mui/system";
import darkTheme from "../../layout/DarkTheme";
import React from 'react';
import { useMenu } from "../../context/MenuContext.js";


const initialState = {
	nombre_usuario: "",
	apellido_usuario: "",
	tipo_licencia: "",
	fechaDesde: null,
	fechaHasta: null,
	orden: "ASC",
  ordenarPor: "apellido_usuario",
	estado: "todo",
	rows: 0,
	first: 0,
};

const estadoOptions = [
	{
		id: "todo",
		descripcion: "Todo",
	},
	{
		id: "habilitado",
		descripcion: "Habilitado",
	},
	{
		id: "deshabilitado",
		descripcion: "Deshabilitado",
	},
];

const ordenOptions = [
  {
    id: "ASC",
    descripcion: "Ascendente",
  },
  {
    id: "DESC",
    descripcion: "Descendente",
  },
];

const ordenarPorOptions = [
	{
		id: "apellido_usuario",
		descripcion: "Apellido",
	},
	{
		id: "tipo_licencia",
		descripcion: "Tipo de Licencia",
	},
	{
		id: "fecha_desde",
		descripcion: "Desde",
	},
	{
		id: "fecha_hasta",
		descripcion: "Hasta",
	},
];

const nameItemsText = "Licencias";
//
// ##################################################################################################################### 
//
const UsuarioLicenciaTable = ({ ...data }) => {
	const [searchData, setSearchData] = useState(initialState);
	const [itemsCrud, setItemsCrud] = useState([]);
	const [itemCrud, setItemCrud] = useState({});
	const [actionCrud, setActionCrud] = useState("");
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [totalRowsCount, setTotalRowsCount] = useState(0);
	const [currentPage, setCurrentPage] = useState(0);
	const [openDialog, setOpenDialog] = useState(false);
	const [message, setMessage] = useState({});
	const [showMessage, setShowMessage] = useState(false);
	const [usuarios, setUsuarios] = useState([]);
	const [isDoingSomething, setIsDoingSomething] = useState(false);
	const { menuOpen } = useMenu();

  const tableStyles = {
    width: menuOpen ?  "80%": "95%", 
    height: "90%",
    overflow: "auto",
    p: 1,
    margin: "auto",
    position: 'fixed', 
    top: "8%", 
    left: menuOpen ? '15%' : '0%', 
    transition: 'left 0.3s ease' 
  };
	
	const tableColumns = [
		{
			name: "id",
			label: "ID",
			options: {
				display: "excluded",
			},
		},
		{
			name: "username_usuario",
			label: "Apellido y Nombre",
			options: {
				filter: false,
				sort: false,
				customBodyRenderLite: dataIndex => {
					let value = itemsCrud[dataIndex].apellido_usuario + ', ' + itemsCrud[dataIndex].nombre_usuario;
					return value
				},
			},
		},
		{
			name: "tipo_licencia",
			label: "Tipo de Licencia",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			name: "fecha_desde",
			label: "Desde",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			name: "fecha_hasta",
			label: "Hasta",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			name: "actions",
			label: "Acciones",
			options: {
				filter: false,
				sort: false,
				empty: true,
				customBodyRenderLite: (dataIndex, rowIndex) => (
					<>
						{itemsCrud[dataIndex].habilitado && (
							<Tooltip title="Editar">
								<IconButton
									size="small"
									aria-label="update"
									onClick={() => handleClickActionCrud(itemsCrud[dataIndex], "update")}
								>
									<EditOutlinedIcon />
								</IconButton>
							</Tooltip>
						)}

						{itemsCrud[dataIndex].habilitado && (
							<Tooltip title="Deshabilitar">
								<IconButton
									size="small"
									aria-label="disable"
									onClick={() => handleClickActionCrud(itemsCrud[dataIndex], "disable")}
								>
									<RemoveIcon />
								</IconButton>
							</Tooltip>
						)}

						{!itemsCrud[dataIndex].habilitado && (
							<Tooltip title="Habilitar">
								<IconButton
									size="small"
									aria-label="enable"
									onClick={() => handleClickActionCrud(itemsCrud[dataIndex], "enable")}
								>
									<AddIcon />
								</IconButton>
							</Tooltip>
						)}
            
            {(
              <Tooltip title="Ver">
                <IconButton
                  size="small"
                  aria-label="read"
                  onClick={() => handleClickActionCrud(itemsCrud[dataIndex], "read")}
                >
                  <ZoomInIcon />
                </IconButton>
              </Tooltip>
            )}
					</>
				),
			},
		},
	];

	const tableOptions = {
		storageKey: "my-table-state",
		rowsPerPageOptions: [10, 20, 30],
		serverSide: true,
		count: totalRowsCount,
		rowsPerPage: rowsPerPage,
		onChangeRowsPerPage: (numberOfRows) => {
			setRowsPerPage(numberOfRows);
		},
		onChangePage: (currentPage) => {
			setCurrentPage(currentPage);
		},
		download: false,
		filter: false,
		print: false,
		search: false,
		viewColumns: false,
		selectableRowsHeader: false,
		selectableRows: "none",
		elevation: 1,
		textLabels: {
			body: {
				noMatch: "No se han encontrado resultados para esta búsqueda",
			},
			pagination: {
				next: "Próxima",
				previous: "Previa",
				rowsPerPage: "Filas:",
				displayRows: "de",
			},
		},
	};
	//
	// ################################################################################
	//
	const load = async () => {
		searchData.rows = rowsPerPage;
		searchData.first = currentPage * rowsPerPage;
		searchData.sortField = searchData.ordenarPor;
		searchData.sortOrder = searchData.orden; 
		setIsDoingSomething(true);
		const response = await getUsuariosLicencias(searchData);
		setIsDoingSomething(false);

		if (response.ok) {
			setItemsCrud(response.data.data);
			setTotalRowsCount(response.data.count);
		} else {
			setMessage({
				messageText: response.messageText,
				severity: response.messageSeverity,
			});
			setShowMessage(true);
		}
	};

	//
	// ################################################################################
	//
	useEffect(() => {
		const loadUsuarios = async () => {
			setIsDoingSomething(true);
			const response = await getUsuarios({ estado: "habilitado" });
			setIsDoingSomething(false);

			if (response.ok) {
				let _response = response.data.data;
				let _usuarios = [];

				_response.forEach((item) => {
					_usuarios.push({
						id: item.id,
						nombre: item.nombre,
						apellido: item.apellido,
						username: item.username,
						label: item.username,
					});
				});

				setUsuarios(_usuarios);
			} else {
				setUsuarios([]);
				setMessage({
					messageText: response.messageText,
					severity: response.messageSeverity,
				});
				setShowMessage(true);
			}
		};
		loadUsuarios();
	}, []);


	//
	// ################################################################################
	//

	useEffect(() => {
		load();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage, rowsPerPage]);

	const handleChange = (e) => {
		setSearchData({
			...searchData,
			[e.target.name]:
				e.target.type === "checkbox" ? e.target.checked : e.target.value,
		});
	};

	const handleChangeFechaDesde = (fechaDesde) => {
		setSearchData({
			...searchData,
			fechaDesde: fechaDesde,
		});
	};

	const handleChangeFechaHasta = (fechaHasta) => {
		setSearchData({
			...searchData,
			fechaHasta: fechaHasta,
		});
	};

	const search = () => {
		setCurrentPage(0);
		load();
	};

	const clean = () => {
		setSearchData(initialState);
		setItemsCrud([]);
	};

	const handleClickActionCrud = (itemCrud, action) => {
		let _itemCrud = {};

		if (action !== "create") {
			const loadItem = async () => {
				setIsDoingSomething(true);
				const response = await getUsuarioLicencia({ id: itemCrud.id });
				setIsDoingSomething(false);

				if (response.ok) {
					_itemCrud = {
						id: response.data.data.id,
						idUsuarioLicencia: response.data.data.id_usuario,
						nombreUsuarioLicencia: response.data.data.nombre_usuario,
						apellidoUsuarioLicencia: response.data.data.apellido_usuario,
						usernameUsuarioLicencia: response.data.data.username_usuario,
						tipoLicencia: response.data.data.tipo_licencia,
						descripcion: response.data.data.descripcion,
						fechaDesde: 
							response.data.data.fecha_desde !== null
							? format(
								new Date(response.data.data.fecha_desde), 
								"yyyy/MM/dd"
								)
							: null,
						fechaHasta: 
						response.data.data.fecha_hasta !== null
							? format(
								new Date(response.data.data.fecha_hasta), 
								"yyyy/MM/dd"
								)
								: null,
						origenDatos: response.data.data.origen_datos,
						usernameUsuarioActualiza: response.data.data.username_usuario_actualiza,
						nombreUsuarioActualiza: response.data.data.nombre_usuario_actualiza,
						apellidoUsuarioActualiza: response.data.data.apellido_usuario_actualiza,
						fechaActualizacion: response.data.data.fecha_actualizacion,
						habilitado: response.data.data.habilitado,
					};

					setActionCrud(action);
					setItemCrud(_itemCrud);
					setOpenDialog(true);
				} else {
					setMessage({
						severity: response.messageSeverity,
						messageText: response.messageText,
					});
					setShowMessage(true);
					return;
				}
			};
			loadItem();
		} else {
			setActionCrud(action);
			setItemCrud(_itemCrud);
			setOpenDialog(true);
		}
	};

	const handleDialogSave = () => {
		load();
		setOpenDialog(false);
	};

	const handleDialogClose = () => {
		setOpenDialog(false);
	};

	//
	// ################################################################################
	//

	const handleGenerarPDF = () => {
		generarPDF();
	};

	//
	// ################################################################################
	//
	
	const generarPDF = async () => {
		let _params = {
			
			nombreUsuarioLicencia: searchData.nombreUsuarioLicencia,
			apellidoUsuarioLicencia: searchData.apellidoUsuarioLicencia,
			fechaDesde: searchData.fechaDesde,
			fechaHasta: searchData.fechaHasta,
			tipoLicencia: searchData.tipoLicencia,
			sortField: searchData.ordenarPor,
			sortOrder: searchData.orden,
			estado: searchData.estado,
		};

		setIsDoingSomething(true);
		const response = await generarPDFUsuariosLicencias(_params);

		setIsDoingSomething(false);
		if (!response.ok) {
			setMessage({
				messageText: response.messageText,
				severity: response.messageSeverity,
			});
			setShowMessage(true);
		}
	};

	//
	// ################################################################################
	//

	// let table = data.data.table

	return (
		<>
			<Paper
				sx={tableStyles}
				elevation={2}
			>
				<Box
					sx={{
						margin: "auto",
						width: "90%",
					}}
				>
					<Box sx={{ m: 1, p: 1 }}>
						<Grid container spacing={1}>
							<Grid item xs={12}>
								<h2>{nameItemsText}</h2>
							</Grid>
							<Grid item xs={12} md={6} lg={2}>
								<TextField
									sx={{ width: "100%", mr: 1 }}
									id="apellidoUsuarioLicencia"
									name="apellidoUsuarioLicencia"
									value={searchData.apellidoUsuarioLicencia}
									onChange={handleChange}
									autoFocus={true}
									label="Apellido"
									variant="outlined"
									size="small"
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={2}>
								<TextField
									sx={{ width: "100%", mr: 1 }}
									id="nombreUsuarioLicencia"
									name="nombreUsuarioLicencia"
									value={searchData.nombreUsuarioLicencia}
									onChange={handleChange}
									label="Nombre"
									variant="outlined"
									size="small"
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={2}>
								<TextField
									sx={{ width: "100%", mr: 1 }}
									id="tipoLicencia"
									name="tipoLicencia"
									value={searchData.tipoLicencia}
									onChange={handleChange}
									label="Tipo de Licencia"
									variant="outlined"
									size="small"
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={2}>
								<DatePicker
									id="fechaDesde"
									label="Desde"
									variant="outlined"
									name="fechaDesde"
									value={searchData.fechaDesde}
									onChange={(fechaDesde) =>
										handleChangeFechaDesde(fechaDesde)
									}
									renderInput={(params) => <TextField size="small" {...params} />}
									inputFormat="dd/MM/yyyy"
								//disabled={actionCrud === "disable" || actionCrud === "enable"}
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={2}>
								<DatePicker
									id="fechaHasta"
									label="Hasta"
									variant="outlined"
									name="fechaHasta"
									value={searchData.fechaHasta}
									onChange={(fechaHasta) =>
										handleChangeFechaHasta(fechaHasta)
									}
									renderInput={(params) => <TextField size="small" {...params} />}
									inputFormat="dd/MM/yyyy"
								//disabled={actionCrud === "disable" || actionCrud === "enable"}
								/>
							</Grid>
		
							<Grid item xs={12} md={6} lg={2}>
								<FormControl sx={{ width: "100%" }}>
									<InputLabel id="estado-select-label">Estado</InputLabel>
									<Select
										sx={{ width: "100%", mr: 1 }}
										labelId="estado"
										label="Estado"
										id="estado"
										name="estado"
										value={searchData?.estado || ""}
										size="small"
										onChange={handleChange}
									>
										{estadoOptions.map((item) => (
											<MenuItem key={item.id} value={item.id}>
												{item.descripcion}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>

						<Grid container spacing={1}>
						</Grid>
					</Box>
					
          <Box sx={{ m: 1, p: 1 }}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={3}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="ordenarPor-select-label">
                    Ordenar por:
                  </InputLabel>
                  <Select
                    sx={{ width: "100%", mr: 1 }}
                    labelId="ordenarPor"
                    label="Ordenar por:"
                    id="ordenaPor"
                    name="ordenarPor"
                    value={searchData.ordenarPor}
                    size="small"
                    onChange={handleChange}
                  >
                    {ordenarPorOptions.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.descripcion}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
							<Grid item xs={12} md={0.5}>
              </Grid>
              <Grid item xs={12} md={4}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <FormControl component="fieldset">
                      <FormLabel
                        component="legend"
                        sx={{
                          textAlign: "center",
                          mb: 1,
                          fontSize: "0.875rem",
                        }}
                      >
                        Orden
                      </FormLabel>
                      <RadioGroup
                        aria-label="Orden"
                        name="orden"
                        value={searchData.orden}
                        onChange={handleChange}
                        row
                      >
                        {ordenOptions
                          .slice(0, Math.ceil(ordenOptions.length / 2))
                          .map((item) => (
                            <FormControlLabel
                              key={item.id}
                              value={item.id}
                              label={item.descripcion}
                              control={<Radio />}
                            />
                          ))}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl component="fieldset">
                      <FormLabel
                        component="legend"
                        sx={{
                          textAlign: "center",
                          mb: 1,
                          fontSize: "0.875rem",
                        }}
                      >
                        &nbsp;
                      </FormLabel>
                      <RadioGroup
                        aria-label="Orden"
                        name="orden"
                        value={searchData.orden}
                        onChange={handleChange}
                        row
                      >
                        {ordenOptions
                          .slice(Math.ceil(ordenOptions.length / 2))
                          .map((item) => (
                            <FormControlLabel
                              key={item.id}
                              value={item.id}
                              label={item.descripcion}
                              control={<Radio />}
                            />
                          ))}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
					<Box sx={{ m: 1, p: 1 }}>
						<Grid container spacing={1}>
							<Grid item xs={12} md={3} lg={2}>
								<Button
									sx={{ width: "100%" }}
									variant="contained"
									onClick={() => handleClickActionCrud({}, "create")}
									startIcon={<AddIcon />}
								>
									Nuevo
								</Button>
							</Grid>
							<Grid item xs={12} md={3} lg={2}>
								<Button
									sx={{ width: "100%" }}
									variant="contained"
									onClick={search}
									startIcon={<SearchIcon />}
								>
									Buscar
								</Button>
							</Grid>
							<Grid item xs={12} md={3} lg={2}>
								<Button
									sx={{ width: "100%", mr: 1 }}
									variant="contained"
									onClick={clean}
									startIcon={<CleaningServicesIcon />}
								>
									Limpiar
								</Button>
							</Grid>
							<Grid item xs={12} md={3} lg={2} >
							</Grid>
							<Grid item xs={12} md={3} lg={2} >
							</Grid>							
							<Grid item xs={12} md={3} lg={2} >
								<Button
									sx={{ width: "100%" }}
									variant="contained"
									onClick={handleGenerarPDF}
									startIcon={<PictureAsPdfIcon />}
								>
									Generar PDF
								</Button>
							</Grid>
						</Grid>
					</Box>

          <Box sx={{ m: 1, p: 1 }}>
          </Box>
            {isDoingSomething && <LinearProgress />} 
					<ThemeProvider theme={darkTheme}>
						<MUIDataTable columns={tableColumns} data={itemsCrud} options={tableOptions} />
					</ThemeProvider>
				</Box>
			</Paper>

			{/* >>>> Dialog */}

			{openDialog && (
				<UsuarioLicenciaDialog
					usuarios={usuarios}
					onSave={handleDialogSave}
					onClose={handleDialogClose}
					itemCrud={itemCrud}
					actionCrud={actionCrud}
					setMessage={setMessage}
					setShowMessage={setShowMessage}
				/>
			)}

			{/* >>>> Messages */}

			{showMessage && (
				<Message
					showMessage={showMessage}
					setShowMessage={setShowMessage}
					message={message}
				/>
			)}
		</>
	);
};

export default UsuarioLicenciaTable;
