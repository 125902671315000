import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

export default function Hero() {
    return (
        <>
            <Box sx={{ display: { xs: 'block', sm: 'block', md: 'block' }, flex: 1, alignItems: { md: 'center', lg: 'baseline' } }}>
                <Typography variant="h1" component="h2"
                    sx={{ flex: 0, color: '#15BF81', fontWeight: 'light', mb: 1, mr: 1 }}
                >
                    Usher
                </Typography>
                <Typography variant="h3" component="div"
                    sx={{ display: { sm: 'none', md: 'flex', lg: 'flex' }, flex: 1, color: 'rgba(255, 255, 255, 0.5)', fontWeight: '400', mb: 1, fontStyle: 'oblique' }}
                >
                    Microservicio de Sistemas y Roles
                </Typography>
                <Box>
                    <Box sx={{ display: 'flex', flex: 1, alignItems: 'center', width: '90%' }}>
                        <Typography component="p" variant="h5"
                            sx={{ flex: 1, color: 'rgba(255, 255, 255, 0.5)', fontWeight: 'light', mb: 1 }}>Este sistema permite habilitar Sistemas, Oficinas y Personas. Relacionarlos entre sí, asignar roles, habilitar y deshabilitar tareas para cada persona, cargar sus licencias, y darles de baja cuando sea necesario. En función a estos datos, dichos agentes podrán ingresar y acceder a la informacion disponible o realizar la tarea indicada si tiene permiso para ello.</Typography>
                    </Box>
                    <Box sx={{ flex: 1, color: '#15BF81', fontWeight: 'light', mt: 2 }}>
                        <Button sx={{ mr: 2 }} href='/sistema' variant="contained">Sistemas</Button>
                        <Button href='/funcion' >Funciones</Button>
                    </Box>

                </Box>
            </Box>


        </>
    );
}
