import {
	Autocomplete,
	Button,
	FormControl,
	FormControlLabel,
  FormLabel,
	Grid,
	InputLabel,
	LinearProgress,
	MenuItem,
	Paper,
	Radio,
  RadioGroup,
	Select,
	TextField,
	ThemeProvider,
	Tooltip,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import RemoveIcon from "@mui/icons-material/Remove";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useEffect, useState } from "react";
import Message from "../../utils/Message";
import { Box } from "@mui/system";
import { getUsuarioOrganismoAreaFuncion, getUsuariosOrganismosAreasFunciones, generarPDFUsuariosOrganismosAreasFunciones } from "../../services/UsuarioOrganismoAreaFuncionService";
import { getFueros } from "../../services/FueroService";
import darkTheme from "../../layout/DarkTheme";
import { getOrganismosAreas } from "../../services/OrganismoAreaService";
import { getFunciones } from "../../services/FuncionService"
import UsuarioOrganismoAreaFuncionDialog from "./UsuarioOrganismoAreaFuncionDialog";
import { getUsuarios } from "../../services/UsuarioService";
import React from 'react';
import { useMenu } from "../../context/MenuContext.js";

const initialSearchData = {
	descripcion: "",
	circunscripcionJudicial: "todo",
	estado: "habilitado",
	orden: "ASC",
  ordenarPor: "apellido_usuario",
};

const circunscripcionJudicialOptions = [
	{
		id: "todo",
		descripcion: "Todas",
	},
	{
		id: "1",
		descripcion: "Primera",
	},
	{
		id: "2",
		descripcion: "Segunda",
	},
	{
		id: "3",
		descripcion: "Tercera",
	},
	{
		id: "4",
		descripcion: "Cuarta",
	}
];

const estadoOptions = [
	{
		id: "todo",
		descripcion: "Todo",
	},
	{
		id: "habilitado",
		descripcion: "Habilitado",
	},
	{
		id: "deshabilitado",
		descripcion: "Deshabilitado",
	},
];

const ordenOptions = [
  {
    id: "ASC",
    descripcion: "Ascendente",
  },
  {
    id: "DESC",
    descripcion: "Descendente",
  },
];

const ordenarPorOptions = [
	{
		id: "apellido_usuario",
		descripcion: "Usuario",
	},
	{
		id: "descripcion_fuero",
		descripcion: "Fuero",
	},
	{
		id: "circunscripcion_judicial_organismo",
		descripcion: "Circunscripción Judicial",
	},
	{
		id: "descripcion_organismo",
		descripcion: "Organismo",
	},
	{
		id: "descripcion_funcion",
		descripcion: "Función",
	},
];

const nameItemsText = "Asignación de Usuario a un Organismo-Área y Función";
//
// ##################################################################################################################### 
//
const UsuarioOrganismoAreaFuncionTable = (data) => {
	const [searchData, setSearchData] = useState(initialSearchData);
	const [itemsCrud, setItemsCrud] = useState([]);
	const [itemCrud, setItemCrud] = useState({});
	const [actionCrud, setActionCrud] = useState("");
	const [isDoingSomething, setIsDoingSomething] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const [totalRowsCount, setTotalRowsCount] = useState(0);
	const [currentPage, setCurrentPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [message, setMessage] = useState({});
	const [showMessage, setShowMessage] = useState(false);
	const [organismosAreas, setOrganismosAreas] = useState([]);
	const [funciones, setFunciones] = useState([]);
	const [usuarios, setUsuarios] = useState([]);
	const [valueAutoCompleteOrganismosAreas, setValueAutoCompleteOrganismosAreas] = useState({
		id: "",
		label: "",
	});
	const [inputValueAutoCompleteOrganismosAreas, setInputValueAutoCompleteOrganismosAreas] =
		useState("");
	const [valueAutoCompleteFunciones, setValueAutoCompleteFunciones] = useState({
		id: "",
		label: "",
	});
	const [inputValueAutoCompleteFunciones, setInputValueAutoCompleteFunciones] =
		useState("");

	const [valueAutoCompleteUsuarios, setValueAutoCompleteUsuarios] = useState({
		id: "",
		label: "",
		idSistemaRol: ""
	});
	const [inputValueAutoCompleteUsuarios, setInputValueAutoCompleteUsuarios] =
		useState("");

	const [fueros, setFueros] = useState([]);
	const [valueAutoCompleteFuero, setValueAutoCompleteFuero] = useState({
		id: "",
		label: "",
	});
	const [inputValueAutoCompleteFuero, setInputValueAutoCompleteFuero] = useState("");
  const { menuOpen } = useMenu();

  const tableStyles = {
    width: menuOpen ?  "80%": "95%", 
    height: "90%",
    overflow: "auto",
    p: 1,
    margin: "auto",
    position: 'fixed', 
    top: "8%", 
    left: menuOpen ? '15%' : '0%', 
    transition: 'left 0.3s ease' 
  };

	const circunscripcionMap = circunscripcionJudicialOptions.reduce((map, option) => {
		map[option.id] = option.descripcion;
		return map;
	}, {});

	const tableColumns = [
		{
			name: "id",
			label: "ID",
			options: {
				display: "excluded",
			},
		},
		{
			name: "username_usuario",
			label: "Usuario",
			options: {
				filter: false,
				sort: false,
				customBodyRenderLite: dataIndex => {
					let value = itemsCrud[dataIndex].apellido_usuario + ', ' + itemsCrud[dataIndex].nombre_usuario;
					return value
				},
			},
		},
		{
			name: "descripcion_fuero",
			label: "Fuero",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			name: 'circunscripcion_judicial_organismo',
			label: 'Circunscripción Judicial',
			options: {
				customBodyRender: (value) => circunscripcionMap[value] || value,
			},
		},
		{
			name: "descripcion_organismo",
			label: "Organismo-Área",
			options: {
				filter: false,
				sort: false,
				customBodyRenderLite: dataIndex => {
					let value = itemsCrud[dataIndex].descripcion_organismo + ' - ' + itemsCrud[dataIndex].descripcion_area;
					return value
				},
			},
		},
		{
			name: "descripcion_funcion",
			label: "Función",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			name: "actions",
			label: "Acciones",
			options: {
				filter: false,
				sort: false,
				empty: true,
				customBodyRenderLite: (dataIndex, rowIndex) => (
					<>
						{itemsCrud[dataIndex].habilitado && (
							<Tooltip title="Editar">
								<IconButton
									size="small"
									aria-label="update"
									onClick={() => handleClickActionCrud(itemsCrud[dataIndex], "update")}
								>
									<EditOutlinedIcon />
								</IconButton>
							</Tooltip>
						)}

						{itemsCrud[dataIndex].habilitado && (
							<Tooltip title="Deshabilitar">
								<IconButton
									size="small"
									aria-label="disable"
									onClick={() =>
										handleClickActionCrud(itemsCrud[dataIndex], "disable")
									}
								>
									<RemoveIcon />
								</IconButton>
							</Tooltip>
						)}

						{!itemsCrud[dataIndex].habilitado && (
							<Tooltip title="Habilitar">
								<IconButton
									size="small"
									aria-label="enable"
									onClick={() =>
										handleClickActionCrud(itemsCrud[dataIndex], "enable")
									}
								>
									<AddIcon />
								</IconButton>
							</Tooltip>
						)}
            
            {(
              <Tooltip title="Ver">
                <IconButton
                  size="small"
                  aria-label="read"
                  onClick={() => handleClickActionCrud(itemsCrud[dataIndex], "read")}
                >
                  <ZoomInIcon />
                </IconButton>
              </Tooltip>
            )}
					</>
				),
			},
		},
	];

	const tableOptions = {
		storageKey: "my-table-state",
		rowsPerPageOptions: [10, 20, 30],
		serverSide: true,
		count: totalRowsCount,
		rowsPerPage: rowsPerPage,
		onChangeRowsPerPage: (numberOfRows) => {
			setRowsPerPage(numberOfRows);
		},
		onChangePage: (currentPage) => {
			setCurrentPage(currentPage);
		},
		download: false,
		filter: false,
		print: false,
		search: false,
		viewColumns: false,
		selectableRowsHeader: false,
		selectableRows: "none",
		elevation: 5,
		textLabels: {
			body: {
				noMatch: "No se encuentran resultados para esta búsqueda",
			},
			pagination: {
				next: "Próxima",
				previous: "Previa",
				rowsPerPage: "Filas:",
				displayRows: "de",
			},
		},
	};
	//
	// ################################################################################
	//


	const loadOrganismosAreas = async (newValue) => {
		let _params = {
			idFuero: newValue?.id || valueAutoCompleteFuero?.id,
			circunscripcionJudicial: newValue?.circunscripcionJudicial || searchData.circunscripcionJudicial,
			estado: "habilitado",

		};

		
		setIsDoingSomething(true);
		const response = await getOrganismosAreas(_params);
		setIsDoingSomething(false);

		if (response.ok) {
			let _response = response.data.data;
			let _organismosAreas = [];

			_response.forEach((item) => {
				_organismosAreas.push({
					id: item.id,
					id_fuero: item.id_fuero,
					descripcion_fuero: item.descripcion_fuero,
					id_organismo: item.id_organismo,
					id_area: item.id_area,
					label: item.descripcion_organismo + ' - ' + item.descripcion_area,
				});
			});

			setOrganismosAreas(_organismosAreas);
			setFunciones([])

		} else {
			setOrganismosAreas([]);
			setMessage({
				messageText: response.messageText,
				severity: response.messageSeverity,
			});
			setShowMessage(true);
		}
	};

		//
	// ################################################################################
	//
	useEffect(() => {
		const loadFueros = async () => {
			setIsDoingSomething(true);
			const response = await getFueros({ estado: "habilitado" });
			setIsDoingSomething(false);

			if (response.ok) {
				let _response = response.data.data;
				let _Fueros = [];

				_response.forEach((item) => {
					_Fueros.push({
						id: item.id,
						label: item.descripcion,
					});
				});

				setFueros(_Fueros);
			} else {
				setFueros([]);
				setMessage({
					messageText: response.messageText,
					severity: response.messageSeverity,
				});
				setShowMessage(true);
			}
		};
		loadFueros();
	}, []);


	//
	// ################################################################################
	//
	useEffect(() => {
		load();
	}, [currentPage, rowsPerPage]);
	//
	// ################################################################################
	//
	const handleChange = (e) => {
		setSearchData({
			...searchData,
			[e.target.name]:
				e.target.type === "checkbox" ? e.target.checked : e.target.value,
		});
	};

	const handleFueroChange = (event, newValue) => {
    setValueAutoCompleteFuero(newValue);
    setSearchData({
      ...searchData,
      idFuero: newValue?.id || '',
    });
    loadOrganismosAreas(newValue);
  };

  const handleCircunscripcionChange = (event) => {
    const newValue = event.target.value;
    setSearchData({
      ...searchData,
      circunscripcionJudicial: newValue,
    });
    loadOrganismosAreas({ circunscripcionJudicial: newValue });
  };

	//
	// ################################################################################
	//
	const handleLimpiar = () => {

		setValueAutoCompleteOrganismosAreas({
			id: "",
			label: "",
		});
		setInputValueAutoCompleteOrganismosAreas("");
		setValueAutoCompleteFunciones({
			id: "",
			label: "",
		});
		setInputValueAutoCompleteFunciones("");
		setValueAutoCompleteUsuarios({
			id: "",
			label: "",
		});
		setInputValueAutoCompleteUsuarios("");
		setSearchData(initialSearchData);
		setItemsCrud([]);
		load();
	};

	//
	// ################################################################################
	//

	const handleBuscar = () => {
		setCurrentPage(0);
		load();
	};
	//
	// ################################################################################
	//
	const load = async () => {
		let _params = {
			idOrganismosAreas: searchData?.id,
			idOrganismos: searchData?.idOrganismos,
			idAreas: searchData?.idAreas,
			idFuero: valueAutoCompleteFuero?.id,
			circunscripcionJudicial: searchData.circunscripcionJudicial,
			idFunciones: valueAutoCompleteFunciones?.id,
			idUsuario: valueAutoCompleteUsuarios?.id,
			estado: searchData.estado,
			sortOrder: searchData.orden,
      sortField: searchData.ordenarPor,
			rows: rowsPerPage,
			first: currentPage * rowsPerPage,
		};

		setIsDoingSomething(true);
		const response = await getUsuariosOrganismosAreasFunciones(_params);

		setIsDoingSomething(false);
		if (response.ok) {
			setTotalRowsCount(response.data.count);
			setItemsCrud(response.data.data);

		} else {
			setMessage({
				messageText: response.messageText,
				severity: response.messageSeverity,
			});
			setShowMessage(true);
		}
		loadFunciones();
		loadUsuarios();
	};
	//
	// ################################################################################  
	//
	const loadFunciones = async (newValue) => {
		let _params = {
			idOrganismosAreas: searchData?.idOrganismosAreas || valueAutoCompleteOrganismosAreas?.id,
			idFunciones: searchData?.idFunciones,
			};

		setIsDoingSomething(true);
		const response = await getFunciones(_params);
		setIsDoingSomething(false);
		if (response.ok) {
			let _response = response.data.data;
			let _funciones = [];
			_response.forEach((item) => {
				_funciones.push({
					id: item.id,
					label: item.descripcion,
				});
			});

			setFunciones(_funciones);
		} else {
			setFunciones([]);
			setMessage({
				messageText: response.messageText,
				severity: response.messageSeverity,
			});
			setShowMessage(true);
		}

	};
	//
	// ################################################################################
	//
	const loadUsuarios = async (newValue) => {
		let _params = {
			idOrganismosAreas: newValue?.id,
		};

		setIsDoingSomething(true);
		const response = await getUsuarios(_params);
		setIsDoingSomething(false);
		if (response.ok) {
			let _response = response.data.data;
			let _usuarios = [];
			_response.forEach((item) => {
				_usuarios.push({
					id: item.id,
					label: item.apellido + ' ' + item.nombre,
				});
			});

			setUsuarios(_usuarios);
		} else {
			setUsuarios([]);
			setMessage({
				messageText: response.messageText,
				severity: response.messageSeverity,
			});
			setShowMessage(true);
		}
	};
	//
	// ################################################################################
	//
	const handleClickActionCrud = (itemCrud, action) => {
		let _itemCrud = {};

		if (action !== "create") {
			const loadItem = async () => {
				setIsDoingSomething(true);
				
				const response = await getUsuarioOrganismoAreaFuncion({ id: itemCrud.id });
				setIsDoingSomething(false);
				
				if (response.ok) {
					_itemCrud = {
						id: response.data.data.id,
						idFuero: response.data.data.id_fuero,
						idOrganismo: response.data.data.id_organismo,
						idArea: response.data.data.id_area,
						idFuncion: response.data.data.id_funcion,
						descripcionArea: response.data.data.descripcion_area,
						descripcionFuncion: response.data.data.descripcion_funcion,
						descripcionOrganismo: response.data.data.descripcion_organismo,
						descripcion_fuero: response.data.data.descripcion_fuero,
						circunscripcionJudicialOrganismo: response.data.data.circunscripcion_judicial_organismo,
						observaciones: response.data.data.observaciones,
						idUsuario: response.data.data.id_usuario,
						fechaActualizacion: response.data.data.fecha_actualizacion,
						nombreUsuario: response.data.data.nombre_usuario,
						apellidoUsuario: response.data.data.apellido_usuario,
						username: response.data.data.username,
						nombreUsuarioActualiza: response.data.data.nombre_usuario_actualiza,
						apellidoUsuarioActualiza: response.data.data.apellido_usuario_actualiza,
						usernameUsuarioActualiza: response.data.data.username_usuario_actualiza,
					};

					setActionCrud(action);
					setItemCrud(_itemCrud);					
					setOpenDialog(true);
				} else {
					setMessage({
						severity: response.messageSeverity,
						messageText: response.messageText,
					});
					setShowMessage(true);
					return;
				}
			};
			loadItem();
		} else {
			setActionCrud(action);
			setItemCrud(_itemCrud);
			setOpenDialog(true);
		}
	};
	//
	// ################################################################################
	//
	const handleDialogSave = () => {
		load();
		setOpenDialog(false);
	};
	//
	// ################################################################################
	//
	const handleDialogClose = () => {
		setOpenDialog(false);
	};

	//
	// ################################################################################
	//

	const handleGenerarPDF = () => {
		generarPDF();
	};
	//
	// ################################################################################
	//
	const generarPDF = async () => {
		let _params = {
			idOrganismosAreas: searchData?.id,
			idOrganismos: searchData?.idOrganismos,
			idAreas: searchData?.idAreas,
			idFuero: valueAutoCompleteFuero?.id,
			idFunciones: valueAutoCompleteFunciones?.id,
			idUsuario: valueAutoCompleteUsuarios?.id,
			sortOrder: searchData.orden,
      sortField: searchData.ordenarPor,
			estado: searchData.estado,
		};

		setIsDoingSomething(true);
		const response = await generarPDFUsuariosOrganismosAreasFunciones(_params);

		setIsDoingSomething(false);
		if (!response.ok) {
			setMessage({
				messageText: response.messageText,
				severity: response.messageSeverity,
			});
			setShowMessage(true);
		}
	};

	//
	// ################################################################################
	// let table = data.data.table

	return (
		<>
			<Paper
				sx={tableStyles}
				elevation={2}
			>
				<Box
					sx={{
						margin: "auto",
						width: "90%",
					}}
				>
					<Box sx={{ m: 1, p: 1 }}>
						<Grid container spacing={1}>
						<Grid item xs={12}>
								<h2>{nameItemsText}</h2>
							</Grid>
							<Grid item xs={12} md={6} lg={2}>
								<Autocomplete
									id="usuario"
									size="small"
									value={valueAutoCompleteUsuarios}
									onChange={(event, newValue) => {
										setValueAutoCompleteUsuarios(newValue);
										setSearchData({
											...searchData,
											idOrganismosAreas: newValue?.id,
											idFunciones: newValue?.idFunciones,
										});
									}}
									inputValue={inputValueAutoCompleteUsuarios}
									onInputChange={(event, newInputValue) => {
										setInputValueAutoCompleteUsuarios(newInputValue);
									}}
									options={usuarios}
									renderInput={(params) => (
										<TextField {...params} label="Usuario" />
									)}
									isOptionEqualToValue={(option, value) =>
										option.value === value.value
									}
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={2}>
								<Autocomplete
									id="fuero"
									size="small"
									value={valueAutoCompleteFuero}
									onChange={handleFueroChange}
									inputValue={inputValueAutoCompleteFuero}
									onInputChange={(event, newInputValue) => {
										setInputValueAutoCompleteFuero(newInputValue);
									}}
									options={fueros}
									renderInput={(params) => (
										<TextField {...params} label="Fuero" />
									)}
									isOptionEqualToValue={(option, value) =>
										option.value === value.value
									}
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={2}>
								<FormControl sx={{ width: "100%" }}>
									<InputLabel id="circunscripcionJudicial-select-label">Circunscripción Judicial</InputLabel>
									<Select
										sx={{ width: "100%", mr: 1 }}
										labelId="circunscripcionJudicial"
										label="Circunscripción Judicial"
										id="circunscripcionJudicial"
										name="circunscripcionJudicial"
										value={searchData.circunscripcionJudicial}
										size="small"
										onChange={handleCircunscripcionChange}
									>
										{circunscripcionJudicialOptions.map((item) => (
											<MenuItem key={item.id} value={item.id}>
												{item.descripcion}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} md={6} lg={2}>
								<Autocomplete
									id="organismosAreas"
									size="small"
									value={valueAutoCompleteOrganismosAreas}
									onChange={(event, newValue) => {
										setValueAutoCompleteOrganismosAreas(newValue);
										setSearchData({
											...searchData,
											idOrganismosAreas: newValue?.id,
											idOrganismos: newValue?.id_organismo,
											idAreas: newValue?.id_area,
											idFunciones: newValue?.id_funciones

										});
									}}
									inputValue={inputValueAutoCompleteOrganismosAreas}
									onInputChange={(event, newInputValue) => {
										setInputValueAutoCompleteOrganismosAreas(newInputValue);
									}}
									options={organismosAreas}
									renderInput={(params) => (
										<TextField {...params} label="Organismo-Área" />
									)}
									isOptionEqualToValue={(option, value) =>
										option.value === value.value
									}
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={2}>
								<Autocomplete
									id="funciones"
									size="small"
									value={valueAutoCompleteFunciones}
									onChange={(event, newValue) => {
										setValueAutoCompleteFunciones(newValue);
										setSearchData({
											...searchData,
											idFunciones: newValue?.id,
										});
									}}
									inputValue={inputValueAutoCompleteFunciones}
									onInputChange={(event, newInputValue) => {
										setInputValueAutoCompleteFunciones(newInputValue);
									}}
									options={funciones}
									renderInput={(params) => (
										<TextField {...params} label="Función" />
									)}
									isOptionEqualToValue={(option, value) =>
										option.value === value.value
									}
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={2}>
								<FormControl sx={{ width: "100%" }}>
									<InputLabel id="estado-select-label">Estado</InputLabel>
									<Select
										sx={{ width: "100%", mr: 1 }}
										labelId="estado"
										label="Estado"
										id="estado"
										name="estado"
										value={searchData.estado}
										size="small"
										onChange={handleChange}
									>
										{estadoOptions.map((item) => (
											<MenuItem key={item.id} value={item.id}>
												{item.descripcion}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Box>
          <Box sx={{ m: 1, p: 1 }}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={3}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="ordenarPor-select-label">
                    Ordenar por:
                  </InputLabel>
                  <Select
                    sx={{ width: "100%", mr: 1 }}
                    labelId="ordenarPor"
                    label="Ordenar por:"
                    id="ordenaPor"
                    name="ordenarPor"
                    value={searchData.ordenarPor}
                    size="small"
                    onChange={handleChange}
                  >
                    {ordenarPorOptions.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.descripcion}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
							<Grid item xs={12} md={0.5}>
              </Grid>
              <Grid item xs={12} md={4}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <FormControl component="fieldset">
                      <FormLabel
                        component="legend"
                        sx={{
                          textAlign: "center",
                          mb: 1,
                          fontSize: "0.875rem",
                        }}
                      >
                        Orden
                      </FormLabel>
                      <RadioGroup
                        aria-label="Orden"
                        name="orden"
                        value={searchData.orden}
                        onChange={handleChange}
                        row
                      >
                        {ordenOptions
                          .slice(0, Math.ceil(ordenOptions.length / 2))
                          .map((item) => (
                            <FormControlLabel
                              key={item.id}
                              value={item.id}
                              label={item.descripcion}
                              control={<Radio />}
                            />
                          ))}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl component="fieldset">
                      <FormLabel
                        component="legend"
                        sx={{
                          textAlign: "center",
                          mb: 1,
                          fontSize: "0.875rem",
                        }}
                      >
                        &nbsp;
                      </FormLabel>
                      <RadioGroup
                        aria-label="Orden"
                        name="orden"
                        value={searchData.orden}
                        onChange={handleChange}
                        row
                      >
                        {ordenOptions
                          .slice(Math.ceil(ordenOptions.length / 2))
                          .map((item) => (
                            <FormControlLabel
                              key={item.id}
                              value={item.id}
                              label={item.descripcion}
                              control={<Radio />}
                            />
                          ))}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
					<Box sx={{ m: 1, p: 1 }}>
						<Grid container spacing={1}>
							<Grid item xs={12} md={3} lg={2}>
								<Button
									sx={{ width: "100%" }}
									variant="contained"
									onClick={() => handleClickActionCrud({}, "create")}
									startIcon={<AddIcon />}
								>
									Nuevo
								</Button>
							</Grid>
							<Grid item xs={12} md={3} lg={2}>
								<Button
									sx={{ width: "100%" }}
									variant="contained"
									onClick={handleBuscar}
									startIcon={<SearchIcon />}
								>
									Buscar
								</Button>
							</Grid>
							<Grid item xs={12} md={3} lg={2}>
								<Button
									sx={{ width: "100%", mr: 1 }}
									variant="contained"
									onClick={handleLimpiar}
									startIcon={<CleaningServicesIcon />}
								>
									Limpiar
								</Button>
							</Grid>
							<Grid item xs={12} md={3} lg={2} >
							</Grid>
							<Grid item xs={12} md={3} lg={2} >
							</Grid>							
							<Grid item xs={12} md={3} lg={2} >
								<Button
									sx={{ width: "100%" }}
									variant="contained"
									onClick={handleGenerarPDF}
									startIcon={<PictureAsPdfIcon />}
								>
									Generar PDF
								</Button>
							</Grid>
						</Grid>
					</Box>

          <Box sx={{ m: 1, p: 1 }}>
          </Box>
            {isDoingSomething && <LinearProgress />} 
					<ThemeProvider theme={darkTheme}>
						<MUIDataTable
							columns={tableColumns}
							data={itemsCrud}
							options={tableOptions}
						/>
					</ThemeProvider>
				</Box>
			</Paper>

			{/* >>>> Dialog */}

			{openDialog && (
				<UsuarioOrganismoAreaFuncionDialog
					fueros={fueros}
					organismosAreas={organismosAreas}
					funciones={funciones}
					usuarios={usuarios}
					onSave={handleDialogSave}
					onClose={handleDialogClose}
					itemCrud={itemCrud}
					actionCrud={actionCrud}
					setMessage={setMessage}
					setShowMessage={setShowMessage}
				/>
			)}

			{/* >>>> Messages */}

			{showMessage && (
				<Message
					showMessage={showMessage}
					setShowMessage={setShowMessage}
					message={message}
				/>
			)}
		</>
	);
};

export default UsuarioOrganismoAreaFuncionTable;
