import {
  Autocomplete,
	Button,
	FormControl,
	Grid,
	InputLabel,
	LinearProgress,
	MenuItem,
	Paper,
	Select,
	TextField,
	Tooltip,
  ThemeProvider,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import RemoveIcon from "@mui/icons-material/Remove";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import { useEffect, useState } from "react";
import { getUsuario } from "../../services/UsuarioService";
import { getProvincia, getProvincias } from "../../services/ProvinciaService";
import ProvinciaDialog from "./ProvinciaDialog";
import { getPaises } from "../../services/PaisService";
import Message from "../../utils/Message";
import { Box } from "@mui/system";
import darkTheme from "../../layout/DarkTheme";
import React from 'react';
import { useMenu } from "../../context/MenuContext.js";

const initialState = {
  descripcion: "",
  estado: "habilitado",
  rows: 0,
	first: 0,
};

const estadoOptions = [
	{
		id: "todo",
		descripcion: "Todo",
	},
	{
		id: "habilitado",
		descripcion: "Habilitado",
	},
	{
		id: "deshabilitado",
		descripcion: "Deshabilitado",
	},
];
const nameItemsText = "Provincias";
//
// ############################################################################# 
//
const ProvinciaTable = ({...data}) => {
  const [searchData, setSearchData] = useState(initialState);
  const [itemsCrud, setItemsCrud] = useState([]);
  const [itemCrud, setItemCrud] = useState({});
  const [actionCrud, setActionCrud] = useState("");
  const [isDoingSomething, setIsDoingSomething] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [totalRowsCount, setTotalRowsCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [message, setMessage] = useState({});
  const [showMessage, setShowMessage] = useState(false);
  const [paises, setPaises] = useState([]);
	const [valueAutoCompletePais, setValueAutoCompletePais] = useState({
		id: "",
		label: "",
	});
	const [inputValueAutoCompletePais, setInputValueAutoCompletePais] = useState("");
  const { menuOpen } = useMenu();

  const tableStyles = {
    width: menuOpen ?  "80%": "95%", 
    height: "90%",
    overflow: "auto",
    p: 1,
    margin: "auto",
    position: 'fixed', 
    top: "8%", 
    left: menuOpen ? '15%' : '0%', 
    transition: 'left 0.3s ease' 
  };

  const tableColumns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded",
      },
    },
		{
			name: "descripcion_pais",
			label: "País",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			name: "descripcion",
			label: "Descripción",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
      name: "descripcion_corta",
      label: "Descripción Corta",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "observaciones",
      label: "Observaciones",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "actions",
      label: "Acciones",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <>
            {itemsCrud[dataIndex].habilitado && (
              <Tooltip title="Editar">
                <IconButton
                  size="small"
                  aria-label="update"
                  onClick={() => handleClickActionCrud(itemsCrud[dataIndex], "update")}
                >
                  <EditOutlinedIcon />
                </IconButton>
              </Tooltip>
            )}

            {itemsCrud[dataIndex].habilitado && (
              <Tooltip title="Deshabilitar">
                <IconButton
                  size="small"
                  aria-label="disable"
                  onClick={() => handleClickActionCrud(itemsCrud[dataIndex], "disable")}
                >
                  <RemoveIcon />
                </IconButton>
              </Tooltip>
            )}

            {!itemsCrud[dataIndex].habilitado && (
              <Tooltip title="Habilitar">
                <IconButton
                  size="small"
                  aria-label="enable"
                  onClick={() => handleClickActionCrud(itemsCrud[dataIndex], "enable")}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            )}

						{(	
              <Tooltip title="Ver">
                <IconButton
                  size="small"
                  aria-label="read"
                  onClick={() => handleClickActionCrud(itemsCrud[dataIndex], "read")}
                >
                  <ZoomInIcon />
                </IconButton>
              </Tooltip>
            )}
          </>
        ),
      },
    },
  ];

  const tableOptions = {
    storageKey: "my-table-state",
    rowsPerPageOptions: [10, 20, 30],
    serverSide: true,
    count: totalRowsCount,
    rowsPerPage: rowsPerPage,
    onChangeRowsPerPage: (numberOfRows) => {
      setRowsPerPage(numberOfRows);
    },
    onChangePage: (currentPage) => {
      setCurrentPage(currentPage);
    },
    download: false,
    filter: false,
    print: false,
    search: false,
    viewColumns: false,
    selectableRowsHeader: false,
    selectableRows: "none",
    elevation: 1,
    textLabels: {
      body: {
        noMatch: "No se han encontrado resultados para esta búsqueda",
      },
      pagination: {
        next: "Próxima",
        previous: "Previa",
        rowsPerPage: "Filas:",
        displayRows: "de",
      },
    },
  };
  //
  // ################################################################################
  //
	const load = async () => {
		searchData.rows = rowsPerPage;
		searchData.first =  currentPage * rowsPerPage

		setIsDoingSomething(true);
		const response = await getProvincias(searchData);
		setIsDoingSomething(false);

		if (response.ok) {
			setItemsCrud(response.data.data);
			setTotalRowsCount(response.data.count);
		} else {
			setMessage({
				messageText: response.messageText,
				severity: response.messageSeverity,
			});
			setShowMessage(true);
		}
	};
  //
  // ################################################################################
  //

  useEffect(() => {
		const loadPaises = async () => {
			setIsDoingSomething(true);
			const response = await getPaises({ estado: "todo" });
			setIsDoingSomething(false);

			if (response.ok) {
				let _response = response.data.data;
				let _Paises = [];

				_response.forEach((item) => {
					_Paises.push({
						id: item.id,
						label: item.descripcion,
					});
				});

				setPaises(_Paises);
			} else {
				setPaises([]);
				setMessage({
					messageText: response.messageText,
					severity: response.messageSeverity,
				});
				setShowMessage(true);
			}
		};
		loadPaises();
	}, []);


	//
	// ################################################################################
	//

  useEffect(() => {
    load();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, rowsPerPage]);

  const handleChange = (e) => {
    setSearchData({
      ...searchData,
      [e.target.name]: e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const search = () => {
		setCurrentPage(0);
		load();
	};

  const clean = () => {
		setValueAutoCompletePais({ label: "", id: "" });
		setSearchData(initialState);
		setItemsCrud([]);
	};

  //
  // ################################################################################
  //
	const handleClickActionCrud = (itemCrud, action) => {
		let _itemCrud = {};

		if (action !== "create") {

      const loadItem = async () => {
				setIsDoingSomething(true);
				const response = await getProvincia({ id: itemCrud.id });
				setIsDoingSomething(false);

				if (response.ok) {
					_itemCrud = {
						id: response.data.data.id,
            pais: {
							id: response.data.data.id_pais,
							label: response.data.data.descripcion_pais,
						},
						descripcion: response.data.data.descripcion,
						descripcion_corta: response.data.data.descripcion_corta,
						observaciones: response.data.data.observaciones,
						fechaCreacion: response.data.data.fecha_creacion,
						fechaActualizacion: response.data.data.fecha_actualizacion,
            username: response.data.data.username_actualiza,
					};

          loadUsuario();

				} else {
					setMessage({
						severity: response.messageSeverity,
						messageText: response.messageText,
					});
					setShowMessage(true);
					return;
				}
			};
			loadItem();

      const loadUsuario = async () => {
				setIsDoingSomething(true);
				const response = await getUsuario({ username: _itemCrud.username });
				setIsDoingSomething(false);

				if (response.ok) {
					_itemCrud.nombreUsuario = response.data.data[0].nombre;
          _itemCrud.apellidoUsuario = response.data.data[0].apellido;

          setActionCrud(action);
					setItemCrud(_itemCrud);
					setOpenDialog(true);

				} else {
					setMessage({
						severity: response.messageSeverity,
						messageText: response.messageText,
					});
					setShowMessage(true);
					return;
				}
			};

		} else {
			setActionCrud(action);
			setItemCrud(_itemCrud);
			setOpenDialog(true);
		}
	};

  // ################################################################################
  //
	const handleDialogSave = () => {
		load();
		setOpenDialog(false);
	};
  //
  // ################################################################################
  //
	const handleDialogClose = () => {
		setOpenDialog(false);
	};
  //
  // ################################################################################
  // let table = data.data.table

	return (
		<>
			<Paper
				sx={tableStyles}
				elevation={2}
			>
				<Box
					sx={{
						margin: "auto",
						width: "90%",
					}}
				>
					<Box sx={{ m: 1, p: 1}}>
						<Grid container spacing={1} sx={{ color: '#fff'}}>
							<Grid item xs={12} sx={{ color: '#fff'}}>
								<h2>{nameItemsText}</h2>
							</Grid>
							<Grid item xs={12} md={4} sx={{ color: '#fff'}}>
                <Autocomplete
                    id="pais"
                    size="small"
                    value={valueAutoCompletePais}
                    onChange={(event, newValue) => {
                      setValueAutoCompletePais(newValue);
                      setSearchData({
                        ...searchData,
                        idPais:
                          newValue === null ? "" : newValue.id,
                      });
                    }}
                    inputValue={inputValueAutoCompletePais}
                    onInputChange={(event, newInputValue) => {
                      setInputValueAutoCompletePais(newInputValue);
                    }}
                    options={paises}
                    renderInput={(params) => (
                      <TextField {...params} label="País" />
                    )}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                  />
                </Grid>
                <Grid item xs={12}  md={6} lg={3} sx={{ color: '#fff'}}>
								<TextField
									sx={{ width: "100%", mr: 1, color: '#fff' }}
									id="descripcion"
									name="descripcion"
									value={searchData.descripcion}
									onChange={handleChange}
									autoFocus={true}
									label="Descripción"
									variant="outlined"
									size="small"
                  color="primary"
								/>
							</Grid>
							<Grid item xs={12} md={3} sx={{ color: '#fff'}}>
								<FormControl sx={{ width: "100%",color: '#fff' }}>
									<InputLabel id="tipo-documento-select-label" sx={{ color: '#fff'}}>
										Estado
									</InputLabel>
									<Select
										sx={{ width: "100%", mr: 1,color: '#fff' }}
										labelId="estado"
                    label="Estado"
										id="estado"
										name="estado"
										value={searchData.estado}
										size="small"
										onChange={handleChange}
									>
										{estadoOptions.map((item) => (
											<MenuItem key={item.id} value={item.id} >
												{item.descripcion}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Box>

					<Box sx={{ m: 1, p: 1 }}>
						<Grid container spacing={1}>
							<Grid item xs={12} md={3} lg={2}>
								<Button
									sx={{ width: "100%" }}
									variant="contained"
									onClick={() => handleClickActionCrud({}, "create")}
									startIcon={<AddIcon />}
								>
									Nuevo
								</Button>
							</Grid>
							<Grid item xs={12} md={3} lg={2}>
								<Button
									sx={{ width: "100%", mr: 1 }}
									variant="contained"
									onClick={search}
									startIcon={<SearchIcon />}
								>
									Buscar
								</Button>
							</Grid>
							<Grid item xs={12} md={3} lg={2}>
								<Button
									sx={{ width: "100%", mr: 1 }}
									variant="contained"
									onClick={clean}
									startIcon={<CleaningServicesIcon />}
								>
									Limpiar
								</Button>
							</Grid>
						</Grid>
					</Box>

          <Box sx={{ m: 1, p: 1 }}>
          </Box>
            {isDoingSomething && <LinearProgress />} 
					<ThemeProvider theme={darkTheme}>
            <MUIDataTable columns={tableColumns} data={itemsCrud} options={tableOptions} />
          </ThemeProvider>
				</Box>
			</Paper>

			{/* >>>> Dialog */}

			{openDialog && (
				<ProvinciaDialog
          paises={paises}
					onSave={handleDialogSave}
					onClose={handleDialogClose}
					itemCrud={itemCrud}
					actionCrud={actionCrud}
					setMessage={setMessage}
					setShowMessage={setShowMessage}
				/>
			)}

			{/* >>>> Messages */}

			{showMessage && (
				<Message
					showMessage={showMessage}
					setShowMessage={setShowMessage}
					message={message}
				/>
			)}
		</>
	);
};

export default ProvinciaTable;
