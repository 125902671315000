import { Alert, Snackbar } from "@mui/material";
import { useState } from "react";

const Message = ({showMessage, setShowMessage, message }) => {
  const [open, setOpen] = useState(showMessage);

  const handleCloseSnackBar = () => {
    setOpen(false);
    setShowMessage(false);
  };

	return (
		<Snackbar
			open={open}
			autoHideDuration={3000}
			onClose={handleCloseSnackBar}
		>
			<Alert
				onClose={handleCloseSnackBar}
				severity={message.severity}
				sx={{ width: "100%" }}
			>
				{message.messageText}
			</Alert>
		</Snackbar>
	);
};

export default Message;
