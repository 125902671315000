import axios from "axios";
import { urlBackendUsuarios } from "../configs/config";
import keycloakObject from "../utils/keycloak";

const epName = "/organismo-fuero/";
const nameItemsText = "OrganismosFueros";
const nameItemText = "Organismo-Fueros";
const itemGender = "M";
const elLa = itemGender === "M" ? "el" : "la";
// const unUna = itemGender === "M" ? "un" : "una";
//
// #############################################################################
//
export const getOrganismosFueros = async (params) => {
  let _params = {};
  let _errorMessage = `Se produjo un error al obtener ${nameItemsText}`;

  if (params.idFuero) {
    _params.id_fuero = params.idFuero;
  }

  if (params.idOrganismo) {
    _params.id_organismo = params.idOrganismo;
  }

	if (params.circunscripcionJudicial) {
		_params.circunscripcion_judicial_organismo = params.circunscripcionJudicial;
	}

  if (params.idCompetencia) {
    _params.id_competencia = params.idCompetencia;
  }

  if (params.rows) {
    _params.rows = params.rows;
  }

  if (params.first) {
    _params.first = params.first;
  }

  if (params.sortField) {
		_params.sortfield = params.sortField;
	}

	if (params.sortOrder) {
		_params.sortorder = params.sortOrder;
	}

  try {
    const response = await axios({
      method: "GET",
      url: `${urlBackendUsuarios}${epName}`,
      params: _params,
      headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
    });

    if (response.status === 200) {
      let _error = "error_code" in response.data;
      if (!_error) {
        // si no hay error controlado
        response.ok = true;
        return response;
      }
    }

    // si hay algun error controlado

    console.log(_errorMessage);
    console.log(response);

    response.ok = false;
    response.messageText = _errorMessage;
    response.messageSeverity = "error";
    return response;
  } catch (error) {
    console.log(_errorMessage);
    if (error.response) {
      // The request was made and the server responded with a status code that falls out of the range of 2xx
      console.log(error.response);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(error);
    }
    let response = {};
    response.ok = false;
    response.messageText = _errorMessage;
    response.messageSeverity = "error";
    return response;
  }
};
//
// #############################################################################
//
export const getOrganismoFuero = async (params) => {
  let _errorMessage = `Se produjo un error al obtener ${elLa} ${nameItemText}`;
  try {
    const response = await axios({
      method: "GET",
      url: `${urlBackendUsuarios}${epName}`,
      params: params,
      headers: { Authorization: `Bearer ${keycloakObject.getToken()}` },
    });

    if (response.status === 200) {
      let _error = "error_code" in response.data;
      if (!_error) {
        // si no hay error controlado
        response.ok = true;
        return response;
      }
    }

    // si hay algun error controlado

    console.log(_errorMessage);
    console.log(response);

    response.ok = false;
    response.messageText = _errorMessage;
    response.messageSeverity = "error";
    return response;
  } catch (error) {
    console.log(_errorMessage);
    if (error.response) {
      // The request was made and the server responded with a status code that falls out of the range of 2xx
      console.log(error.response);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(error);
    }
    let response = {};
    response.ok = false;
    response.messageText = _errorMessage;
    response.messageSeverity = "error";
    return response;
  }
};
//
// #############################################################################
//
export const saveOrganismoFuero = async (actionCrud, item) => {
  let _errorMessage = `No fue posible guardar ${elLa} ${nameItemText}`;

  let _item = {
    id_fuero: item.idFuero,
    id_organismo: item.idOrganismo,
    id_competencia: item.idCompetencia,
    username_actualiza: item.usernameUsuarioActualiza,
  };
  if (actionCrud !== "create") {
    _item.id = item.id;
  }

  try {
    let response = {};
    if (actionCrud === "create") {
      response = await axios({
        method: "POST",
        url: `${urlBackendUsuarios}${epName}`,
        data: _item,
        headers: { Authorization: `Bearer ${keycloakObject.getToken()}` },
      });
    } else if (actionCrud === "update") {
      response = await axios({
        method: "PUT",
        url: `${urlBackendUsuarios}${epName}`,
        data: _item,
        headers: { Authorization: `Bearer ${keycloakObject.getToken()}` },
      });
    } else if (actionCrud === "disable" || actionCrud === "enable") {
      response = await axios({
        method: "DELETE",
        url: `${urlBackendUsuarios}${epName}`,
        params: {
          id: _item.id,
          username_actualiza: _item.username_actualiza,
          action: actionCrud,
        },
        headers: { Authorization: `Bearer ${keycloakObject.getToken()}` },
      });
    }

    if (response.status === 200) {
      console.log(response.data);
      let _error = "error_code" in response.data;
      if (!_error) {
        // si no hay error controlado
        response.ok = true;
        response.messageText =
          `${nameItemText}` +
          (actionCrud === "disable"
            ? itemGender === "M"
              ? " deshabilitado"
              : " deshabilitada"
            : actionCrud === "enable"
            ? itemGender === "M"
              ? " habilitado"
              : " habilitada"
            : itemGender === "M"
            ? " guardado"
            : " guardada");
        response.messageSeverity = "success";
      } else {
        // si hay error controlado
        console.log(_errorMessage);
        console.log(response);

        let _message_code = response.data.message_code;
        if (_message_code === "err-organismo-fuero-already-exist") {
          response.messageText = `Ya existe ${elLa} ${nameItemText}.`;
        } else if (_message_code === "err-organismo-fuero") {
          response.messageText = `El Organismo no corresponde a ese Fuero`;
        } else {
          response.messageText = _errorMessage;
        }
        response.ok = false;
        response.messageSeverity = "error";
      }
      return response;
    }
  } catch (error) {
    console.log(_errorMessage);
    if (error.response) {
      // The request was made and the server responded with a status code that falls out of the range of 2xx
      console.log(error.response);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(error);
    }
    let response = {};
    response.ok = false;
    response.messageText = _errorMessage;
    response.messageSeverity = "error";
    return response;
  }
};
//
// #############################################################################
//
