import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useEffect, useState } from "react";
import { getProcesos } from "../../services/ProcesoService";
import { getProcesosEtapasProcesales } from "../../services/ProcesoEtapaProcesalService";
import { getSubtiposActJuzgado } from "../../services/SubtipoActJuzgadoService";
import { saveProcesoEtapaProcesalSubtipoActJuzgado } from "../../services/ProcesoEtapaProcesalSubtipoActJuzgadoService";
import darkTheme from "../../layout/DarkTheme";

const initialState = {
  observaciones: "",
};


const itemGender = "M";
const nameItemText = "Proceso Etapa Procesal - Subtipo Actuación Juzgado";

const ProcesoEtapaProcesalSubtipoActJuzgadoDialog = ({
  fueros,

  onSave,
  onClose,
  itemCrud,
  actionCrud,
  setMessage,
  setShowMessage,
}) => {
  const [itemCrudLocal, setItemCrudLocal] = useState(initialState);
  const [isDoingSomething, setIsDoingSomething] = useState(false);
  const [procesosLocal, setProcesosLocal] = useState([]);
  const [procesosEtapasProcesalesLocal, setProcesosEtapasProcesalesLocal] = useState([]);
  const [subtiposActJuzgadoLocal, setSubtiposActJuzgadoLocal] = useState([]);
  const [valueAutoCompleteFueros, setValueAutoCompleteFueros] = useState(itemCrud.fuero);
  const [inputValueAutoCompleteFueros, setInputValueAutoCompleteFueros] = useState("");
  const [valueAutoCompleteProcesos, setValueAutoCompleteProcesos] = useState(itemCrud.proceso);
  const [inputValueAutoCompleteProcesos, setInputValueAutoCompleteProcesos] = useState("");
  const [valueAutoCompleteProcesosEtapasProcesales, setValueAutoCompleteProcesosEtapasProcesales] = useState(itemCrud.procesoEtapaProcesal);
  const [inputValueAutoCompleteProcesosEtapasProcesales, setInputValueAutoCompleteProcesosEtapasProcesales] = useState("");
  const [valueAutoCompleteSubtiposActJuzgado, setValueAutoCompleteSubtiposActJuzgado] = useState(itemCrud.subtipoActJuzgado);
  const [inputValueAutoCompleteSubtiposActJuzgado, setInputValueAutoCompleteSubtiposActJuzgado] = useState("");

  useEffect(() => {
    if (actionCrud === "create") {
      setValueAutoCompleteFueros("");
      setItemCrudLocal(initialState);
    } else {
      setValueAutoCompleteFueros(itemCrud.fuero);
      setValueAutoCompleteProcesos(itemCrud.proceso);
      setValueAutoCompleteSubtiposActJuzgado(itemCrud.subtipoActJuzgado);
      setItemCrudLocal(itemCrud);
      loadProcesos(itemCrud.fuero);
      loadSubtiposActJuzgado();
    }
  }, []);

  const handleCloseDialog = () => {
    onClose();
  };

  const handleChange = (e) => {
    setItemCrudLocal({
      ...itemCrudLocal,
      [e.target.name]: e.target.value,
    });
  };

  const handleFueroChange = (event, newValue) => {
    setValueAutoCompleteFueros(newValue);
    setValueAutoCompleteProcesos("");
    setValueAutoCompleteProcesosEtapasProcesales("");
    setValueAutoCompleteSubtiposActJuzgado("");
    loadProcesos(newValue);
		
  };


  const handleProcesoChange = (event, newValue) => {
    setValueAutoCompleteProcesos(newValue);
    setValueAutoCompleteProcesosEtapasProcesales("");
    setProcesosEtapasProcesalesLocal([]);
    setValueAutoCompleteSubtiposActJuzgado("");
    loadProcesosEtapasProcesales(newValue);
    loadSubtiposActJuzgado();
  };


  const handleSave = () => {
    if (actionCrud === "create" || actionCrud === "update") {
      if (inputValueAutoCompleteFueros === "") {
        setMessage({
          severity: "error",
          messageText: "Debes seleccionar un Fuero",
        });
        setShowMessage(true);
        return;
      }

      if (inputValueAutoCompleteProcesos === "") {
        setMessage({
          severity: "error",
          messageText: "Debes seleccionar un Proceso",
        });
        setShowMessage(true);
        return;
      }

      if (inputValueAutoCompleteProcesosEtapasProcesales === "") {
        setMessage({
          severity: "error",
          messageText: "Debes seleccionar una Etapa Procesal",
        });
        setShowMessage(true);
        return;
      }

      if (inputValueAutoCompleteSubtiposActJuzgado === "") {
        setMessage({
          severity: "error",
          messageText: "Debes seleccionar un Tipo: Subtipo Actuación Juzgado",
        });
        setShowMessage(true);
        return;
      }

      if (itemCrudLocal.avanzaEtapa === "" || itemCrudLocal.avanzaEtapa === undefined || itemCrudLocal.avanzaEtapa === null) {
        setMessage({
          severity: "error",
          messageText: "Debes seleccionar si avanza de etapa procesal",
        });
        setShowMessage(true);
        return;
      }

      if (itemCrudLocal.finalizaProceso === "" || itemCrudLocal.finalizaProceso === undefined || itemCrudLocal.finalizaProceso === null) {
        setMessage({
          severity: "error",
          messageText: "Debes seleccionar si finaliza el proceso del expediente",
        });
        setShowMessage(true);
        return;
      }
    }
    save();
  };

  const save = async () => {
    let _itemCrud = {
      idFuero: valueAutoCompleteFueros?.id,
      idProcesoEtapaProcesal: valueAutoCompleteProcesosEtapasProcesales?.id,
      idSubtipoActJuzgado: valueAutoCompleteSubtiposActJuzgado?.id,
      avanzaEtapa: itemCrudLocal.avanzaEtapa,
      finalizaProceso: itemCrudLocal.finalizaProceso,
      observaciones: itemCrudLocal?.observaciones,
      usernameUsuarioActualiza: localStorage.getItem("emailUsuario"),
    };
    if (actionCrud !== "create") {
      _itemCrud.id = itemCrud.id;
    }
    setIsDoingSomething(true);
    const response = await saveProcesoEtapaProcesalSubtipoActJuzgado(actionCrud, _itemCrud);
    setIsDoingSomething(false);

    if (response.ok) {
      onSave();
    }

    setMessage({
      messageText: response.messageText,
      severity: response.messageSeverity,
    });
    setShowMessage(true);
  };

  const loadProcesos = async (fuero) => {
    let _params = {
      idFuero: fuero?.id || valueAutoCompleteFueros?.id,
    };
    setIsDoingSomething(true);

    const response = await getProcesos(_params);
    setIsDoingSomething(false);
    if (response.ok) {
      let _response = response.data.data;
      let _procesos = [];
      _response.forEach((item) => {
        _procesos.push({
          id: item.id,
          label: item.descripcion,
        });
      });
      setProcesosLocal(_procesos);
    } else {
      setProcesosLocal([]);
      setMessage({
        messageText: response.messageText,
        severity: response.messageSeverity,
      });
      setShowMessage(true);
    }
  };

  const loadProcesosEtapasProcesales = async (proceso) => {
    let _params = {
      idProceso: proceso?.id || valueAutoCompleteProcesos?.id,
    };
    setIsDoingSomething(true);
    const response = await getProcesosEtapasProcesales(_params);
    setIsDoingSomething(false);
    if (response.ok) {
      let _response = response.data.data;
      let _procesos_etapas_procesales = [];
      
      _response.forEach((item) => {

        _procesos_etapas_procesales.push({
          id: item.id,
          label: item.descripcion_etapa_procesal,
        });
      });
      setProcesosEtapasProcesalesLocal(_procesos_etapas_procesales);
    } else {
      setProcesosEtapasProcesalesLocal([]);
      setMessage({
        messageText: response.messageText,
        severity: response.messageSeverity,
      });
      setShowMessage(true);
    }
  };


  const loadSubtiposActJuzgado = async () => {
    let _params = {
      estado: 'habilitado',
    };
    setIsDoingSomething(true);
    const response = await getSubtiposActJuzgado(_params);
    setIsDoingSomething(false);
    if (response.ok) {
      let _response = response.data.data;
      let _subtipos_act_juzgado = [];
      _response.forEach((item) => {
        _subtipos_act_juzgado.push({
          id: item.id,
          label: item.descripcion_tipo_act_juzgado +': ' + item.descripcion,
        });
      });
      setSubtiposActJuzgadoLocal(_subtipos_act_juzgado);
    } else {
      setSubtiposActJuzgadoLocal([]);
      setMessage({
        messageText: response.messageText,
        severity: response.messageSeverity,
      });
      setShowMessage(true);
    }
  };

  return (
    <>
      <Dialog
        open={true}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth={true}
        scroll="paper"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title"
          textAlign={{ xs: "center", sm: "center" }}
          style={{ background: darkTheme.palette.primary.dark, color: darkTheme.palette.primary.text}}
        >
          {actionCrud === "create"
            ? itemGender === "M"
              ? "Nuevo"
              : "Nueva"
            : actionCrud === "update"
              ? "Editando"
              : actionCrud === "enable"
                ? "Habilitando"
                : actionCrud === "disable"
                  ? "Deshabilitando"
                  : actionCrud === "read"
                    ? "Detalle"
                    : ""}{" "}
          {nameItemText}
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        {isDoingSomething && <LinearProgress />}

        <DialogContent dividers>
          <Box sx={{ m: 1, p: 1 }}>
            <Autocomplete
              id="fuero"
              sx={{ width: "100%", pb: 1 }}
              size="small"
              value={valueAutoCompleteFueros}
              onChange={handleFueroChange}
              inputValue={inputValueAutoCompleteFueros}
              onInputChange={(event, newInputValue) => {
                setInputValueAutoCompleteFueros(newInputValue);
              }}
              options={fueros}
              renderInput={(params) => (
                <TextField {...params} label="Fuero" />
              )}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
              disableClearable={true}
            />

            <Autocomplete
              id="proceso"
              sx={{ width: "100%", pb: 1 }}
              size="small"
              value={valueAutoCompleteProcesos}
              onChange={handleProcesoChange}
              inputValue={inputValueAutoCompleteProcesos}
              onInputChange={(event, newInputValue) => {
                setInputValueAutoCompleteProcesos(newInputValue);
              }}
              options={procesosLocal}
              renderInput={(params) => (
                <TextField {...params} label="Proceso" />
              )}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read" || !valueAutoCompleteFueros?.id }
            />

            <Autocomplete
              id="procesoEtapaProcesal"
              sx={{ width: "100%", pb: 1 }}
              size="small"
              value={valueAutoCompleteProcesosEtapasProcesales}
              onChange={(event, newValue) => {
                setValueAutoCompleteProcesosEtapasProcesales(newValue);
              }
              }
              inputValue={inputValueAutoCompleteProcesosEtapasProcesales}
              onInputChange={(event, newInputValue) => {
                setInputValueAutoCompleteProcesosEtapasProcesales(newInputValue);
              }}
              options={procesosEtapasProcesalesLocal}
              renderInput={(params) => (
                <TextField {...params} label="Etapa Procesal" />
              )}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read" || !valueAutoCompleteProcesos?.id}
            />
            

            <Autocomplete
              id="subtipoActJuzgado"
              sx={{ width: "100%", pb: 1 }}
              size="small"
              value={valueAutoCompleteSubtiposActJuzgado}
              onChange={(event, newValue) => {
                setValueAutoCompleteSubtiposActJuzgado(newValue);
              }}
              inputValue={inputValueAutoCompleteSubtiposActJuzgado}
              onInputChange={(event, newInputValue) => {
                setInputValueAutoCompleteSubtiposActJuzgado(newInputValue);
              }}
              options={subtiposActJuzgadoLocal}
              renderInput={(params) => (
                <TextField {...params} label="Tipo: Subtipo Actuación Juzgado" />
              )}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
            />

            <FormControl variant="outlined" size="small" sx={{ width: "100%", pb: 1 }}>
              <InputLabel id="avanzaEtapa">Avanza de Etapa Procesal</InputLabel>
              <Select
                labelId="avanzaEtapa"
                name="avanzaEtapa"
                value={itemCrudLocal.avanzaEtapa}
                onChange={handleChange}
                label="Avanza de Etapa Procesal "
                disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
              >
                <MenuItem value={true}>Sí</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>

            <FormControl variant="outlined" size="small" sx={{ width: "100%", pb: 1 }}>
              <InputLabel id="finalizaProceso">Finaliza el Proceso del Expediente</InputLabel>
              <Select
                labelId="finalizaProceso"
                name="finalizaProceso"
                value={itemCrudLocal.finalizaProceso}
                onChange={handleChange}
                label="Finaliza el proceso del Expediente"
                disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
              >
                <MenuItem value={true}>Sí</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>

            <TextField
              variant="outlined"
              size="small"
              name="observaciones"
              label="Observaciones"
              value={itemCrudLocal.observaciones}
              onChange={handleChange}
              disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
              sx={{ width: "100%", pb: 1 }}
              multiline
              rows={4}
            />

            {actionCrud !== "create" && (
              <Box
                sx={{
                  p: 1,
                  mt: 1,
                  color: "SlateGrey",
                  border: "0px solid DarkGray",
                  borderRadius: "3px",
                }}
              >
                <Typography variant="h8" component="div" gutterBottom>
                  Última actualización: {itemCrudLocal.fechaActualizacion}
                </Typography>

                <Typography variant="h8" component="div" gutterBottom>
                  Último usuario que actualizó: {itemCrudLocal.nombreUsuario}{" "}
                  {itemCrudLocal.apellidoUsuario} ({itemCrudLocal.username})
                </Typography>
              </Box>
            )}
          </Box>

          <Box sx={{ textAlign: "right" }}>
            <Button
              sx={{ mr: 1 }}
              variant="outlined"
              onClick={handleCloseDialog}
              startIcon={<CloseIcon />}
            >
              Cancelar
            </Button>

            {(actionCrud !== "read") && (
              <Button
                sx={{ mr: 1, backgroundcolor: "warning" }}
                variant="contained"
                onClick={handleSave}
                startIcon={
                  actionCrud === "disable" ? (
                    <RemoveCircleOutlineIcon />
                  ) : actionCrud === "enable" ? (
                    <AddCircleOutlineIcon />
                  ) : (
                    <SaveIcon />
                  )
                }
              >
                {actionCrud === "disable"
                  ? "Deshabilitar"
                  : actionCrud === "enable"
                    ? "Habilitar"
                    : "Guardar"}
              </Button>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ProcesoEtapaProcesalSubtipoActJuzgadoDialog;
