import {
	Autocomplete,
	Button,
	FormControl,
	FormControlLabel,
  FormLabel,
	Grid,
	InputLabel,
	LinearProgress,
	MenuItem,
	Paper,
	Radio,
  RadioGroup,
	Select,
	ThemeProvider,
	TextField,
	Tooltip,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RemoveIcon from "@mui/icons-material/Remove";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import { useEffect, useState } from "react";
import { getTipoActJuzgados } from "../../services/TipoActJuzgadoService.js";
import { getSubtiposActJuzgado } from "../../services/SubtipoActJuzgadoService.js";
import { getFuerosSubtiposActJuzgado, getFueroSubtipoActJuzgado } from "../../services/FueroSubtipoActJuzgadoService.js";
import FueroSubtipoActJuzgadoDialog from "./FueroSubtipoActJuzgadoDialog.jsx";
import { getFueros } from "../../services/FueroService.js";
import { getFunciones } from "../../services/FuncionService.js";
import Message from "../../utils/Message.jsx";
import { Box } from "@mui/system";
import darkTheme from "../../layout/DarkTheme.jsx";
import React from 'react';
import { useMenu } from "../../context/MenuContext.js";

const initialState = {
	estado: "habilitado",
	visibilidad: "todo",
	idFuero: "",
	firmas:"todo",
	orden: "ASC",
  ordenarPor: "descripcion_fuero",
	rows: 0,
	first: 0,
};

const estadoOptions = [
	{
		id: "todo",
		descripcion: "Todo",
	},
	{
		id: "habilitado",
		descripcion: "Habilitado",
	},
	{
		id: "deshabilitado",
		descripcion: "Deshabilitado",
	},
];

const visibilidadOptions = [
	{
		id: "todo",
		descripcion: "Todo",
	},
	{
		id: "publico",
		descripcion: "Público",
	},
	{
		id: "no-publico",
		descripcion: "No Público",
	},
];

const firmasOptions = [
	{
		id: "todo",
		descripcion: "Todo",
	},
	{
		id: "electronica",
		descripcion: "Electrónica",
	},
	{
		id: "digital",
		descripcion: "Digital",
	},
	{
		id: "ambas",
		descripcion: "Ambas",
	},
	{
		id: "ninguna",
		descripcion: "Ninguna",
	},	
];


const ordenOptions = [
  {
    id: "ASC",
    descripcion: "Ascendente",
  },
  {
    id: "DESC",
    descripcion: "Descendente",
  },
];

const ordenarPorOptions = [
	{
		id: "descripcion_fuero",
		descripcion: "Fuero",
	},
	{
		id: "descripcion_tipo_act_juzgado",
		descripcion: "Tipo Actuación Juzgado",
	},
	{
		id: "descripcion_subtipo_act_juzgado",
		descripcion: "Subtipo Actuación Juzgado",
	},
	{
		id: "visibilidad",
		descripcion: "Visibilidad",
	},
];

const nameItemsText = "Fueros Subtipos Actuaciones Juzgados";
//
// ##################################################################################################################### 
//
const FueroSubtipoActJuzgadoTable = ({...data}) => {
	const [searchData, setSearchData] = useState(initialState);
	const [itemsCrud, setItemsCrud] = useState([]);
	const [itemCrud, setItemCrud] = useState({});
	const [actionCrud, setActionCrud] = useState("");
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [openDialog, setOpenDialog] = useState(false);
	const [message, setMessage] = useState({});
	const [showMessage, setShowMessage] = useState(false);
	const [isDoingSomething, setIsDoingSomething] = useState(false);
	const [totalRowsCount, setTotalRowsCount] = useState(0);
	const [currentPage, setCurrentPage] = useState(0);
	const [funciones, setFunciones] = useState([]);
	const [fueros, setFueros] = useState([]);
	const [valueAutoCompleteFuero, setValueAutoCompleteFuero] = useState({
		id: "",
		label: "",
	});
	const [inputValueAutoCompleteFuero, setInputValueAutoCompleteFuero] = useState("");
	const [tipoActJuzgado, setTipoActJuzgado] = useState([]);
	const [valueAutoCompleteTipoActJuzgado, setValueAutoCompleteTipoActJuzgado] = useState({
		id: "",
		label: "",
	});
	const [inputValueAutoCompleteTipoActJuzgado, setInputValueAutoCompleteTipoActJuzgado] = useState("");
	const [subtipoActJuzgado, setSubtipoActJuzgado] = useState([]);
	const [valueAutoCompleteSubtipoActJuzgado, setValueAutoCompleteSubtipoActJuzgado] = useState({
		id: "",
		label: "",
	});
	const [inputValueAutoCompleteSubtipoActJuzgado, setInputValueAutoCompleteSubtipoActJuzgado] = useState("");
  const { menuOpen } = useMenu();

  const tableStyles = {
    width: menuOpen ?  "80%": "95%", 
    height: "90%",
    overflow: "auto",
    p: 1,
    margin: "auto",
    position: 'fixed', 
    top: "8%", 
    left: menuOpen ? '15%' : '0%', 
    transition: 'left 0.3s ease' 
  };


	const tableColumns = [
		{
			name: "id",
			label: "ID",
			options: {
				display: "excluded",
			},
		},
		{
			name: "descripcion_fuero",
			label: "Fuero",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					const habilitadoFuero = itemsCrud[tableMeta.rowIndex].habilitado_fuero ? "" : "(Deshabilitado)";
					return `${value} ${habilitadoFuero}`;
				},	
			},
		},
		{
			name: "descripcion_tipo_act_juzgado",
			label: "Tipo Actuación Juzgado",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					const habilitadoTipoActJuzgado = itemsCrud[tableMeta.rowIndex].habilitado_tipo_act_juzgado ? "" : "(Deshabilitada)";
					return `${value} ${habilitadoTipoActJuzgado}`;
				},	
			},
		},
		{
			name: "descripcion_subtipo_act_juzgado",
			label: "Subtipo Actuación Juzgado",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					const habilitadoSubtipoActJuzgado = itemsCrud[tableMeta.rowIndex].habilitado_subtipo_act_juzgado ? "" : "(Deshabilitada)";
					return `${value} ${habilitadoSubtipoActJuzgado}`;
				},	
			},
		},
		{
			name: "firmas",
			label: "Firmas",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					const firma = itemsCrud[tableMeta.rowIndex].firma_digital === true && itemsCrud[tableMeta.rowIndex].firma_electronica === true ? "Ambas" : itemsCrud[tableMeta.rowIndex].firma_digital === false && itemsCrud[tableMeta.rowIndex].firma_electronica === false ? "Ninguna" : itemsCrud[tableMeta.rowIndex].firma_digital === true ? "Digital" : "Electrónica";

					return `${firma}`;
				},
			},
		},
		{
			name: "visibilidad" ,
			label: "Visibilidad",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			name: "actions",
			label: "Acciones",
			options: {
				filter: false,
				sort: false,
				empty: true,
				customBodyRenderLite: (dataIndex, rowIndex) => (
					<>
						{itemsCrud[dataIndex].habilitado_subtipo_act_juzgado && itemsCrud[dataIndex].habilitado_fuero && itemsCrud[dataIndex].habilitado && (
							<Tooltip title="Editar">
								<IconButton
									size="small"
									aria-label="update"
									onClick={() => handleClickActionCrud(itemsCrud[dataIndex], "update")}
								>
									<EditOutlinedIcon />
								</IconButton>
							</Tooltip>
						)}

						{itemsCrud[dataIndex].habilitado_subtipo_act_juzgado && itemsCrud[dataIndex].habilitado_fuero && itemsCrud[dataIndex].habilitado && (
							<Tooltip title="Deshabilitar">
								<IconButton
									size="small"
									aria-label="disable"
									onClick={() => handleClickActionCrud(itemsCrud[dataIndex], "disable")}
								>
									<RemoveIcon />
								</IconButton>
							</Tooltip>
						)}

						{itemsCrud[dataIndex].habilitado_subtipo_act_juzgado && itemsCrud[dataIndex].habilitado_fuero && !itemsCrud[dataIndex].habilitado && (
							<Tooltip title="Habilitar">
								<IconButton
									size="small"
									aria-label="enable"
									onClick={() => handleClickActionCrud(itemsCrud[dataIndex], "enable")}
								>
									<AddIcon />
								</IconButton>
							</Tooltip>
						)}
					
					{(
              <Tooltip title="Ver">
                <IconButton
                  size="small"
                  aria-label="read"
                  onClick={() => handleClickActionCrud(itemsCrud[dataIndex], "read")}
                >
                  <ZoomInIcon />
                </IconButton>
              </Tooltip>
            )}
						</>
				),
			},
		},
	];

	const tableOptions = {
		storageKey: "my-table-state",
		rowsPerPageOptions: [10, 20, 30],
		serverSide: true,
		count: totalRowsCount,
		rowsPerPage: rowsPerPage,
		onChangeRowsPerPage: (numberOfRows) => {
			setRowsPerPage(numberOfRows);
		},
		onChangePage: (currentPage) => {
			setCurrentPage(currentPage);
		},
		download: false,
		filter: false,
		print: false,
		search: false,
		viewColumns: false,
		selectableRowsHeader: false,
		selectableRows: "none",
		elevation: 1,
		textLabels: {
			body: {
				noMatch: "No se han encontrado resultados para esta búsqueda",
			},
			pagination: {
				next: "Próxima",
				previous: "Previa",
				rowsPerPage: "Filas:",
				displayRows: "de",
			},
		},
	};
	//
	// ################################################################################
	//
	const load = async () => {
		searchData.rows = rowsPerPage;
		searchData.first =  currentPage * rowsPerPage;
		searchData.sortField = searchData.ordenarPor;
		searchData.sortOrder = searchData.orden; 

		setIsDoingSomething(true);
		const response = await getFuerosSubtiposActJuzgado(searchData);
		setIsDoingSomething(false);

		if (response.ok) {
			setItemsCrud(response.data.data);
			setTotalRowsCount(response.data.count);
		} else {
			setMessage({
				messageText: response.messageText,
				severity: response.messageSeverity,
			});
			setShowMessage(true);
		}
	};

	//
	// ################################################################################
	//

	const loadSubtipoActJuzgados = async (newValue) => {
		let _params = {
			idTipoActJuzgado: newValue?.id || valueAutoCompleteTipoActJuzgado?.id,
		};

		setIsDoingSomething(true);
		const response = await getSubtiposActJuzgado( _params );
		setIsDoingSomething(false);

		if (response.ok) {
			let _response = response.data.data;
			let _subtipoActJuzgados = [];

			_response.forEach((item) => {
				_subtipoActJuzgados.push({
					id: item.id,
					label: item.descripcion,
				});
			});

			setSubtipoActJuzgado(_subtipoActJuzgados);
		} else {
			setSubtipoActJuzgado([]);
			setMessage({
				messageText: response.messageText,
				severity: response.messageSeverity,
			});
			setShowMessage(true);
		}
	};



	//
	// ################################################################################
	//
	useEffect(() => {
		const loadFueros = async () => {
			setIsDoingSomething(true);
			const response = await getFueros({ estado: "habilitado" });
			setIsDoingSomething(false);

			if (response.ok) {
				let _response = response.data.data;
				let _Fueros = [];

				_response.forEach((item) => {
					_Fueros.push({
						id: item.id,
						label: item.descripcion,
					});
				});

				setFueros(_Fueros);
			} else {
				setFueros([]);
				setMessage({
					messageText: response.messageText,
					severity: response.messageSeverity,
				});
				setShowMessage(true);
			}
		};
		loadFueros();
	}, []);


	//
	// ################################################################################
	//
	useEffect(() => {
		const loadTipoActJuzgados = async () => {
			setIsDoingSomething(true);
			const response = await getTipoActJuzgados({ estado: "habilitado" });
			setIsDoingSomething(false);

			if (response.ok) {
				let _response = response.data.data;
				let _TipoActJuzgados = [];

				_response.forEach((item) => {
					_TipoActJuzgados.push({
						id: item.id,
						label: item.descripcion,
					});
				});

				setTipoActJuzgado(_TipoActJuzgados);
			} else {
				setTipoActJuzgado([]);
				setMessage({
					messageText: response.messageText,
					severity: response.messageSeverity,
				});
				setShowMessage(true);
			}
		};
		loadTipoActJuzgados();
	}, []);

	//
	// ################################################################################
	//
	useEffect(() => {
		const loadFunciones = async () => {
			setIsDoingSomething(true);
			const response = await getFunciones({ estado: "todo" });
			setIsDoingSomething(false);

			if (response.ok) {
				let _response = response.data.data;
				let _Funciones = [];

				_response.forEach((item) => {
					_Funciones.push({
						id: item.id,
						descripcion: item.descripcion,
						habilitado: item.habilitado,
					});
				});

				setFunciones(_Funciones);
			} else {
				setFunciones([]);
				setMessage({
					messageText: response.messageText,
					severity: response.messageSeverity,
				});
				setShowMessage(true);
			}
		};
		loadFunciones();
	}, []);


	//
	// ################################################################################
	//




	useEffect(() => {
		load();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage, rowsPerPage]);

	const handleChange = (e) => {
		setSearchData({
			...searchData,
			[e.target.name]:
				e.target.type === "checkbox" ? e.target.checked : e.target.value,
		});
	};

	const handleTipoActJuzgadoChange = (event, newValue) => {
    setValueAutoCompleteTipoActJuzgado(newValue);
    setSearchData({
      ...searchData,
      idTipoActJuzgado: newValue?.id || '',
    });
    loadSubtipoActJuzgados(newValue);
  };

	const search = () => {
		setCurrentPage(0);
		load();
	};

	const clean = () => {
		setValueAutoCompleteFuero({ label: "", id: "" });
		setValueAutoCompleteTipoActJuzgado({ label: "", id: "" });
		setValueAutoCompleteSubtipoActJuzgado({ label: "", id: "" });
		setSearchData(initialState);
		setItemsCrud([]);
	};

	const handleClickActionCrud = (itemCrud, action) => {
		let _itemCrud = {};

		if (action !== "create") {
			const loadItem = async () => {
				setIsDoingSomething(true);
				const response = await getFueroSubtipoActJuzgado({ id: itemCrud.id });
				setIsDoingSomething(false);
				if (response.ok) {
					_itemCrud = {
						id: response.data.data[0].id,
						fuero: {
							id: response.data.data[0].id_fuero,
							label: response.data.data[0].descripcion_fuero,
						},
						subtipoActJuzgado: {
							id: response.data.data[0].id_subtipo_act_juzgado,
							label: response.data.data[0].descripcion_subtipo_act_juzgado,
						},
						tipoActJuzgado: {
							id: response.data.data[0].id_tipo_act_juzgado,
							label: response.data.data[0].descripcion_tipo_act_juzgado,
						},
						visibilidad: response.data.data[0].visibilidad,
						firmaDigital: response.data.data[0].firma_digital,
						firmaElectronica: response.data.data[0].firma_electronica,
						habilitado: response.data.data[0].habilitado,
						fechaActualizacion: response.data.data[0].fecha_actualizacion,
						fechaCreacion: response.data.data[0].fecha_creacion,
						nombreUsuario: response.data.data[0].nombre_usuario_actualiza,
						apellidoUsuario: response.data.data[0].apellido_usuario_actualiza,
						username: response.data.data[0].username_usuario_actualiza,
						listFunciones: [...response.data.data[0].funcion],
					};

					setActionCrud(action);
					setItemCrud(_itemCrud);
					setOpenDialog(true);
				} else {
					setMessage({
						severity: response.messageSeverity,
						messageText: response.messageText,
					});
					setShowMessage(true);
					return;
				}
			};
			loadItem();
		} else {


			const loadItem = async () => {
				setIsDoingSomething(true);
				const response = await getFunciones({ estado: "todo" });
				setIsDoingSomething(false);
				if (response.ok) {

					let _listFunciones = [];
					response.data.data.forEach((item) => {
						_listFunciones.push({
							id_funcion: item.id,
							descripcion_funcion: item.descripcion,
							habilitado_funcion: item.habilitado,
							asignado: false,
						});
					});

					
					_itemCrud = {
						id: "",
						fuero: {
							id: "",
							label: "",
						},
						subtipoActJuzgado: {
							id: "",
							label: "",
						},
						tipoActJuzgado: {
							id: "",
							label: "",
						},
						visibilidad: "",
						firmaDigital: false,
						firmaElectronica: false,
						habilitado: true,
						fechaActualizacion: "",
						fechaCreacion: "",
						nombreUsuario: "",
						apellidoUsuario: "",
						username: "",
						listFunciones: _listFunciones,
					};
					setActionCrud(action);
 					setItemCrud(_itemCrud);

			setOpenDialog(true);
				} else {
					setMessage({
						severity: response.messageSeverity,
						messageText: response.messageText,
					});
					setShowMessage(true);
					return;
				}
			};
			loadItem();

		}
	};

	const handleDialogSave = () => {
		load();
		setOpenDialog(false);
	};

	const handleDialogClose = () => {
		setOpenDialog(false);
	};
	//
	// ################################################################################
	// let table = data.data.table

	return (
		<>
			<Paper
				sx={tableStyles}
				elevation={2}
			>
				<Box
					sx={{
						margin: "auto",
						width: "90%",
					}}
				>
					<Box sx={{ m: 1, p: 1 }}>
						<Grid container spacing={1}>
							<Grid item xs={12}>
								<h2>{nameItemsText}</h2>
							</Grid>
							<Grid item xs={12} md={6} lg={2}>
								<Autocomplete
									id="fuero"
									size="small"
									value={valueAutoCompleteFuero}
									onChange={(event, newValue) => {
										setValueAutoCompleteFuero(newValue);
										setSearchData({
											...searchData,
											idFuero:
												newValue === null ? "" : newValue.id,
										});
									}}
									inputValue={inputValueAutoCompleteFuero}
									onInputChange={(event, newInputValue) => {
										setInputValueAutoCompleteFuero(newInputValue);
									}}
									options={fueros}
									renderInput={(params) => (
										<TextField {...params} label="Fuero" />
									)}
									isOptionEqualToValue={(option, value) =>
										option.value === value.value
									}
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={2}>
								<Autocomplete
									id="tipoActJuzgado"
									size="small"
									value={valueAutoCompleteTipoActJuzgado}
									onChange={handleTipoActJuzgadoChange}
									inputValue={inputValueAutoCompleteTipoActJuzgado}
									onInputChange={(event, newInputValue) => {
										setInputValueAutoCompleteTipoActJuzgado(newInputValue);
									}}
									options={tipoActJuzgado}
									renderInput={(params) => (
										<TextField {...params} label="Tipo Actuación Juzgado" />
									)}
									isOptionEqualToValue={(option, value) =>
										option.value === value.value
									}
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={2}>
								<Autocomplete
									id="subtipoActJuzgado"
									size="small"
									disabled={!valueAutoCompleteTipoActJuzgado?.id }
									value={valueAutoCompleteSubtipoActJuzgado}
									onChange={(event, newValue) => {
										setValueAutoCompleteSubtipoActJuzgado(newValue);
										setSearchData({
											...searchData,
											idSubtipoActJuzgado:
												newValue === null ? "" : newValue.id,
										});
									}}
									inputValue={inputValueAutoCompleteSubtipoActJuzgado}
									onInputChange={(event, newInputValue) => {
										setInputValueAutoCompleteSubtipoActJuzgado(newInputValue);
									}}
									options={subtipoActJuzgado}
									renderInput={(params) => (
										<TextField {...params} label="Subtipo Actuación Juzgado" />
									)}
									isOptionEqualToValue={(option, value) =>
										option.value === value.value
									}
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={2}>
								<FormControl sx={{ width: "100%" }}>
									<InputLabel id="firmas-select-label">Firmas</InputLabel>
									<Select
										sx={{ width: "100%", mr: 1 }}
										labelId="firmas"
										label="Firmas"
										id="firmas"
										name="firmas"
										value={searchData.firmas}
										size="small"
										onChange={handleChange}
									>
										{firmasOptions.map((item) => (
											<MenuItem key={item.id} value={item.id}>
												{item.descripcion}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} md={6} lg={2}>
								<FormControl sx={{ width: "100%" }}>
									<InputLabel id="visibilidad-select-label">Visibilidad</InputLabel>
									<Select
										sx={{ width: "100%", mr: 1 }}
										labelId="visibilidad"
										label="Visibilidad"
										id="visibilidad"
										name="visibilidad"
										value={searchData.visibilidad}
										size="small"
										onChange={handleChange}
									>
										{visibilidadOptions.map((item) => (
											<MenuItem key={item.id} value={item.id}>
												{item.descripcion}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} md={6} lg={2}>
								<FormControl sx={{ width: "100%" }}>
									<InputLabel id="estado-select-label">Estado</InputLabel>
									<Select
										sx={{ width: "100%", mr: 1 }}
										labelId="estado"
										label="Estado"
										id="estado"
										name="estado"
										value={searchData.estado}
										size="small"
										onChange={handleChange}
									>
										{estadoOptions.map((item) => (
											<MenuItem key={item.id} value={item.id}>
												{item.descripcion}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Box>

          <Box sx={{ m: 1, p: 1 }}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={3}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="ordenarPor-select-label">
                    Ordenar por:
                  </InputLabel>
                  <Select
                    sx={{ width: "100%", mr: 1 }}
                    labelId="ordenarPor"
                    label="Ordenar por:"
                    id="ordenaPor"
                    name="ordenarPor"
                    value={searchData.ordenarPor}
                    size="small"
                    onChange={handleChange}
                  >
                    {ordenarPorOptions.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.descripcion}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
							<Grid item xs={12} md={0.5}>
              </Grid>
              <Grid item xs={12} md={4}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <FormControl component="fieldset">
                      <FormLabel
                        component="legend"
                        sx={{
                          textAlign: "center",
                          mb: 1,
                          fontSize: "0.875rem",
                        }}
                      >
                        Orden
                      </FormLabel>
                      <RadioGroup
                        aria-label="Orden"
                        name="orden"
                        value={searchData.orden}
                        onChange={handleChange}
                        row
                      >
                        {ordenOptions
                          .slice(0, Math.ceil(ordenOptions.length / 2))
                          .map((item) => (
                            <FormControlLabel
                              key={item.id}
                              value={item.id}
                              label={item.descripcion}
                              control={<Radio />}
                            />
                          ))}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl component="fieldset">
                      <FormLabel
                        component="legend"
                        sx={{
                          textAlign: "center",
                          mb: 1,
                          fontSize: "0.875rem",
                        }}
                      >
                        &nbsp;
                      </FormLabel>
                      <RadioGroup
                        aria-label="Orden"
                        name="orden"
                        value={searchData.orden}
                        onChange={handleChange}
                        row
                      >
                        {ordenOptions
                          .slice(Math.ceil(ordenOptions.length / 2))
                          .map((item) => (
                            <FormControlLabel
                              key={item.id}
                              value={item.id}
                              label={item.descripcion}
                              control={<Radio />}
                            />
                          ))}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>

					<Box sx={{ m: 1, p: 1 }}>
						<Grid container spacing={1}>
							<Grid item xs={12} md={3} lg={2}>
								<Button
									sx={{ width: "100%" }}
									variant="contained"
									onClick={() => handleClickActionCrud({}, "create")}
									startIcon={<AddIcon />}
								>
									Nuevo
								</Button>
							</Grid>
							<Grid item xs={12} md={3} lg={2}>
								<Button
									sx={{ width: "100%" }}
									variant="contained"
									onClick={search}
									startIcon={<SearchIcon />}
								>
									Buscar
								</Button>
							</Grid>
							<Grid item xs={12} md={3} lg={2}>
								<Button
									sx={{ width: "100%", mr: 1 }}
									variant="contained"
									onClick={clean}
									startIcon={<CleaningServicesIcon />}
								>
									Limpiar
								</Button>
							</Grid>
						</Grid>
					</Box>

          <Box sx={{ m: 1, p: 1 }}>
          </Box>
            {isDoingSomething && <LinearProgress />} 
					<ThemeProvider theme={darkTheme}>
						<MUIDataTable columns={tableColumns} data={itemsCrud} options={tableOptions} />
					</ThemeProvider>	
				</Box>
			</Paper>

			{/* >>>> Dialog */}

			{openDialog && (
				<FueroSubtipoActJuzgadoDialog
					fueros={fueros}
					tipoActJuzgado={tipoActJuzgado}
					funciones={funciones}
					subtipoActJuzgado={subtipoActJuzgado}	
					onSave={handleDialogSave}
					onClose={handleDialogClose}
					itemCrud={itemCrud}
					actionCrud={actionCrud}
					setMessage={setMessage}
					setShowMessage={setShowMessage}
				/>
			)}

			{/* >>>> Messages */}

			{showMessage && (
				<Message
					showMessage={showMessage}
					setShowMessage={setShowMessage}
					message={message}
				/>
			)}
		</>
	);
};

export default FueroSubtipoActJuzgadoTable;
