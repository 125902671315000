import axios from "axios";
import { urlBackendPDFBuilder } from "../configs/config";
import { urlBackendUsuarios } from "../configs/config";
import keycloakObject from "../utils/keycloak";

const epName = "/organismo-area/";
const epPDFBuilderName = "/genera_pdf/";
const nameReporte = "Organismos-Areas";
const nameItemsText = "OrganismosAreas";
const nameItemText = "Organismo-Areas";
const itemGender = "M";
const elLa = itemGender === "M" ? "el" : "la";
// const unUna = itemGender === "M" ? "un" : "una";
//
// #############################################################################
//
export const getOrganismosAreas = async (params) => {
  let _params = {};
  let _errorMessage = `Se produjo un error al obtener ${nameItemsText}`;

  if (params.idFuero) {
    _params.id_fuero = params.idFuero;
  }

  if (params.idOrganismo) {
    _params.id_organismo = params.idOrganismo;
  }

	if (params.circunscripcionJudicial) {
		_params.circunscripcion_judicial_organismo = params.circunscripcionJudicial;
	}

  if (params.idArea) {
    _params.id_area = params.idArea;
  }

  if (params.estado) {
    let estado =
      params.estado === "habilitado"
        ? "H"
        : params.estado === "deshabilitado"
        ? "D"
        : "";
    _params.habilitado = estado;
  }

  if (params.rows) {
    _params.rows = params.rows;
  }

  if (params.first) {
    _params.first = params.first;
  }

  if (params.sortField) {
		_params.sortfield = params.sortField;
	}

	if (params.sortOrder) {
		_params.sortorder = params.sortOrder;
	}

  try {
    const response = await axios({
      method: "GET",
      url: `${urlBackendUsuarios}${epName}`,
      params: _params,
      headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
    });

    if (response.status === 200) {
      let _error = "error_code" in response.data;
      if (!_error) {
        // si no hay error controlado
        response.ok = true;
        return response;
      }
    }

    // si hay algun error controlado

    console.log(_errorMessage);
    console.log(response);

    response.ok = false;
    response.messageText = _errorMessage;
    response.messageSeverity = "error";
    return response;
  } catch (error) {
    console.log(_errorMessage);
    if (error.response) {
      // The request was made and the server responded with a status code that falls out of the range of 2xx
      console.log(error.response);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(error);
    }
    let response = {};
    response.ok = false;
    response.messageText = _errorMessage;
    response.messageSeverity = "error";
    return response;
  }
};
//
// #############################################################################
//
export const getOrganismoArea = async (params) => {
  let _errorMessage = `Se produjo un error al obtener ${elLa} ${nameItemText}`;
  try {
    const response = await axios({
      method: "GET",
      url: `${urlBackendUsuarios}${epName}`,
      params: params,
      headers: { Authorization: `Bearer ${keycloakObject.getToken()}` },
    });

    if (response.status === 200) {
      let _error = "error_code" in response.data;
      if (!_error) {
        // si no hay error controlado
        response.ok = true;
        return response;
      }
    }

    // si hay algun error controlado

    console.log(_errorMessage);
    console.log(response);

    response.ok = false;
    response.messageText = _errorMessage;
    response.messageSeverity = "error";
    return response;
  } catch (error) {
    console.log(_errorMessage);
    if (error.response) {
      // The request was made and the server responded with a status code that falls out of the range of 2xx
      console.log(error.response);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(error);
    }
    let response = {};
    response.ok = false;
    response.messageText = _errorMessage;
    response.messageSeverity = "error";
    return response;
  }
};
//
// #############################################################################
//
export const saveOrganismoArea = async (actionCrud, item) => {
  let _errorMessage = `No fue posible guardar ${elLa} ${nameItemText}`;

  let _item = {
    id_fuero: item.idFuero,
    id_organismo: item.idOrganismo,
    id_area: item.idArea,
    observaciones: item.observaciones,
    username_actualiza: item.usernameUsuarioActualiza,
  };
  if (actionCrud !== "create") {
    _item.id = item.id;
  }

  try {
    let response = {};
    if (actionCrud === "create") {
      response = await axios({
        method: "POST",
        url: `${urlBackendUsuarios}${epName}`,
        data: _item,
        headers: { Authorization: `Bearer ${keycloakObject.getToken()}` },
      });
    } else if (actionCrud === "update") {
      response = await axios({
        method: "PUT",
        url: `${urlBackendUsuarios}${epName}`,
        data: _item,
        headers: { Authorization: `Bearer ${keycloakObject.getToken()}` },
      });
    } else if (actionCrud === "disable" || actionCrud === "enable") {
      response = await axios({
        method: "DELETE",
        url: `${urlBackendUsuarios}${epName}`,
        params: {
          id: _item.id,
          username_actualiza: _item.username_actualiza,
          action: actionCrud,
        },
        headers: { Authorization: `Bearer ${keycloakObject.getToken()}` },
      });
    }

    if (response.status === 200) {
      console.log(response.data);
      let _error = "error_code" in response.data;
      if (!_error) {
        // si no hay error controlado
        response.ok = true;
        response.messageText =
          `${nameItemText}` +
          (actionCrud === "disable"
            ? itemGender === "M"
              ? " deshabilitado"
              : " deshabilitada"
            : actionCrud === "enable"
            ? itemGender === "M"
              ? " habilitado"
              : " habilitada"
            : itemGender === "M"
            ? " guardado"
            : " guardada");
        response.messageSeverity = "success";
      } else {
        // si hay error controlado
        console.log(_errorMessage);
        console.log(response);

        let _message_code = response.data.message_code;
        if (_message_code === "err-organismo-area-already-exist") {
          response.messageText = `Ya existe ${elLa} ${nameItemText}. Verifique los inhabilitados`;
        } else if (_message_code === "err-organismo-area") {
          response.messageText = `El Organismo no corresponde a ese Fuero`;
        } else {
          response.messageText = _errorMessage;
        }
        response.ok = false;
        response.messageSeverity = "error";
      }
      return response;
    }
  } catch (error) {
    console.log(_errorMessage);
    if (error.response) {
      // The request was made and the server responded with a status code that falls out of the range of 2xx
      console.log(error.response);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(error);
    }
    let response = {};
    response.ok = false;
    response.messageText = _errorMessage;
    response.messageSeverity = "error";
    return response;
  }
};
//
// #############################################################################
//
export const generarPDFOrganismosAreas = async (params) => {
  let _params = {};
  let _errorMessage = `Se produjo un error al obtener ${nameItemsText}`;

  if (params.idFuero) {
    _params.id_fuero = params.idFuero;
  }

  if (params.idOrganismo) {
    _params.id_organismo = params.idOrganismo;
  }

  if (params.idArea) {
    _params.id_area = params.idArea;
  }

  if (params.estado) {
    let estado =
      params.estado === "habilitado"
        ? "H"
        : params.estado === "deshabilitado"
        ? "D"
        : "";
    _params.habilitado = estado;
  }

  if (params.sortField) {
		_params.sortfield = params.sortField;
	}

	if (params.sortOrder) {
		_params.sortorder = params.sortOrder;
	}

  try {
    const response = await axios({
      method: "GET",
      url: `${urlBackendUsuarios}${epName}`,
      params: _params,
      headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
    });

    if (response.status === 200) {
      let _error = "error_code" in response.data;
      if (!_error) {
        // si no hay error controlado
        let contenido_json = [];
        contenido_json.push({ data: response.data.data });
        response.ok = true;

        let _paramsPDF = {};
        _paramsPDF.tipo = "pdf";
        _paramsPDF.sistema = "usher";
        _paramsPDF.codigo_reporte = nameReporte;

        const responsePDFBuilder = await axios({
          method: "PUT",
          url: `${urlBackendPDFBuilder}${epPDFBuilderName}`,
          params: _paramsPDF,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("Token")}`,
            "Content-Type": "application/json",
          },
          data: { contenido_json: contenido_json },
          responseType: "arraybuffer",
        });

        if (responsePDFBuilder.status === 200) {
          if (
            responsePDFBuilder.headers["content-type"] === "application/json"
          ) {
            let _error = "error_code" in responsePDFBuilder.data;
console.log(_error);          } else {
            // Crear un objeto Blob a partir de la respuesta
            const blob = new Blob([responsePDFBuilder.data], {
              type: "application/pdf",
            });

            // Crear una URL de objeto para el Blob
            const url = window.URL.createObjectURL(blob);

            // Obtener la fecha y hora actual para nombrar el archivo
            const fechaHoraActual = new Date();
            const formatoFechaHora = `${fechaHoraActual.getFullYear()}${(
              fechaHoraActual.getMonth() + 1
            )
              .toString()
              .padStart(2, "0")}${fechaHoraActual
              .getDate()
              .toString()
              .padStart(2, "0")}_${fechaHoraActual
              .getHours()
              .toString()
              .padStart(2, "0")}${fechaHoraActual
              .getMinutes()
              .toString()
              .padStart(2, "0")}`;

            // Crear un enlace y simular clic para descargar
            const a = document.createElement("a");
            a.href = url;
            a.download = `${_paramsPDF.sistema}_${_paramsPDF.codigo_reporte}-${formatoFechaHora}.pdf`;
            a.click();

            // Liberar el objeto URL
            window.URL.revokeObjectURL(url);

            responsePDFBuilder.ok = true;
            return responsePDFBuilder;
          }
        }

        // si hay algun error controlado

        console.log(_errorMessage);
        console.log(responsePDFBuilder);

        responsePDFBuilder.ok = false;
        responsePDFBuilder.messageText = _errorMessage;
        responsePDFBuilder.messageSeverity = "error";
        return responsePDFBuilder;
      }
    }

    // si hay algun error controlado

    console.log(_errorMessage);
    console.log(response);

    response.ok = false;
    response.messageText = _errorMessage;
    response.messageSeverity = "error";
    return response;
  } catch (error) {
    console.log(_errorMessage);
    if (error.response) {
      // The request was made and the server responded with a status code that falls out of the range of 2xx
      console.log(error.response);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(error);
    }
    let response = {};
    response.ok = false;
    response.messageText = _errorMessage;
    response.messageSeverity = "error";
    return response;
  }
};
