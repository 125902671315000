import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Logo from "./assets/escudoDueTone.png";
import LogoUsher from "./assets/logo.png";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import keycloakObject from "../utils/keycloak";
import { useMenu } from "../context/MenuContext.js";


const _fotoPerfil = localStorage.getItem("fotoUsuario");



const drawerWidth = 340;
const settings = [/*'Profile', 'Account', 'Dashboard',*/ 'Logout'];

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function MiniDrawer(currentPages) {
  //const { menuOpen, setMenuOpen } = useMenu();
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleMenu = (e) => {
    let navlink = e.target.innerText
    if (navlink === 'Logout') {
      logout()
    }
    handleCloseUserMenu()
  };

  const logout = () => {
    keycloakObject.doLogout()
  }

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const { menuOpen, setMenuOpen } = useMenu(false);

  const [pages, setPages] = React.useState(currentPages.currentPages);

  const handleDrawerOpen = () => {
    setOpen(true);
    setMenuOpen(true); // Abrir el menú lateral
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setMenuOpen(false); // Abrir el menú lateral
  };
  const [expanded, setExpanded] = React.useState("panel0");

  const handleChange = (index) => (event, isExpanded) => {
    setExpanded(isExpanded ? index : "");
  };
  const onClickOpen = (index) => (event, isExpanded) => {
  };
  return (
    <Box sx={{ display: 'flex', backgroundcolor: 'rgba(255, 255, 255, 0.02)' }} >
      <CssBaseline />
      <AppBar position="fixed" open={open} sx={{ backgroundcolor: 'rgba(255, 255, 255, 0.02)' }}>
        <Toolbar
          sx={{ display: 'flex' }}
        >
          <IconButton
            color="inherit"
            backgroundcolor='rgba(255, 255, 255, 0.02)'
            aria-label="Abrir panel de navegación lateral"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 2,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', marginRight: 2 }}>
            <Box sx={{ display: 'flex' }}>
              <Typography variant="h3" noWrap component="div"
                sx={{ color: 'rgba(255, 255, 255, 0.5)', fontWeight: 'light', marginLeft: 1 }} 
              >
                Usher
              </Typography>
              <Tooltip title="Versión 1.2.0">
              <Box
                component="img"
                sx={{
                  height: 50,
                  marginRight: 2,
                  marginLeft: 1
                }}
                alt="Logo de la aplicación representado por gafas de realidad virtual"
                src={LogoUsher}                
              />              
            </Tooltip>
            </Box>
            <Box component="div" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center'}} >
              <Typography variant="h8" component="h1" noWrap
                sx={{ color: 'rgba(255, 255, 255, 0.4)', fontWeight: 'light', textTransform: 'uppercase', flexGrow: 1 }}
              >
                Poder Judicial de Mendoza
              </Typography>
              <Box
                component="img"
                sx={{
                  width: 22,
                  opacity: 0.5,
                  marginLeft: 1
                }}
                alt="Escudo de la Provincia de Mendoza"
                src={Logo}
              />
            </Box>
          </Box>

          <Box sx={{ flexGrow: 0, backgroundcolor: 'rgba(255, 255, 255, 0.02)' }}>
            <Tooltip title="Abrir menú usuario">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <img 
                src={_fotoPerfil} 
                alt="Icono de usuario" 
                style={{ width: '36px', height: '36px', borderRadius: '50%' }}
                />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={Math.random() * Date.now()} value={setting} name={setting} onClick={handleMenu}>
                  <Typography textAlign="center" >{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon titleAccess="Abrir panel de navegación lateral" /> : <ChevronLeftIcon titleAccess="Cerrar panel de navegación lateral" />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {pages.map((item, index) => item.section ? (
            < Accordion key={Math.random() * Date.now()} expanded={expanded === "panel" + index}  sx={{ display: 'block', p:0 }} onChange={handleChange("panel" + index)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: expanded === "panel" + index ? '#15BF81' : "#ffff" }} />}
                aria-controls={"panel" + index + "bh-content"}
                id={"panel" + index + "bh-header"}
                sx={{
                  minHeight: 48,
                  justifyContent: 'initial',
                  px: 2.5,
                  color: expanded === "panel" + index && !open ? '#15BF81' : "#ffff"
                }}

              >

                <ListItemIcon
                  id={"panel" + index + "bh-icon"}
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                  onChange={handleChange("panel" + index)} onClick={onClickOpen(item.page)}
                  expanded={expanded === "panel" + index}
                >

                  {
                    expanded === "panel" + index && open
                      ?
                      undefined
                      :
                      <item.Icon href={item.url} titleAccess={item.page}  onChange={handleChange("panel" + index)} sx={{ color: expanded === "panel" + index && !open ? '#15BF81' : "#ffff", p:0 }} />
                  }


                </ListItemIcon>
                <Typography sx={{ width: '33%', flexShrink: 0, color: expanded === "panel" + index ? '#15BF81' : "#ffff", fontWeight: expanded === "panel" + index ? 'lighter' : "inherit", fontSize: expanded === "panel" + index ? '22px' : "inherit" }} >
                  {item.page}
                </Typography>
              </AccordionSummary>
              {item.section?.map((section, index) => (
                <ListItem key={Math.random() * Date.now()}  sx={{ display: 'block', p:0  }} >
                  <ListItemButton
                  component="a" href={section.url}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      < section.Icon titleAccess={section.page} role="img" variant="contained" />

                    </ListItemIcon>
                    <ListItemText primary={section.page} sx={{ opacity: open ? 1 : 0, color: "#f2eeed" }} />
                  </ListItemButton>
                </ListItem>
              ))}
            </Accordion>

          ) : (

            <ListItem key={Math.random() * Date.now()}  sx={{ display: 'block', p:0 }} >
              <ListItemButton
              component="a" href={item.url}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  < item.Icon titleAccess={item.page} role="img" variant="contained" />

                </ListItemIcon>
                <ListItemText primary={item.page} sx={{ opacity: open ? 1 : 0, color: "#f2eeed" }} />
              </ListItemButton>
            </ListItem>


          ))}
        </List>
      </Drawer>

    </Box >
  );
}
