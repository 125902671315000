import * as React from 'react';
import { Tooltip } from "@mui/material";
import Box from '@mui/material/Box';
import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import homeData from "./homeData.json";
import Button from '@mui/material/Button';




export default function FAQ() {
    const [expanded, setExpanded] = React.useState('panel0');
    const [isAdmin, setIsAdmin] = React.useState(false);

    const handleChange = (index) => (event, isExpanded) => {
        setExpanded(isExpanded ? index : "");
    };
    //console.log(expanded)


    return (
        <>
            {isAdmin ? (
                <Box sx={{ display: 'flex' }}>
                    <Typography variant="h4" noWrap component="h2"
                        sx={{ flex: 1, color: 'rgba(255, 255, 255, 0.5)', fontWeight: 'light', mb: 1 }}
                    >
                        Preguntas frecuentes
                    </Typography>
                    <Tooltip title="Editar">
                        <IconButton
                            size="small"
                            aria-label="update"
                        /*onClick={() => handleClickActionCrud(itemsCrud[dataIndex], "u pdate")}*/
                        >
                            <EditOutlinedIcon />
                        </IconButton>
                    </Tooltip>


                </Box>
            ) : (
                <Typography variant="h4" noWrap component="h2"
                    sx={{ flex: 1, color: 'rgba(255, 255, 255, 0.5)', fontWeight: 'light', mb: 1 }}
                >
                    Preguntas frecuentes
                </Typography>
            )}

            {homeData.faq.map((faq, index) => (
                <Accordion key={Math.random() * Date.now()} expanded={expanded === "panel" + index } onChange={handleChange("panel" + index)} component="article">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={"panel" + index + "bh-content"}
                        id={"panel" + index + "bh-header"}
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }} component="h3">
                            {faq.titulo}
                        </Typography>
                        <Typography sx={{ color: 'text.secondary' }}></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {faq.contenido}
                        </Typography>
                    </AccordionDetails>
                    <Button sx={{ m: 1 }} href={faq.url}>----</Button>
                </Accordion>
            ))}
        </>
    );
}
