import axios from "axios";
import { urlBackendUsuarios } from "../configs/config";
//import jwt_decode from "jwt-decode";

const epName = "/all_info/";
// const nameItemsText = "Areas";
const nameItemText = "Info";
const itemGender = "F";
const elLa = itemGender === "M" ? "el" : "la";
//const unUna = itemGender === "M" ? "un" : "una";


//
// #############################################################################
//
export const getAllinfo = async (params) => {
	let _errorMessage = `Se produjo un error al obtener ${elLa} ${nameItemText}`;
	try {
		const response = await axios({
			method: "GET",
      url: `${urlBackendUsuarios}${epName}`,
			params: params,
			headers: {'Authorization': `Bearer ${localStorage.getItem('Token')}`},
		});

		if (response.status === 200) {
			let _error = "error_code" in response.data;
			if (!_error) {
				// si no hay error controlado
				response.ok = true;
				return response;
			}
		}

		// si hay algun error controlado

		console.log(_errorMessage);
		console.log(response);

		response.ok = false;
		response.messageText = _errorMessage;
    response.messageSeverity = "error";
		return response;
	} catch (error) {
		console.log(_errorMessage);
		if (error.response) {
			// The request was made and the server responded with a status code that falls out of the range of 2xx
			console.log(error.response);
		} else if (error.request) {
			// The request was made but no response was received
			// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
			// http.ClientRequest in node.js
			console.log(error.request);
		} else {
			// Something happened in setting up the request that triggered an Error
			console.log(error);
		}
		let response = {};
		response.ok = false;
		response.messageText = _errorMessage;
    response.messageSeverity = "error";
		return response;
	}
};
