import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useEffect, useState } from "react";
import { getFueroTiposIncidentes } from "../../services/FueroTipoIncidenteService";
import { getEtapasProcesales } from "../../services/EtapaProcesalService";
import { saveFueroTipoIncidenteEtapaProcesal } from "../../services/FueroTipoIncidenteEtapaProcesalService"; 
import darkTheme from "../../layout/DarkTheme";

const initialState = {
  observaciones: "",
};


const itemGender = "M";
const nameItemText = "Fuero Tipo Incidente - Etapa Procesal";

const FueroTipoIncidenteEtapaProcesalDialog = ({
  fueros,
  onSave,
  onClose,
  itemCrud,
  actionCrud,
  setMessage,
  setShowMessage,
}) => {
  const [itemCrudLocal, setItemCrudLocal] = useState(initialState);
  const [isDoingSomething, setIsDoingSomething] = useState(false);
  const [fueroTipoIncidentesLocal, setFueroTipoIncidentesLocal] = useState([]);
  const [etapasProcesalesLocal, setEtapasProcesalesLocal] = useState([]);
  const [valueAutoCompleteFueros, setValueAutoCompleteFueros] = useState(itemCrud.fuero);
  const [inputValueAutoCompleteFueros, setInputValueAutoCompleteFueros] = useState("");
  const [valueAutoCompleteFueroTipoIncidentes, setValueAutoCompleteFueroTipoIncidentes] = useState(itemCrud.fueroTipoIncidente);
  const [inputValueAutoCompleteFueroTipoIncidentes, setInputValueAutoCompleteFueroTipoIncidentes] = useState("");
  const [valueAutoCompleteEtapasProcesales, setValueAutoCompleteEtapasProcesales] = useState(itemCrud.etapaProcesal);
  const [inputValueAutoCompleteEtapasProcesales, setInputValueAutoCompleteEtapasProcesales] = useState("");

  useEffect(() => {
    if (actionCrud === "create") {
      setValueAutoCompleteFueros("");
      setItemCrudLocal(initialState);
    } else {
      setValueAutoCompleteFueros(itemCrud.fuero);
      setValueAutoCompleteFueroTipoIncidentes(itemCrud.fueroTipoIncidente);
      setValueAutoCompleteEtapasProcesales(itemCrud.etapaProcesal);
      setItemCrudLocal(itemCrud);
      loadFueroTipoIncidentes(itemCrud.fuero);
      loadEtapasProcesales();
    }
  }, []);

  const handleCloseDialog = () => {
    onClose();
  };

  const handleChange = (e) => {
    setItemCrudLocal({
      ...itemCrudLocal,
      [e.target.name]: e.target.value,
    });
  };

  const handleFueroChange = (event, newValue) => {
    setValueAutoCompleteFueros(newValue);
    setValueAutoCompleteFueroTipoIncidentes("");
    setValueAutoCompleteEtapasProcesales("");
    loadFueroTipoIncidentes(newValue);
		loadEtapasProcesales();
  };

  const handleSave = () => {
    if (actionCrud === "create" || actionCrud === "update") {
      if (inputValueAutoCompleteFueros === "") {
        setMessage({
          severity: "error",
          messageText: "Debes seleccionar un Fuero",
        });
        setShowMessage(true);
        return;
      }

      if (inputValueAutoCompleteFueroTipoIncidentes === "") {
        setMessage({
          severity: "error",
          messageText: "Debes seleccionar un Fuero Tipo Incidente",
        });
        setShowMessage(true);
        return;
      }

      if (inputValueAutoCompleteEtapasProcesales === "") {
        setMessage({
          severity: "error",
          messageText: "Debes seleccionar una Etapa Procesal",
        });
        setShowMessage(true);
        return;
      }

      if (itemCrudLocal.orden === "" || itemCrudLocal.orden === undefined) {
        setMessage({
          severity: "error",
          messageText: "Debes ingresar un Orden",
        });
        setShowMessage(true);
        return;
      }
    }
    save();
  };

  const save = async () => {

    let _itemCrud = {
      idFuero: valueAutoCompleteFueros?.id,
      idFueroTipoIncidente: valueAutoCompleteFueroTipoIncidentes?.id,
      idEtapaProcesal: valueAutoCompleteEtapasProcesales?.id,
      orden: itemCrudLocal.orden,
      observaciones: itemCrudLocal?.observaciones,
      usernameUsuarioActualiza: localStorage.getItem("emailUsuario"),
    };
    if (actionCrud !== "create") {
      _itemCrud.id = itemCrud.id;
    }
    setIsDoingSomething(true);
    const response = await saveFueroTipoIncidenteEtapaProcesal(actionCrud, _itemCrud);
    setIsDoingSomething(false);

    if (response.ok) {
      onSave();
    }

    setMessage({
      messageText: response.messageText,
      severity: response.messageSeverity,
    });
    setShowMessage(true);
  };

  const loadFueroTipoIncidentes = async (fuero) => {
    let _params = {
      idFuero: fuero?.id || valueAutoCompleteFueros?.id,
    };
    setIsDoingSomething(true);

    const response = await getFueroTiposIncidentes(_params);
    setIsDoingSomething(false);

    if (response.ok) {
			let _response = response.data.data[0].tipo_incidente;
      let _fueroTipoIncidentes = [];
      _response.forEach((item) => {
        if (item.asignado) {
          _fueroTipoIncidentes.push({
            id: item.id_fuero_tipo_incidente,
            id_tipo_incidente: item.id_tipo_incidente,
            label: item.descripcion_tipo_incidente,
          });
        }
      });
      setFueroTipoIncidentesLocal(_fueroTipoIncidentes);
    } else {
      setFueroTipoIncidentesLocal([]);
      setMessage({
        messageText: response.messageText,
        severity: response.messageSeverity,
      });
      setShowMessage(true);
    }
  };

  const loadEtapasProcesales = async () => {
    let _params = {
      estado: 'habilitado',
    };
    setIsDoingSomething(true);
    const response = await getEtapasProcesales(_params);
    setIsDoingSomething(false);
    if (response.ok) {
      let _response = response.data.data;
      let _etapas_procesales = [];
      _response.forEach((item) => {
        _etapas_procesales.push({
          id: item.id,
          label: item.descripcion,
        });
      });
      setEtapasProcesalesLocal(_etapas_procesales);
    } else {
      setEtapasProcesalesLocal([]);
      setMessage({
        messageText: response.messageText,
        severity: response.messageSeverity,
      });
      setShowMessage(true);
    }
  };

  return (
    <>
      <Dialog
        open={true}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth={true}
        scroll="paper"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title"
          textAlign={{ xs: "center", sm: "center" }}
          style={{ background: darkTheme.palette.primary.dark, color: darkTheme.palette.primary.text}}
        >
          {actionCrud === "create"
            ? itemGender === "M"
              ? "Nuevo"
              : "Nueva"
            : actionCrud === "update"
              ? "Editando"
              : actionCrud === "enable"
                ? "Habilitando"
                : actionCrud === "disable"
                  ? "Deshabilitando"
                  : actionCrud === "read"
                    ? "Detalle"
                    : ""}{" "}
          {nameItemText}
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        {isDoingSomething && <LinearProgress />}

        <DialogContent dividers>
          <Box sx={{ m: 1, p: 1 }}>
            <Autocomplete
              id="fuero"
              sx={{ width: "100%", pb: 1 }}
              size="small"
              value={valueAutoCompleteFueros}
              onChange={handleFueroChange}
              inputValue={inputValueAutoCompleteFueros}
              onInputChange={(event, newInputValue) => {
                setInputValueAutoCompleteFueros(newInputValue);
              }}
              options={fueros}
              renderInput={(params) => (
                <TextField {...params} label="Fuero" />
              )}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
              disableClearable={true}
            />

            <Autocomplete
              id="fueroTipoIncidente"
              sx={{ width: "100%", pb: 1 }}
              size="small"
              value={valueAutoCompleteFueroTipoIncidentes}
              onChange={(event, newValue) => {
                setValueAutoCompleteFueroTipoIncidentes(newValue);
                setValueAutoCompleteEtapasProcesales("");
              }}
              inputValue={inputValueAutoCompleteFueroTipoIncidentes}
              onInputChange={(event, newInputValue) => {
                setInputValueAutoCompleteFueroTipoIncidentes(newInputValue);
              }}
              options={fueroTipoIncidentesLocal}
              renderInput={(params) => (
                <TextField {...params} label="Tipo Incidente" />
              )}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read" || !valueAutoCompleteFueros?.id }
            />

            <Autocomplete
              id="etapaProcesal"
              sx={{ width: "100%", pb: 1 }}
              size="small"
              value={valueAutoCompleteEtapasProcesales}
              onChange={(event, newValue) => {
                setValueAutoCompleteEtapasProcesales(newValue);
              }}
              inputValue={inputValueAutoCompleteEtapasProcesales}
              onInputChange={(event, newInputValue) => {
                setInputValueAutoCompleteEtapasProcesales(newInputValue);
              }}
              options={etapasProcesalesLocal}
              renderInput={(params) => (
                <TextField {...params} label="Etapa Procesal" />
              )}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
            />

            <TextField
              variant="outlined"
              size="small"
              name="orden"
              label="Orden"
              value={itemCrudLocal.orden}
              onChange={handleChange}
              disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
              sx={{ width: "100%", pb: 1 }}
            />

            <TextField
              variant="outlined"
              size="small"
              name="observaciones"
              label="Observaciones"
              value={itemCrudLocal.observaciones}
              onChange={handleChange}
              disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
              sx={{ width: "100%", pb: 1 }}
              multiline
              rows={4}
            />

            {actionCrud !== "create" && (
              <Box
                sx={{
                  p: 1,
                  mt: 1,
                  color: "SlateGrey",
                  border: "0px solid DarkGray",
                  borderRadius: "3px",
                }}
              >
                <Typography variant="h8" component="div" gutterBottom>
                  Última actualización: {itemCrudLocal.fechaActualizacion}
                </Typography>

                <Typography variant="h8" component="div" gutterBottom>
                  Último usuario que actualizó: {itemCrudLocal.nombreUsuario}{" "}
                  {itemCrudLocal.apellidoUsuario} ({itemCrudLocal.username})
                </Typography>
              </Box>
            )}
          </Box>

          <Box sx={{ textAlign: "right" }}>
            <Button
              sx={{ mr: 1 }}
              variant="outlined"
              onClick={handleCloseDialog}
              startIcon={<CloseIcon />}
            >
              Cancelar
            </Button>

            {(actionCrud !== "read") && (
              <Button
                sx={{ mr: 1, backgroundcolor: "warning" }}
                variant="contained"
                onClick={handleSave}
                startIcon={
                  actionCrud === "disable" ? (
                    <RemoveCircleOutlineIcon />
                  ) : actionCrud === "enable" ? (
                    <AddCircleOutlineIcon />
                  ) : (
                    <SaveIcon />
                  )
                }
              >
                {actionCrud === "disable"
                  ? "Deshabilitar"
                  : actionCrud === "enable"
                    ? "Habilitar"
                    : "Guardar"}
              </Button>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FueroTipoIncidenteEtapaProcesalDialog;
