import {
	Autocomplete,
	Button,
	Grid,
	LinearProgress,
	Paper,
	TextField,
	Tooltip,
	ThemeProvider,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import { useEffect, useState } from "react";
import { getFueros } from "../../services/FueroService.js";
import FueroTipoActParteDialog from "./FueroTipoActParteDialog";
import Message from "../../utils/Message.jsx";
import { Box } from "@mui/system";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import darkTheme from "../../layout/DarkTheme.jsx";
import React from 'react';
import { useMenu } from "../../context/MenuContext.js";
import { getFueroTiposActPartes } from "../../services/FueroTipoActParteService.js";

const initialSearchData = {
	id_SistemaRol: ""
};


const nameItemsText = "Asignación de Tipo de Actuación de Parte a Fuero";
//
// ##################################################################################################################### 
//
const FueroTipoActParteTable = (data) => {
	const [searchData, setSearchData] = useState(initialSearchData);
	const [itemsCrud, setItemsCrud] = useState([]);
	const [itemCrud, setItemCrud] = useState({});
	const [actionCrud, setActionCrud] = useState("");
	const [isDoingSomething, setIsDoingSomething] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const [totalRowsCount, setTotalRowsCount] = useState(0);
	const [currentPage, setCurrentPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [message, setMessage] = useState({});
	const [showMessage, setShowMessage] = useState(false);
	const [Fueros, setFueros] = useState([]);
	const [valueAutoCompleteFueros, setValueAutoCompleteFueros] = useState({
		id: "",
		label: "",
	});
	const [inputValueAutoCompleteFueros, setInputValueAutoCompleteFueros] =
		useState("");
	const { menuOpen } = useMenu();

	const tableStyles = {
		width: menuOpen ?  "80%": "95%", 
		height: "90%",
		overflow: "auto",
		p: 1,
		margin: "auto",
		position: 'fixed', 
		top: "8%", 
		left: menuOpen ? '15%' : '0%', 
		transition: 'left 0.3s ease' 
	};
	
	const tableColumns = [
		{
			name: "id",
			label: "ID",
			options: {
				display: "excluded",
			},
		},
		{
			name: "descripcion_fuero",
			label: "Fuero",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					const habilitadoFuero = itemsCrud[tableMeta.rowIndex].habilitado_fuero ? "" : "(Deshabilitado)";
					return `${value} ${habilitadoFuero}`;
				},
				
		},
		},

		{
			name: "tipo_act_parte_asignados",
			label: "Tipos Actuación Parte asignados",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			name: "actions", 
			label: "Acciones",
			options: {
				filter: false,
				sort: false,
				empty: true,
				customBodyRenderLite: (dataIndex, rowIndex) => (
						<>
						<Tooltip title="Editar">
							<IconButton
								size="small"
								aria-label="update"
								onClick={() =>
									handleClickActionCrud(itemsCrud[dataIndex], "update")
								}
							>
								<EditOutlinedIcon />
							</IconButton>
						</Tooltip>
						<Tooltip title="Ver">
							<IconButton
								size="small"
								aria-label="read"
								onClick={() =>
									handleClickActionCrud(itemsCrud[dataIndex], "read")
								}
							>
								<ZoomInIcon />
							</IconButton>
						</Tooltip>
						</>
				
				),
			},
		},
	];

	const tableOptions = {
		storageKey: "my-table-state",
		rowsPerPageOptions: [10, 20, 30],
		serverSide: true,
		count: totalRowsCount,
		rowsPerPage: rowsPerPage,
		onChangeRowsPerPage: (numberOfRows) => {
			setRowsPerPage(numberOfRows);
		},
		onChangePage: (currentPage) => {
			setCurrentPage(currentPage);
		},
		download: false,
		filter: false,
		print: false,
		search: false,
		viewColumns: false,
		selectableRowsHeader: false,
		selectableRows: "none",
		elevation: 5,
		textLabels: {
			body: {
				noMatch: "No se han encontrado resultados para esta búsqueda",
			},
			pagination: {
				next: "Próxima",
				previous: "Previa",
				rowsPerPage: "Filas:",
				displayRows: "de",
			},
		},
	};
	//
	// ################################################################################
	//
	useEffect(() => {
		loadFueros();
	}, [currentPage, rowsPerPage]);

	// ################################################################################
	//
	const handleLimpiar = () => {

		setValueAutoCompleteFueros({
			id: "",
			label: "",
		});
		setInputValueAutoCompleteFueros("");
		setSearchData(initialSearchData);
		setItemsCrud([]);
	};
	//
	// ################################################################################
	//
	const handleBuscar = () => {
		setCurrentPage(0);
		load();
		loadFueros()
	};
	//
	// ################################################################################
	//
	const loadFueros = async () => {
		let _params = {
			idFuero: valueAutoCompleteFueros?.id ? valueAutoCompleteFueros.id : null,
			habilitado: 'todo',
			rows: rowsPerPage,
			first: currentPage * rowsPerPage,
		};

		setIsDoingSomething(true);
		const response = await getFueroTiposActPartes(_params);
		setIsDoingSomething(false);
		if (response.ok) {
			setTotalRowsCount(response.data.count);
			setItemsCrud(response.data.data);
		} else {
			setMessage({
				messageText: response.messageText,
				severity: response.messageSeverity,
			});
			setShowMessage(true);
		}
	};
	//
	// ################################################################################
	//

	useEffect(() => {	
		load()
	}, []);
	
	const load = async () => {
		setIsDoingSomething(true);
		const responseFueros = await getFueros( { estado: "todo" } );
		setIsDoingSomething(false);

		if (responseFueros.ok) {
			let _responseFueros = responseFueros.data.data;
			let _Fueros = [];

			_responseFueros.forEach((item) => {
				_Fueros.push({
					id: item.id,
					label: item.descripcion,
				});
			});
			setFueros(_Fueros);
		} else {
			setFueros([]);
			setMessage({
				messageText: responseFueros.messageText,
				severity: responseFueros.messageSeverity,
			});
			setShowMessage(true);
		}
		
	};
	//
	// ################################################################################
	//
	const handleClickActionCrud = (itemCrud, action) => {
		let _itemCrud = {};

		if (action !== "create") {
			const loadItem = async () => {
				setIsDoingSomething(true);
				let params = {
					idFuero: itemCrud.id_fuero,
					descripcionFuero: itemCrud.descripcion_fuero,
					fueroHabilitado: itemCrud.habilitado_fuero,
					tipoActParteAsignados: itemCrud.tipo_act_parte_asignados,
					}
				
				const response = await getFueroTiposActPartes(params);
				setIsDoingSomething(false);
				if (response.ok) {
					let _response = response.data.data[0]

					_itemCrud = {
						idFuero: _response.id_fuero,
						descripcionFuero: _response.descripcion_fuero,
						habilitadoFuero: _response.habilitado_fuero,
						tipoActParteList: [..._response.tipo_act_parte],
						fechaActualizacion: response.data.data.fecha_actualizacion,
						nombreUsuario: response.data.data.nombre_usuario,
						apellidoUsuario: response.data.data.apellido_usuario,
						username: response.data.data.username,
					};
					setActionCrud(action);
					setItemCrud(_itemCrud);
					setOpenDialog(true);
				} else {
					setMessage({
						severity: response.messageSeverity,
						messageText: response.messageText,
					});
					setShowMessage(true);
					return;
				}
			};
			loadItem();
		} else {
			setActionCrud(action);
			setItemCrud(_itemCrud);
			setOpenDialog(true);
		}
	};
	//
	// ################################################################################
	//
	const handleDialogSave = () => {
		load();
		loadFueros();
		setOpenDialog(false);
	};
	//
	// ################################################################################
	//
	const handleDialogClose = () => {
		setOpenDialog(false);
	};
	//
	// ################################################################################
	//



	// let table = data.data.table
	return (
		<>
			<Paper
				sx={tableStyles}
				elevation={2}
			>
				<Box
					sx={{
						margin: "auto",
						width: "90%",
					}}
				>
					<Box sx={{ m: 1, p: 1 }}>
						<Grid container spacing={1}>
							<Grid item xs={12}>
								<h2>{nameItemsText}</h2>
							</Grid>
							<Grid item xs={12} md={6} lg={3}>
								<Autocomplete
									id="fuero"
									size="small"
									value={valueAutoCompleteFueros}
									onChange={(event, newValue) => {
										setValueAutoCompleteFueros(newValue);
										setSearchData({
											...searchData,
											idFuero: newValue === null ? "" : newValue.id,
										});
									}}
									inputValue={inputValueAutoCompleteFueros}
									onInputChange={(event, newInputValue) => {
										setInputValueAutoCompleteFueros(newInputValue);
									}}
									options={Fueros}
									renderInput={(params) => (
										<TextField {...params} label="Fuero" />
									)}
									isOptionEqualToValue={(option, value) =>
										option.value === value.value
									}
								/>
							</Grid>
						</Grid>
					</Box>

					<Box sx={{ m: 1, p: 1 }}>
						<Grid container spacing={1}>
							<Grid item xs={12} md={3} lg={2}>
								<Button
									sx={{ width: "100%" }}
									variant="contained"
									onClick={handleBuscar}
									startIcon={<SearchIcon />}
								>
									Buscar
								</Button>
							</Grid>
							<Grid item xs={12} md={3} lg={2}>
								<Button
									sx={{ width: "100%", mr: 1 }}
									variant="contained"
									onClick={handleLimpiar}
									startIcon={<CleaningServicesIcon />}
								>
									Limpiar
								</Button>
							</Grid>
						</Grid>
					</Box>

          <Box sx={{ m: 1, p: 1 }}>
          </Box>
            {isDoingSomething && <LinearProgress />} 
					<ThemeProvider theme={darkTheme}>
						<MUIDataTable
							columns={tableColumns}
							data={itemsCrud}
							options={tableOptions}
						/>
					</ThemeProvider>
				</Box>
			</Paper>

			{/* >>>> Dialog */}

			{openDialog && (
				<FueroTipoActParteDialog
					Fueros={Fueros}
					onSave={handleDialogSave}
					onClose={handleDialogClose}
					itemCrud={itemCrud}
					actionCrud={actionCrud}
					setMessage={setMessage}
					setShowMessage={setShowMessage}
				/>
			)}

			{/* >>>> Messages */}

			{showMessage && (
				<Message
					showMessage={showMessage}
					setShowMessage={setShowMessage}
					message={message}
				/>
			)}
		</>
	);
};

export default FueroTipoActParteTable;
