import { createTheme } from '@mui/material/styles';

    const darkTheme = createTheme({
        palette: {
          mode: 'dark',
          background: {
            paper: '#313131', 
            default: '#313131'
            },
          divider: '#4a4d49',
          primary:{
            main:"#15BF81",
            light:"#14c987",
            dark:"#4A4D49"
          },
          secondary:{
            main:"#15BF81",
            light:"#ec5990",
            dark:"#0f111b"
          },
          text:{
            disabled:"rgba(255, 255, 255, 0.5)",
            icon:"#16F2A1",
            primary:"#f2eeed",
            secondary:"#b5b2b1"
          }
        },
        table: {
            width: "90%",
            height: "90%",
            overflow: "auto",
            p: 1,
            margin: "auto",
            position: 'fixed', 
            top: "8%", 
            left: '13%'
        },
        components: {
          MuiCssBaseline: {
            styleOverrides: {
              body: {
                scrollbarColor: "#4A4D49 #313131",
                "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
                  backgroundColor: "#313131",
                  width:5,
                },
                "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
                  borderRadius: 8,
                  backgroundColor: "#4A4D49",
                  minHeight: 24
                },
                "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
                  backgroundColor: "#15BF81",
                },
                "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
                  backgroundColor: "#15BF81",
                },
                "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: "#15BF81",
                },
                "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
                  backgroundColor: "#313131",
                },
              },
            },
          },
        },
        
      });

export default darkTheme;
